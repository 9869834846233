
import React, { useState , useEffect} from "react";
import { useLocation , useParams } from "react-router-dom";
import { ApiActions } from "../../actions/ApiActions";
import BeatLoader from "react-spinners/BeatLoader";
import { toast } from "react-toastify";
import  ShareOnSocial  from 'react-share-on-social';
import {FaShareAlt} from "react-icons/fa"
import { Helmet } from 'react-helmet';
import AppStore from "../../stores/AppStore";
import CryptoJS from 'crypto-js';

function truncateText(text, maxLength) {
  if (text.length <= maxLength) {
    return text;
  }
  return text.slice(0, maxLength) + "...";
}

function formatCurrency(value, locale = "pt-AO", currency = "AOA") {
  return new Intl.NumberFormat(locale, {
    style: "currency",
    currency: currency,
  }).format(value);
}

const ShareLink = ({ uri, title, description }) => {
  const shareConfig = {
    textToShare: title,
    link: uri,
    linkTitle: title,
    linkMetaDesc: description,
    shareTo: ['facebook', 'twitter', 'whatsapp'],
  };

  return (
    <ShareOnSocial {...shareConfig}>
      <button  className="flex items-center justify-center gap-2 bg-customPurple  text-white text-sm font-semibold py-2 px-4 rounded"
    aria-label="Share"> <FaShareAlt size={13} /> Partilhar</button>
    </ShareOnSocial>
  );
};
 function definir(forma) {
     if (forma.toUpperCase() === 'KILOGRAMAS') { 
       return 'KG'
     } else { 
       
       return 'UN'
     }

      }
const PromoDetailPage = () => {
  const params = useParams(); // Access route parameters

  const location = useLocation();
  const [selectedProduct, setSelectedProduct] = useState(
    location.state ? location.state.produto : null
  );
  const [quantity, setQuantity] = useState(
    selectedProduct && selectedProduct.stoqueExistente > 1
      ? 1
      : selectedProduct && selectedProduct.stoqueExistente
  );
  const [discount, setDiscount] = useState(null);
  const [isAdding, setIsAdding] = useState(false);

  const notificarSucesso = () => {
    toast.success("Produto adicionado ao carrinho!");
  };

  const notificarErro = () => {
    toast.error("Não foi possível adicionar o produto ");
  };

  const handleSelectDiscount = (discountOption) => {
    setQuantity(discountOption.quantidade);
    setDiscount(discountOption.pupanca);
  };

  const handleQuantityChange = (delta) => {
    const formattedStoqueExistente = selectedProduct.stoqueExistente;
    setQuantity((prevQuantity) => {
      let newQuantity = prevQuantity + delta;
      newQuantity = Math.max(1, newQuantity);
      newQuantity = Math.min(newQuantity, formattedStoqueExistente);
      return newQuantity;
    });
    setDiscount(null);
  };

  const handleAddToCart = () => {
    setIsAdding(true);
    const idProduto = selectedProduct.id;
    const desconto = discount;
    const quantidade = quantity;

    ApiActions.addToCart(idProduto, desconto, quantidade)
      .then(() => {
        setIsAdding(false);
        notificarSucesso();
      })
      .catch((error) => {
        console.error("Error adding to cart:", error);
        notificarErro();
      })
      .finally(() => {
        setIsAdding(false);
      });
  };

  useEffect(() => {
    if (location.state?.produto) {
      
      ApiActions.loadDetalhePromo(location.state.produto.id);
      setSelectedProduct(location.state.produto);
    } else if (params.Id) { // Assuming your route parameter is named 'sociaId'
       const base64 = params.Id.replace(/_/g, '/'); 
      const key = CryptoJS.enc.Hex.parse('0879febbaf365b1535e175da463ca7bcd7fdc76956282fa33c2cf5a9c28cc1b5'); // Ensure key is in correct format
       const bytes = CryptoJS.AES.decrypt(base64, key, {
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7
    });
      const originalText = bytes.toString(CryptoJS.enc.Utf8);
       ApiActions.loadDetalhePromo(originalText);
       //ApiActions.loadRelatedProducts(originalText);
      //setRelatedProducts(AppStore.get)
      AppStore.on("detalhePromo", handleChanges);
     // fetchProdutosRelacionados(originalText);
        
    }
   
    function handleChanges() {
        setSelectedProduct(AppStore.getPromocao())
       // setSelectedProduct(AppStore.getProduto());
      }

       // Carregar produtos ao iniciar
      return () => {
        AppStore.removeListener("detalhePromo", handleChanges);
      };
    
  }, [params.Id]);

  const handleShareButtonClick = () => {
    const currentUrl = window.location.href;
    
    navigator.clipboard.writeText(currentUrl)
      .then(() => {
        console.log('URL copiada para a área de transferência:', currentUrl);
      })
      .catch((error) => {
        console.error('Erro ao copiar URL para a área de transferência:', error);
      });
  };
  
  if (!selectedProduct) {
    return (
      <div>
        <p>Produto não encontrado.</p>
      </div>
    );
  }

  return (
   <>
    <Helmet>
    <title>Sócia - {selectedProduct.produto}</title>
</Helmet>

    <div className="flex flex-col md:flex-row justify-center w-full">
       <Helmet>
        <title>{selectedProduct.produto}</title>

        <meta property="og:type" content="website" />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:title" content={selectedProduct.produto} />
        <meta property="og:description" content={selectedProduct.produto} />
        <meta property="og:image" content={selectedProduct.foto} />

        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />

        {/* Twitter */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:url" content={window.location.href} />
        <meta name="twitter:title" content={selectedProduct.produto} />
        <meta name="twitter:description" content={selectedProduct.produto} />
        <meta name="twitter:image" content={selectedProduct.foto} />
      </Helmet>
      <div className="md:w-6/12 flex flex-col items-center p-4">
        <img
          src={selectedProduct.foto}
          alt={selectedProduct.produto}
          className="h-96 justify-items-center object-contain"
        />
        <div className="flex flex-col items-center justify-center gap-5 text-xs mb-2">
          <h2 className="font-bold text-2xl p-4 text-center">
            {selectedProduct.produto}
          </h2>
          <ShareLink uri={window.location.href} title={selectedProduct.produto} />
          <p className="mb-1 p-10 text-center">
            Nas nossas promoções, a transparência e a facilidade reinam
            supremas. O desconto promocional é todo seu, desde que a quantidade
            adquirida não ultrapasse o limite máximo definido pela oferta. Isso
            significa que você tem controle total sobre suas economias, sem
            surpresas desagradáveis na hora de finalizar a compra.
          </p>
        
        </div>
      </div>

      <div className="md:w-4/12 p-5">
        <div className="shadow-lg rounded-md bg-gray-50 p-10">
          <div className="flex justify-between items-center mb-4">
            <p className="text-lg font-semibold">
              {formatCurrency(
                selectedProduct.precoPromocao / selectedProduct.quantidade
              )}
              <sup> \ { definir(selectedProduct.dividoPor)} </sup>
            </p>
            {/* <button
            onClick={handleShareButtonClick}
            className=" text-customPurple font-bold py-2 px-4 rounded"
          >
            <FaCreativeCommonsShare size={28} />
          </button>  */}
          </div>
          <p className="text-lg text-red-500 font-medium mb-2 uppercase text-center">
            {" "}
            LIMITADO A {selectedProduct.stoqueExistente}{" "}
            {selectedProduct.dividoPor} POR COMPRA
          </p>
          <p className=" text-green-500 text-xs mb-2 text-center">VAI COMPRAR QUANTAS ?</p>
          <div className="flex gap-2 justify-center items-center mb-4">
            <button
              className="p-2 bg-red-500 text-white rounded ml-2"
              onClick={() => handleQuantityChange(-1)}
            >
              {" "}
              -{" "}
            </button>
            <p className="font-bold text-2xl p-4"> {quantity}</p>
            <button
              className="p-2 bg-green-500 text-white rounded ml-2"
              onClick={() => handleQuantityChange(1)}
            >
              +
            </button>
          </div>
          <div className="flex gap-2 justify-center items-center mb-4">
            <p className="text-xs">VAI PAGAR</p>
            <p className="text-xs">
              {formatCurrency(
                (selectedProduct.precoPromocao / selectedProduct.quantidade) *
                  quantity -
                  discount
              )}
            </p>
          </div>
          {isAdding && <div className="flex flex-col items-center justify-center"><BeatLoader /></div>}
          <div className="flex flex-row items-center justify-center">
          <button
            onClick={handleAddToCart}
            disabled={isAdding}
            className="w-full bg-customPurple text-white rounded-md px-4 py-2"
          >
            Colocar no carrinho
          </button>
          
          </div>
        </div>
        <div className="h-6"></div>
        <div className="shadow-lg rounded-md bg-gray-50 w-full p-5">
          <h2 className="font-bold text-xl p-4">COMO FUNCIONA</h2>
          <div className="flex justify-start items-start mb-4">
            <ul>
              <li>
                <p className="text-xs mb-2 text-left">
                  <span className="text-black font-bold uppercase">
                    Limite por produto:
                  </span>
                  &nbsp;Só é possível adquirir um produto pelo preço promocional
                  em cada compra.
                </p>
              </li>
              <li>
                <p className="text-xs mb-2 text-left">
                  <span className="text-black font-bold uppercase">
                    Aplicação do desconto:
                  </span>
                  &nbsp;O preço promocional só é aplicado se a compra não
                  exceder o limite máximo permitido por produto.
                </p>
              </li>
            </ul>
          </div>
          <p className="text-xs mb-2 text-left">
            <span className="text-black-500 font-bold uppercase">Exemplo:</span>
            &nbsp;Se a promoção oferece um desconto de 20% em um produto com
            limite de 2 unidades por compra:
          </p>
          <ul>
            <li>
              <p className="text-xs mb-2 text-left">
                <span className="text-black-500 font-bold uppercase">
                  você poderá
                </span>
                &nbsp;Comprar 2 unidades com 20% de desconto
              </p>
            </li>
            <li>
              <p className="text-xs mb-2 text-left">
                <span className="text-black-500 font-bold uppercase">
                  você não poderá
                </span>
                &nbsp;comprar 3 unidades com 20% de desconto, pois isso
                excederia o limite máximo
              </p>
            </li>
          </ul>
        </div>
      </div>
    </div>
   </>
  );
};

export default PromoDetailPage;
