
import React, { useEffect, useState } from "react";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import BeatLoader from "react-spinners/BeatLoader";
import { ApiActions } from "../../actions/ApiActions";
import AppStore from "../../stores/AppStore";
import { FaTimes } from "react-icons/fa";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { BsFiletypePdf } from "react-icons/bs";
import { useLocation } from "react-router-dom";

function formatCurrency(value, locale = "pt-AO", currency = "AOA") {
  return new Intl.NumberFormat(locale, {
    style: "currency",
    currency: currency,
  }).format(value);
}

function PriceDisplay({ price }) {
  return <div>{formatCurrency(price)}</div>;
}
export default function Carrinho() {
  const [isLoading, setIsLoading] = useState(AppStore.getIsLoading());
  const [products, setProducts] = useState(AppStore.getCart());
  const [coupon, setCoupon] = useState("");
  const [isAdding, setIsAdding] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { fatura } = location.state;
  const [ValidCoupon, setValidCoupon] = useState("");
  const notificarSucesso = () => {
    toast.success("Produto adicionado ao carrinho!");
  };
  const notificarErro = () => {
    toast.error("Não foi possivel adicionar o produto ");
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
    function handleLoading() {
      setIsLoading(AppStore.getIsLoading());
    }

    AppStore.on("carregando", handleLoading);
    if (!token) {
      navigate("/");
      return;
    }
  }, [navigate]);

  const handleAddToCart = () => {
    setIsAdding(true);

    ApiActions.PagarIban(products[0].id)
      .then(() => {
        setIsAdding(false);
        notificarSucesso();
      })
      .catch((error) => {
        console.error("Error adding to cart:", error);
        notificarErro();
      })
      .finally(() => {
        setIsAdding(false); 
      });
  };

  const RemoverCarrinho = (id) => {
    ApiActions.RemoverProCarrinho(id)
      .then(() => {
        // Após remover com sucesso da API, atualize o estado localmente para refletir a mudança
        const updatedProducts = [...products];
        const productIndex = updatedProducts[0].produtos.findIndex(
          (product) => product.id === id
        );
        updatedProducts[0].produtos.splice(productIndex, 1);
        setProducts(updatedProducts);
      })
      .catch((error) => {
        console.error("Error removing from cart:", error);
      });
  };

  useEffect(() => {
    function handleChanges() {
      setProducts(AppStore.getCart());
      handleLoading();
    }
    function handleLoading() {
      setIsLoading(AppStore.getIsLoading());
    }

    AppStore.on("carregando", handleLoading);
    AppStore.on("change_carrinho", handleChanges);

    ApiActions.loadCart(); // Carregar produtos ao iniciar
    return () => {
      AppStore.removeListener("change_carrinho", handleChanges);
      AppStore.removeListener("carregando", handleLoading);
    };
  }, []);

  const handleIncrementQuantity = (productId) => {
    const updatedProducts = [...products];
    const productIndex = updatedProducts[0].produtos.findIndex(
      (product) => product.id === productId
    );

    // Incrementa a quantidade do produto
    updatedProducts[0].produtos[productIndex].quantidade++;

    // Atualiza o estado com a lista de produtos atualizada
    setProducts(updatedProducts);
  };

  const handleDecrementQuantity = (productId) => {
    const updatedProducts = [...products];
    const productIndex = updatedProducts[0].produtos.findIndex(
      (product) => product.id === productId
    );

    // Decrementa a quantidade do produto, garantindo que seja pelo menos 1
    updatedProducts[0].produtos[productIndex].quantidade = Math.max(
      1,
      updatedProducts[0].produtos[productIndex].quantidade - 1
    );

    // Atualiza o estado com a lista de produtos atualizada
    setProducts(updatedProducts);
  };

  const applyCoupon = (e) => {
    e.preventDefault();

    if (coupon === "CUPOM20") {
      const updatedProducts = [...products];
      updatedProducts[0].total *= 0.8;
      setProducts(updatedProducts);
      toast.success("Cupom adicionado!");
      setValidCoupon("Ok");
      setCoupon("");
    } else if (coupon === "CUPOM30") {
      const updatedProducts = [...products];
      updatedProducts[0].total *= 0.7;
      setProducts(updatedProducts);
      toast.success("Cupom adicionado!");
      setValidCoupon("Ok");
      setCoupon("");
    } else if (coupon === "CUPOM50") {
      const updatedProducts = [...products];
      updatedProducts[0].total *= 0.5;
      setProducts(updatedProducts);
      toast.success("Cupom adicionado!");
      setValidCoupon("Ok");
      setCoupon("");
    } else {
      toast.error("Cupom de desconto inválido!");
    }
  };

  function formatCurrency(value, locale = "pt-AO", currency = "AOA") {
    return new Intl.NumberFormat(locale, {
      style: "currency",
      currency: currency,
    }).format(value);
  }

  pdfMake.vfs = pdfFonts.pdfMake.vfs;

  const generatePdf = () => {
    // Extrair os dados relevantes da fatura
    const { numero, cliente, produtos, subTotal, custoServico, desconto, total } = fatura;

    const { primerioNome, ultimoNome, telefone } = cliente;

    const tableBody = produtos.map((produto) => [
      produto.produto,
      produto.quantidade,
      formatCurrency(produto.preco / produto.quantidade),
      formatCurrency(produto.desconto),
      formatCurrency(produto.preco)
    ]);
  
    const tableHeader = [
      "PRODUTO",
      "QTD",
      "PRECO UNIT",
      "DESCONTO",
      "TOTAL",
    ];
  
    // Definir o conteúdo do PDF com base nos dados da fatura
    const docDefinition = {
      content: [
        { text: "\n\n" },
        { text: "\n\nSOCIA LDA,\n", bold: true },
        { text: "Luanda" },
        { text: `\n\n\n${primerioNome}\n ${ultimoNome}\n\n ${telefone}\n\n`, bold: true, fontSize: 13 },
        { text: `Guia número: ${numero}\n`, bold: true, fontSize: 12 },
        { text: "\n\nGUIA DE ENTREGA\n\n", bold: true, fontSize: 18 },
        {
          table: {
            headerRows: 1,
            widths: ["*", "*", "*", "*", "*"],
            body: [tableHeader, ...tableBody],
          },
          margin: [0, 20, 0, 20], // margem superior e inferior de 20 unidades
          layout: {
            vLineWidth: function (i, node) {
              return 0; // remove as linhas verticais
            },
            paddingLeft: function (i, node) {
              return 5; // padding esquerdo
            },
            paddingRight: function (i, node) {
              return 5; // padding direito
            },
            paddingTop: function (i, node) {
              return 5; // padding superior
            },
            paddingBottom: function (i, node) {
              return 5; // padding inferior
            },
          },
        },
        {
          text: [
            { text: "\n\n" },
            { text: `Sub total: ${formatCurrency(subTotal)}\n\n`, bold: true },
            { text: `Custo de Serviço: ${formatCurrency(custoServico)}\n\n`, bold: true },
            { text: `Desconto: ${formatCurrency(desconto)}\n\n\n`, bold: true },
            { text: `TOTAL: ${formatCurrency(total)}\n`, bold: true },
          ],
          fontSize: 12,
          alignment: "right",
          margin: [30, 30, 0, 10],
        },
      ],
      footer: {
        columns: [
          { width: "33%", text: "" }, // Espaço vazio para alinhar à esquerda
          {
            width: "*",
            text: "Criamos poder de compra",
            alignment: "center", // Texto centralizado
          },
          { width: "33%", text: "" }, // Espaço vazio para alinhar à direita
        ],
      },
      styles: {
        tableHeader: {
          fillColor: "#cccccc",
          bold: true,
        },
      },
    };
  
    pdfMake.createPdf(docDefinition).open();
  };
  

  if (isLoading) {
    return <div className="flex flex-col items-center justify-center">{isLoading && <BeatLoader />}</div>;
  }
  if (!fatura || fatura.length === 0) {
    return (
      <div className="flex flex-col items-center justify-center h-96">
        <main className="flex flex-col items-center justify-center h-screen">
          <img
            src="https://illustrations.popsy.co/fuchsia/shopping-cart.svg"
            alt="Carrinho vazio"
            className="h-52 select-none object-contain"
          />
          <h1 className="scroll-m-20 text-2xl text-customPurple font-extrabold tracking-tight lg:text-4xl select-none">
            Carrinho vazio
          </h1>
          <p className="leading-7 [&:not(:first-child)]:mt-6 text-center mb-5">
            Não espere mais, comece agora e leve para casa os melhores produtos
          </p>
        </main>
      </div>
    );
  }
  return (
    <div className="bg-gray-100 min-h-screen flex flex-row justify-center items-start gap-3 pt-24 pb-24">
      <div className="bg-white p-6 rounded shadow-md w-full max-w-2xl">
        <div className="flex flex-row items-center justify-between pb-5">
            <h2 className="text-xl uppercase font-semibold pb-2">Factura Nº: {fatura.numero}</h2>
            <div>
              <button
                className="flex flex-row items-center gap-2 bg-customPurple text-white px-2 py-2 rounded text-sm"
                onClick={generatePdf}
              >
                Relatório <BsFiletypePdf size={15} />
              </button>
            </div>
        </div>
        {fatura.produtos
          .slice()
          .reverse()
          .map((product) => (
            <div
              className="flex justify-between items-center mt-6 relative bg-gray-300/15 rounded-lg p-2"
              key={product.id}
            >
              <button
                className="absolute top-0 right-0 p-1 text-gray-500 hover:text-gray-700 p-3"
                onClick={() => RemoverCarrinho(product.id)}
              >
                <FaTimes size={12} />
              </button>
              <div className="flex items-center">
                <img
                  src={product.foto}
                  alt={product.produto}
                  className="rounded mr-4 h-40"
                />

                <div className="flex flex-col text-sm text-left">
                  <h2 className="text-lg uppercase font-bold">
                    {product.produto}
                  </h2>
                  <p className="text-gray-700 uppercase">
                    Preço unitário{" "}
                    {formatCurrency(product.preco / product.quantidade)}
                  </p>
                  <p className="text-gray-700 uppercase">
                    Desconto {formatCurrency(product.desconto)}
                  </p>
                </div>
              </div>
              <div className="flex flex-col items-end text-right">
                <div className="flex items-center space-x-2">
                  <button className="bg-gray-200 text-gray-600 p-1 rounded">
                    -
                  </button>
                  <span className="text-sm">{product.quantidade}</span>
                  <button className="bg-gray-200 text-gray-600 p-1 rounded">
                    +
                  </button>
                </div>
                <p className="text-lg font-semibold mt-4">
                  {formatCurrency(product.preco)}
                </p>
              </div>
            </div>
          ))}
      </div>
      <form className="bg-white p-4 border border-b w-96">
        <div className="text-left flex flex-col justify-between items-start mt-6 bg-white p-1 rounded gap-4">
          <div>
            <h2 className="text-2xl uppercase font-semibold pb-2">
              Dados de Pagamento
            </h2>
          </div>
          <div>
            <p className="uppercase">
              Subtotal:{" "}
              <span className="font-bold">
                {formatCurrency(fatura.subTotal)}
              </span>
            </p>
            <p className="uppercase">
              Desconto:{" "}
              <span className="font-bold">
                {formatCurrency(fatura.desconto)}
              </span>
            </p>
            <p className="uppercase">
              Custo de Entrega:{" "}
              <span className="font-bold">
                {formatCurrency(fatura.custoServico)}
              </span>
            </p>
          </div>
          <div className="py-4  border-b border-t w-full">
            <div className="flex flex-row uppercase items-center justify-between text-end">
              <span>Total</span>
              <span className="font-bold text-lg">
                {formatCurrency(fatura.total)}
              </span>
            </div>
          </div>
          {/* Inserted Payment Option Selection Here */}
          {/* <div className="py-4 border-b w-full">
            <h3 className="font-semibold uppercase mb-2">
              forma de pagamento:
            </h3>
            <div className="flex flex-col gap-2">
              <label className="flex items-center">
                <input
                  type="radio"
                  name="paymentMethod"
                  value="creditCard"
                  checked={paymentMethod === "creditCard"}
                  onChange={handlePaymentMethodChange}
                  disabled={products[0].confirmado}
                />
                <span className="ml-2">TPA</span>
              </label>
              <label className="flex items-center">
                <input
                  type="radio"
                  name="paymentMethod"
                  value="bankSlip"
                  checked={paymentMethod === "bankSlip"}
                  disabled={products[0].confirmado}
                  onChange={handlePaymentMethodChange}
                />
                <span className="ml-2">BAI PAGA</span>
              </label>
              <label className="flex items-center">
                <input
                  type="radio"
                  name="paymentMethod"
                  value="pix"
                  checked={paymentMethod === "pix"}
                  onChange={handlePaymentMethodChange}
                  disabled={products[0].confirmado}
                />
                <span className="ml-2">UNITEL MONEY</span>
              </label>
            </div>
          </div> */}
        </div>
        {ValidCoupon !== "Ok" && (
          <div className="mt-4 w-full flex items-center">
            <input
              type="text"
              placeholder="Cupom de desconto"
              className="w-full px-2 py-3 outline-none border rounded-md"
              value={coupon}
              onChange={(e) => setCoupon(e.target.value)}
            />
            <button
              className="ml-2 px-4 py-3 bg-customPurple text-white rounded-md"
              onClick={applyCoupon}
            >
              Aplicar
            </button>
          </div>
        )}

        <div className="mt-6">
          {isAdding && <BeatLoader />}
          {fatura.confirmado ? (
            <h3 className="font-semibold mb-2 text-center">
              COMPRA CONFIRMADA
            </h3>
          ) : (
            <button
              className="w-full bg-customPurple text-white py-3 rounded"
              onClick={handleAddToCart}
              disabled={isAdding}
            >
              RECOMPRAR
            </button>
          )}
        </div>
      </form>
    </div>
  );
}
