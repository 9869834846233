import React, { useState, useEffect, useCallback, useMemo } from "react";
import { useLocation, useParams } from "react-router-dom";
import { ApiActions } from "../../actions/ApiActions";
import AppStore from "../../stores/AppStore";
import CryptoJS from 'crypto-js';
import BeatLoader from "react-spinners/BeatLoader";
import { toast } from "react-toastify";
import CreateGroupForm from './CreateGroupService';
import ShareLink from '../HELPERS/ShareButton';

const GroupBuyingCard = ({ product, onCreateGroup }) => {
  return (
   <div className="shadow-lg rounded-md bg-gray-50 p-4 flex flex-col items-center justify-center">
  <h3 className="font-bold text-lg mb-2 text-center"></h3>
      
  <button
    className="w-full  bg-customPurple text-white text-lg uppercase py-2 px-4 rounded"
    onClick={onCreateGroup}
  >
   Fazer Sócia
  </button>
</div>
  );
};
// CreateGroupModal Component
const CreateGroupModal = ({ isOpen, onClose, product, onSubmitGroupBuy }) => {
  const [quantityPerParticipant, setQuantityPerParticipant] = useState(parseInt(product.quantidade / 4));
  const [participants, setParticipants] = useState(2);
  const [discountPercent, setDiscountPercent] = useState(2);
  const [totalDiscount, setTotalDiscount] = useState(0);
  const [totalPriceAfterDiscount, setTotalPriceAfterDiscount] = useState(0);
  const safeQuantityPerParticipant = Math.min(quantityPerParticipant, product.quantidade);

  useEffect(() => {
    calculateDiscount();
  }, [quantityPerParticipant, participants, product]);

  const calculateDiscount = () => {
    console.log(product)
    if (!product || participants < 2  ) {
      alert("Um grupo deve ter dois participantes");
      setParticipants(2)
      return;
    }
     if (participants > 4  ) {
     // alert("Um grupo deve ter participantes");
      setParticipants(4)
      return;
    }
     if (quantityPerParticipant > product.quantidade) {
      alert(`A quantidade por participante foi ajustada para ${product.quantidade} para não exceder a capacidade da caixa.`);
      setQuantityPerParticipant(safeQuantityPerParticipant);
     }
    if (quantityPerParticipant < parseInt(product.quantidade / 4)) {
      alert(`A quantidade por participante foi ajustada para ${parseInt(product.quantidade / 4)} para não ficar abaixo do valor minimo permitido.`);
      setQuantityPerParticipant (parseInt(product.quantidade / 4));
    }
    // Definindo a lógica de crescimento progressivo do desconto
    const baseDiscount = 5; // 5% para dois participantes
    let additionalDiscount = (product.margem < 1 ? 10 : product.margem   - 6) / 2; // 2% adicional por cada participante extra
    let maxDiscountPercent = product.margem < 45 ?  product.margem < 1 ? 10 :  product.margem  : 45  ; // Máximo de desconto permitido

    let calculatedDiscountPercent = baseDiscount + (participants - 2) * additionalDiscount;
    calculatedDiscountPercent = Math.min(calculatedDiscountPercent, maxDiscountPercent);

    const totalQuantity = quantityPerParticipant ;
    const totalPrice = totalQuantity * (product.preco / product.quantidade);
    const totalCost = totalQuantity * product.costPrice;
    const profitMargin = 2000;
    
    let calculatedTotalDiscount = (totalPrice * calculatedDiscountPercent) / 100;
    calculatedTotalDiscount = Math.min(calculatedTotalDiscount, profitMargin); // O desconto não pode exceder a margem de lucro

    setDiscountPercent(calculatedDiscountPercent);
    setTotalDiscount(calculatedTotalDiscount);
    setTotalPriceAfterDiscount(totalPrice - calculatedTotalDiscount);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmitGroupBuy({
      quantityPerParticipant,
      participants,
      discountPercent,
      totalDiscount,
      totalPriceAfterDiscount
    });
    onClose();
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex items-center justify-center z-50">
      <div className="relative mx-auto p-5 border w-96 shadow-lg rounded-md bg-white">
        <h3 className="text-lg font-bold mb-4 text-center uppercase">Detalhes da Sócia</h3>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label htmlFor="quantityPerParticipant" className="block text-sm font-medium text-gray-700 uppercase">Quantidade por sócio (Participante):</label>
            <input
              type="number"
              id="quantityPerParticipant"
              value={quantityPerParticipant}
              onChange={(e) => setQuantityPerParticipant(Math.max(1, parseInt(e.target.value, 10)))}
              className="mt-1 p-2 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
              min="1"
            />
          </div>
          <div className="mb-4">
            <label htmlFor="participants" className="block text-sm font-medium text-gray-700 uppercase">Número de sócios (Participantes):</label>
            <input
              type="number"
              id="participants"
              value={participants}
              onChange={(e) => setParticipants(Math.max(2, parseInt(e.target.value, 10)))}
              className="mt-1 p-2 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
              min="2"
            />
          </div>
          <div className="mb-4">
            <p className="text-sm text-gray-500 uppercase">Desconto: {discountPercent.toFixed(2)}%</p>
            <p className="text-sm text-gray-500 uppercase">Valor do Desconto: {formatCurrency(totalDiscount)}</p>
            <p className="text-sm text-gray-500 uppercase">Preço Após Desconto: {formatCurrency(totalPriceAfterDiscount) }</p>
          </div>
          <div className="flex justify-between">
            <button type="button" onClick={onClose} className="bg-red-100 hover:bg-red-700 text-white font-bold py-2 px-4 rounded">Cancelar</button>
            <button type="submit" className="bg-customPurple hover:bg-customPurple text-white font-bold py-2 px-4 rounded">Criar Sócia</button>
          </div>
        </form>
      </div>
    </div>
  );
};




function formatCurrency(value, locale = "pt-AO", currency = "AOA") {
  return new Intl.NumberFormat(locale, {
    style: "currency",
    currency: currency,
  }).format(value);
}

const ServiceDetalhe = () => {
  const location = useLocation();
  const params = useParams();
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [showCreateGroupModal, setShowCreateGroupModal] = useState(false);
  const [quantity, setQuantity] = useState(1);
  const [discount, setDiscount] = useState(null);
  const [relatedProducts, setRelatedProducts] = useState([]);
  const [isAdding, setIsAdding] = useState(false);
  const [groups, setGroups] = useState([]);
  const [selectedOfferIndex, setSelectedOfferIndex] = useState(null);
  const [selectedQuality, setSelectedQuality] = useState('Premium');

  const notificarSucesso = () => toast.success("Produto adicionado ao carrinho!");
  const notificarErro = () => toast.error("Não foi possível adicionar o produto.");
  const notificarErroSocia = () => toast.error("Não foi possível a sócia.");

  const handleCreateGroup = useCallback(() => setShowCreateGroupModal(true), []);
  const handleCloseModal = useCallback(() => setShowCreateGroupModal(false), []);

  const handleSubmitGroupBuy = useCallback((groupDetails) => {
    // Handle group buy logic here
  }, []);

  const handleQuantityChange = useCallback((delta) => {
    setQuantity((prevQuantity) => {
      const newQuantity = Math.max(1, Math.min(prevQuantity + delta, selectedProduct.stoqueExistente));
      setDiscount(null);
      return newQuantity;
    });
  }, [selectedProduct]);

  const handleSelectRelatedProduct = useCallback((relatedProduct) => {
    setSelectedProduct(relatedProduct);
    setQuantity(1);
    setDiscount(null);
    setSelectedOfferIndex(null);
    window.scrollTo(0, 0);
  }, []);

  const handleOfferSelect = useCallback((index) => {
    const offer = selectedProduct.socios[index];
    setSelectedOfferIndex(index);
    setDiscount(offer.pupanca);
    setQuantity(offer.quantidade);
    window.scrollTo(0, 0);
  }, [selectedProduct]);

  const handleAddToCart = useCallback(() => {
    setIsAdding(true);
    ApiActions.addToCart(selectedProduct.id, discount, quantity)
      .then(() => {
        notificarSucesso();
        setIsAdding(false);
      })
      .catch((error) => {
        console.error("Error adding to cart:", error);
        notificarErro();
        setIsAdding(false);
      });
  }, [selectedProduct, discount, quantity]);

  useEffect(() => {
   if (params.Id) {
      const base64 = params.Id.replace(/_/g, '/');
      const key = CryptoJS.enc.Hex.parse('0879febbaf365b1535e175da463ca7bcd7fdc76956282fa33c2cf5a9c28cc1b5');
      const bytes = CryptoJS.AES.decrypt(base64, key, {
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7
      });
      const originalText = bytes.toString(CryptoJS.enc.Utf8);
      ApiActions.loadDetalheProduto(originalText);
      ApiActions.loadRelatedProducts(originalText);
      AppStore.on("detalheProduto", handleChanges);
    }

    function handleChanges() {
      const produto = AppStore.getProduto();
      setSelectedProduct(produto);
    }

    return () => {
      AppStore.removeListener("detalheProduto", handleChanges);
    };
  }, [location.state, params.Id]);

  useEffect(() => {
    ApiActions.loadMeusGrupos();
    function handleChanges() {
      setGroups(AppStore.getGrupos());
    }

    AppStore.on("change_grupos", handleChanges);
    return () => {
      AppStore.removeListener("change_grupos", handleChanges);
    };
  }, []);

  const relatedProductElements = useMemo(() => relatedProducts.map((relatedProduct) => (
    <div
      key={relatedProduct.id}
      className="bg-white shadow rounded flex flex-col cursor-pointer"
      onClick={() => handleSelectRelatedProduct(relatedProduct)}
    >
      <img
        src={relatedProduct.foto}
        alt={relatedProduct.produto}
        className="h-32 object-contain"
      />
      <div className="p-4 flex flex-col justify-between flex-1">
        <h4 className="text-lg font-semibold mb-2">{relatedProduct.produto.toUpperCase()}</h4>
        <p className="text-gray-600 mb-2">
          {formatCurrency(relatedProduct.preco / relatedProduct.quantidade)}
        </p>
      </div>
    </div>
  )), [relatedProducts, handleSelectRelatedProduct]);

  if (!selectedProduct) {
    return (
      <div className="flex flex-col items-center justify-center h-screen">
        <BeatLoader />
      </div>
    );
  }

  return (
    <>
      <div className="flex flex-col lg:flex-row h-screen">
        <div className="w-full lg:w-3/5 flex flex-col items-center justify-center p-4">
          <img
            src={selectedProduct.foto}
            alt={selectedProduct.produto}
            className="h-96 object-contain rounded-lg"
          />
          <h2 className="font-bold text-2xl text-center mt-4">{selectedProduct.produto.toUpperCase()} </h2>
          <p className="text-xl text-center font-semibold pb-4">
            {formatCurrency(selectedProduct.preco / selectedProduct.quantidade)}
            <sup>{selectedProduct.dividoPor.toUpperCase() === 'KILOGRAMAS' ? 'KG' : 'UN'}</sup>
          </p>
          <ShareLink uri={window.location.href} title={selectedProduct.produto} />
          <div className="text-xs mb-2 flex flex-col items-center justify-center">
            <p className="text-justify py-4">
              Eleve as suas economias ao próximo nível! Descubra o poder da união ao criar uma sócia na nossa plataforma. Imagine só: quanto mais pessoas se juntarem a você, maior será o desconto que todos desfrutarão. E tem mais, cada desconto reflete nossa margem de lucro, assegurando uma política justa para todos.
              Agora, você tem o poder nas suas mãos. Clique no botão "Criar Socia” e inicie uma jornada de economia coletiva sem precedentes. Junte-se à aventura de comprar mais por menos e veja o quanto você pode economizar quando a força da comunidade se une. Não deixe para depois, a economia começa quando você decide agir. Clique e torne-se um pioneiro em economia coletiva.
            </p>
            <p className="mb-1 py-4 text-justify">
              Encontre uma sócia para as tuas compras! Escolha entre as nossas ofertas especiais com desconto. Caso a quantidade desejada não esteja disponível no momento, não há motivo para preocupação! Você tem a possibilidade de nos informar exatamente quantas unidades precisa.
            </p>
          </div>
          {selectedProduct.socios && selectedProduct.socios.length > 0 && (
            <div className="hidden lg:flex flex-col items-center justify-center">
              <p className="font-bold text-xl mb-2 pt-1">OFERTAS COM POUPANÇA</p>
              <div className="grid grid-flow-col auto-cols-max justify-items-center gap-4 mt-4 overflow-auto p-4">
                {selectedProduct.socios.map((offer, index) => (
                  <button
                    key={index}
                    className={`p-2 text-sm rounded ${selectedOfferIndex === index ? 'bg-customPurple text-white' : 'bg-gray-100'}`}
                    onClick={() => handleOfferSelect(index)}
                  >
                    {offer.quantidade} {selectedProduct.dividoPor.toUpperCase()}
                  </button>
                ))}
              </div>
            </div>
          )}
        </div>
        <div className="flex-1 bg-neutral-50 flex flex-col items-start justify-start p-4">
          <div className="font-sans p-4 w-full">
            {/* <span className="block mb-2 text-lg font-semibold">COMO VAI COMPRAR</span>
            <div className="inline-flex border border-gray-300 rounded overflow-hidden w-full">
              <button
                className={`py-2 px-4 w-1/2 text-sm focus:outline-none ${selectedQuality === 'Premium' ? 'bg-gray-200 font-bold' : 'bg-gray-100'}`}
                onClick={() => setSelectedQuality('Premium')}
              >
                EM GRUPO
              </button>
              <button
                className={`py-2 px-4 w-1/2 text-sm focus:outline-none ${selectedQuality === 'Basic' ? 'bg-gray-200 font-bold' : 'bg-gray-100'}`}
                onClick={() => setSelectedQuality('Basic')}
              >
                INDIVIDUAL
              </button>
            </div> */}
          </div>
          <div className="w-full mt-4">
            {selectedQuality === 'Basic' ? (
              <div className="p-4 rounded w-full">
                <div className="flex gap-2 justify-center items-center mb-4">
                  <button
                    className="p-2 ph-8 bg-red-500 text-white rounded font-bold text-lg ml-2"
                    onClick={() => handleQuantityChange(-1)}
                  >
                    - 
                  </button>
                  <p className="font-bold text-2xl p-4"> {quantity}</p>
                  <button
                    className="p-2 bg-green-500 font-bold text-lg text-white rounded ml-2"
                    onClick={() => handleQuantityChange(1)}
                  >
                    +
                  </button>
                </div>
                <div className="flex gap-2 justify-center items-center mb-4">
                  <p className="text-xs">POUPAS</p>
                  <p className="text-xs">{formatCurrency(discount)}</p>
                </div>
                <div className="flex gap-2 justify-center items-center mb-4">
                  <p className="text-xs">VAIS PAGAR</p>
                  <p className="text-xs">
                    {formatCurrency((selectedProduct.preco / selectedProduct.quantidade) * quantity - discount)}
                  </p>
                </div>
                {isAdding && <div className="flex flex-col items-center justify-center"><BeatLoader /></div>}
                <button
                  onClick={handleAddToCart}
                  disabled={isAdding}
                  className="w-full uppercase text-lg bg-customPurple text-white rounded-md px-4 py-2"
                >
                  Adicionar ao carrinho
                </button>
              </div>
            ) : (
              <div className="p-4">
                <CreateGroupForm
                  product={selectedProduct}
                  onSubmitGroupBuy={handleSubmitGroupBuy}
                  userGroups={groups}
                />
              </div>
            )}
          </div>
          <div className="h-6"></div>
        </div>
      </div>
      {relatedProducts && relatedProducts.length > 0 && (
        <div className="mt-10 flex flex-col justify-start">
          <h1 className="scroll-m-20 pb-5 text-2xl font-semibold tracking-tight first:mt-0 text-customPurple">
            Produtos relacionados
          </h1>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-10 py-8">
            {relatedProductElements}
          </div>
        </div>
      )}
    </>
  );
};

export default ServiceDetalhe;
