import React, { useState, useEffect , useCallback  } from "react";
import { FaShare, FaTimes, FaPlus, FaMinus  } from "react-icons/fa";
import { Dividir, RemoverDivisao, fetchEnderecos  ,UpdateCarrinho} from "../../actions/apiServices";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FaChevronDown, FaChevronUp } from "react-icons/fa"; // Adicione os ícones
import { FiEdit3, FiTrash } from "react-icons/fi";

// Função para formatar valores em moeda local
const formatCurrency = (value, locale = "pt-AO", currency = "AOA") => {
  return new Intl.NumberFormat(locale, {
    style: "currency",
    currency: currency,
  }).format(value);
};

const CarrinhoCart = React.memo(({ product, remover, actualizar }) => {
  const [productQuantity, setProductQuantity] = useState(product.quantidade);

  const handleIncreaseQuantity = () => {
    const newQuantity = productQuantity + 1;
    setProductQuantity(newQuantity);
    handleUpdate(newQuantity); // Atualiza via prop passada
     setRemainingQuantity(remainingQuantity + 1);
  };

  const handleDecreaseQuantity = () => {
    

    if  (productQuantity > 1) {
      const newQuantity = productQuantity - 1;
       if (newQuantity < totalDistributed) {
      toast.warn("Quantidade deve ser maior que a quantidade dividida.");
      return;
    }
      setProductQuantity(newQuantity);
     
      // Atualiza via prop passada
      handleUpdate(newQuantity);
       setRemainingQuantity(remainingQuantity - 1);
    } else {
      toast.warn("A quantidade mínima é 1.");
    }
  };
  const handleUpdate = useCallback(async (quantity) => {
   // actualizar(product.id, quantity);
     await UpdateCarrinho(product.id, quantity);
  }, []);

  const [showPopup, setShowPopup] = useState(false);
  const [addresses, setAddresses] = useState(product.enderecos || []);
  const [remainingQuantity, setRemainingQuantity] = useState(product.quantidade);
  const [availableAddresses, setAvailableAddresses] = useState([]);
  const [isLoadingAddresses, setIsLoadingAddresses] = useState(false);
  const [quantityByAddress, setQuantityByAddress] = useState({});
  const [isAddressesExpanded, setIsAddressesExpanded] = useState(false); // Estado para expandir/colapsar
  const totalDistributed = addresses.reduce((acc, { quantidade }) => acc + quantidade, 0);

  useEffect(() => {
    const totalDivided = addresses.reduce((acc, { quantidade }) => acc + quantidade, 0);
    setRemainingQuantity(product.quantidade - totalDivided);
  }, [addresses, product.quantidade]);

  useEffect(() => {
    if (showPopup) {
      const loadAddresses = async () => {
        setIsLoadingAddresses(true);
        try {
          const data = await fetchEnderecos();
          setAvailableAddresses(data);
        } catch (error) {
          toast.error("Erro ao carregar endereços.");
        } finally {
          setIsLoadingAddresses(false);
        }
      };
      loadAddresses();
    }
  }, [showPopup]);

  const handleAddAddress = async (address) => {
    const formattedAddress = `${address.municipio}, ${address.bairro}, ${address.rua}`;
    const quantity = quantityByAddress[address.id] || 0;

    if (addresses.some((addr) => addr.endereco === formattedAddress)) {
      toast.warn("Este endereço já foi adicionado.");
      return;
    }

    if (quantity <= 0) {
      toast.warn("Quantidade deve ser maior que 0.");
      return;
    }
   
    if (remainingQuantity - quantity < 0) {
      toast.error(`A quantidade excede o limite disponível (${remainingQuantity}).`);
      return;
    }

    try {
      await Dividir(product.id, address.id, quantity);
      setAddresses([
        ...addresses,
        { endereco: formattedAddress, quantidade: quantity, id: address.id },
      ]);
      setQuantityByAddress((prev) => ({ ...prev, [address.id]: 0 }));
      toast.success("Endereço adicionado e distribuído com sucesso!");
    } catch (error) {
      toast.error("Erro ao distribuir para o endereço.");
    }
  };

  const handleQuantityChange = (id, value) => {
    const parsedValue = Math.max(0, Number(value));
    const totalQuantity = addresses.reduce(
      (acc, { quantidade }) => acc + quantidade,
      0
    ) + parsedValue - (quantityByAddress[id] || 0);

    if (totalQuantity > product.quantidade) {
      toast.error(`A soma das quantidades não pode exceder ${product.quantidade}.`);
      return;
    }

    setQuantityByAddress((prev) => ({
      ...prev,
      [id]: parsedValue,
    }));
  };

  const handleRemoveAddress = async (id) => {
    try {
      await RemoverDivisao(id);
      setAddresses((prev) => prev.filter((address) => address.id !== id));
      toast.success("Endereço removido com sucesso.");
    } catch (error) {
      toast.error("Erro ao remover endereço.");
    }
  };

  return (
    <div className="flex gap-4 items-center border-b py-4">
      <div className="w-20 h-20 flex-shrink-0">
        <img
          src={product.foto}
          alt={product.produto}
          className="rounded-lg object-cover w-full h-full"
        />
      </div>

      <div className="flex-1">
        <h3 className="text-lg uppercase font-bold">{product.produto}</h3>
        <p className="text-sm text-gray-600">
          Preço: {formatCurrency(product.preco)}
        </p>
        <p className="text-sm text-gray-600">
          Quantidade restante: {remainingQuantity}
        </p>


        
        
<div className="mt-4">
  {/* Texto e Botão para Expandir/Colapsar */}
  <div className="flex justify-between items-center">
    <span className="text-gray-600 text-sm">
      {addresses.length > 0
        ? "Endereços associados"
        : "Nenhum endereço associado"}
    </span>

    {addresses.length > 0 ? (
      <button
        onClick={() => setIsAddressesExpanded((prev) => !prev)}
        className="text-customPurple hover:text-blue-700 text-sm flex items-center"
      >
        {isAddressesExpanded ? (
          <>
            <FaChevronUp className="mr-1" />
            
          </>
        ) : (
          <>
            <FaChevronDown className="mr-1" />
            
          </>
        )}
      </button>
    ) : (
      <button
        onClick={() => setShowPopup(true)}
        className="text-blue-500 hover:text-blue-700 text-sm"
      >
        Adicionar Endereço
      </button>
    )}
  </div>

  {/* Lista de Endereços Associados */}
{isAddressesExpanded && addresses.length > 0 && (
  <>
    <ul className="mt-4 space-y-2">
      {addresses.map((address) => (
        <li
          key={address.id}
          className="flex justify-between items-center "
        >
          <div>
            <span className="block text-sm text-gray-700">
              {address.endereco}
            </span>
            <span className="block text-xs text-gray-500">
              Qtd: {address.quantidade}
            </span>
          </div>
          <button
            onClick={() => handleRemoveAddress(address.id)}
            className="text-white hover:text-white text-sm border p-2 rounded-md  bg-customPurple"
          >
            Remover
          </button>
        </li>
      ))}
    </ul>

    {/* Botão para adicionar novo endereço */}
    {remainingQuantity > 0 && (
      <div className="mt-4 flex items-center">
        <button
          onClick={() => setShowPopup(true)}
          className="flex items-center text-blue-500 hover:text-blue-700 text-sm"
        >
          <FaPlus className="mr-2" />
          Associar Novo Endereço
        </button>
      </div>
    )}
  </>
)}
</div>
      </div>
 {/* Controles de Quantidade */}
        <div className="flex items-center mt-4">
          <button
            onClick={handleDecreaseQuantity}
            className="px-2 py-1 border rounded text-sm bg-gray-200 hover:bg-gray-300"
          >
            <FaMinus />
          </button>
          <span className="px-4 text-sm">{productQuantity}</span>
          <button
            onClick={handleIncreaseQuantity}
            className="px-2 py-1 border rounded text-sm bg-gray-200 hover:bg-gray-300"
          >
            <FaPlus />
          </button>
        </div>
      <div className="flex flex-col gap-2">
      
        <button
          onClick={() => remover(product.id)}
          className="text-customPurple hover:text-red-700"
        >
          <FiTrash />
        </button>
      </div>

      {showPopup && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-white rounded-lg p-6">
            <h2 className="text-xl font-bold mb-4">Associar Endereços</h2>
            {isLoadingAddresses ? (
              <p className="text-gray-500">A carregar endereços...</p>
            ) : (
              availableAddresses.map((address) => {
                const isAdded = addresses.some(
                  (addr) =>
                    addr.endereco === `${address.municipio}, ${address.bairro}, ${address.rua}`
                );

                return (
                  <div key={address.id} className="flex justify-between items-center mb-2">
                    <div>
                      <span>{`${address.municipio}, ${address.bairro}, ${address.rua}`}</span>
                      <div className="flex items-center">
                        <button
                          onClick={() =>
                            setQuantityByAddress((prev) => ({
                              ...prev,
                              [address.id]: Math.max(
                                (prev[address.id] || 0) - 1,
                                0
                              ),
                            }))
                          }
                          className="px-2 text-sm"
                        >
                          <FaMinus />
                        </button>
                        <input
                          type="number"
                          min="0"
                          value={quantityByAddress[address.id] || ""}
                          onChange={(e) =>
                            handleQuantityChange(address.id, e.target.value)
                          }
                          className="ml-4 border rounded px-2 py-1 text-sm w-16"
                          placeholder="Qtd."
                        />
                        <button
                          onClick={() =>
                            setQuantityByAddress((prev) => ({
                              ...prev,
                              [address.id]: (prev[address.id] || 0) + 1,
                            }))
                          }
                          className="px-2 text-sm"
                        >
                          <FaPlus />
                        </button>
                      </div>
                    </div>
                    <button
                      onClick={() => handleAddAddress(address)}
                      disabled={isAdded}
                      className={`text-blue-500 hover:text-blue-700 text-xs ml-4 ${
                        isAdded ? "opacity-50 cursor-not-allowed" : ""
                      }`}
                    >
                      {isAdded ? "Já Adicionado" : "Adicionar"}
                    </button>
                  </div>
                );
              })
            )}

            <div className="mt-4 flex justify-end">
              <button
                onClick={() => setShowPopup(false)}
                className="px-4 py-2 bg-red-500 text-white rounded"
              >
                Fechar
              </button>
                  
            </div>
          </div>
        </div>
      )}
    </div>
  );
});

export default CarrinhoCart;