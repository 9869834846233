import React, { useState, useEffect, useRef, useCallback, useMemo, Suspense } from "react";
import logo from "../Images/logotipo.png";
import { MdOutlineShoppingCart, MdGroups2, MdEvent, MdVideoLibrary, MdShoppingCart, MdPeople, MdRssFeed, MdMenu } from "react-icons/md";
import { FaUser, FaGamepad, FaVideo, FaShoppingBag, FaNewspaper, FaSignInAlt } from "react-icons/fa";
import { GiExitDoor } from "react-icons/gi";
import CryptoJS from "crypto-js";
import {jwtDecode} from "jwt-decode";
import { useNavigate, Link } from "react-router-dom";
import AppStore from "../../stores/AppStore";

const SearchBar = React.lazy(() => import("./SearchBar"));
const Modal = React.lazy(() => import("../Modal"));
const LoginModal = React.lazy(() => import("../LoginModal"));

const decrypt = (encryptedText) => {
  const key = CryptoJS.enc.Hex.parse('0879febbaf365b1535e175da463ca7bcd7fdc76956282fa33c2cf5a9c28cc1b5');
  const bytes = CryptoJS.AES.decrypt(encryptedText, key, {
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7
  });
  const originalText = bytes.toString(CryptoJS.enc.Utf8);
  return originalText;
};

const NavigationBar = React.memo(({ categorias }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [cartLength, setCartLength] = useState(0); 
  const [isDropdownOpen, setIsDropdownOpen] = useState(false); 
  const [apiDataLength, setApiDataLength] = useState(0); 
  const navigate = useNavigate();
  const [userData, setUserData] = useState(null);

  // useEffect to check if token exists and navigate accordingly
useEffect(() => {
   const token = localStorage.getItem("token");
    if (!token) {
    //  navigate("/");
     // return;
    } else {
     const decoded = jwtDecode(token);
      setUserData(decoded);
    }
  }, [navigate]);

  const dropdownRef = useRef(null);

  const toggleMenu = useCallback(() => {
    setIsMenuOpen(prevState => !prevState);
  }, []);

  const openModal = useCallback(() => {
    setShowModal(true);
  }, []);

  const closeModal = useCallback(() => {
    setShowModal(false);
  }, []);

  const handleLogout = useCallback(() => {
    localStorage.clear();
    window.location.reload();
  }, []);

  useEffect(() => {
    function handleCartChange() {
      setCartLength(AppStore.getCart().length);
    }

    AppStore.on("change_carrinho", handleCartChange);
    return () => {
      AppStore.removeListener("change_carrinho", handleCartChange);
    };
  }, []);

  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const fetchData = useCallback(async () => {
    const token = localStorage.getItem("token");
    try {
      const response = await fetch("https://api.socia.ao/apiV2/Socia/Meucarrinho", {
        headers: {
          apiKey: "9MT3VnQYj2Mb7ILNqKy2HfF9AChqPeFwtDQ8QkGBZZ0=",
          Authorization: `Bearer ${token}` 
        }
      });
      const data = await response.json();
      setApiDataLength(data.objecto[0].produtos.length);
    } catch (error) {
      console.error("Erro ao buscar dados da API:", error);
    }
  }, []);

  useEffect(() => {
    fetchData();

    const intervalId = setInterval(fetchData, 5000);

    return () => clearInterval(intervalId);
  }, [fetchData]);

  const userFirstName = useMemo(() => decrypt(userData ? userData.PrimeiroNome : ""), [userData]);

  const handleCategorySelect = useCallback((catId) => {
    navigate(`/produtoscategoria/`, { state: { catId } });
    setIsDropdownOpen(false);
  }, [navigate]);

  return (
    <>
      <header className="fixed top-0 left-0 right-0 flex flex-row items-center justify-between px-4 md:px-10 border-b-[0.5px] bg-[rgba(255, 255, 255, 0.849)] backdrop-blur-md z-50">
        <Link to="/">
          <img src={logo} width={75} height={75} alt="Sócia Logo" />
        </Link>
        <div className="hidden md:flex justify-center items-center">
          <Suspense fallback={<div>Loading...</div>}>
            <SearchBar categories={categorias} />
          </Suspense>
        </div>

        <div className="flex flex-row items-center gap-2 md:gap-5">
          <div className="hidden md:flex flex-row items-center gap-8">
            <Link
              to="/"
              className="flex flex-row items-center gap-2 text-customPurple font-semibold text-base leading-7"
            >
              <FaShoppingBag size={21} className="text-customPurple" /> Produtos
            </Link>
            <Link
              to="/socias"
              title="Compras em grupo"
              className="flex flex-row items-center gap-2 text-customPurple font-semibold text-base leading-7"
            >
              <MdGroups2 size={25} className="text-customPurple" /> Compras em Grupo
            </Link>
            <Link
              to="/carrinho"
              title="Carrinho"
              className="flex flex-row items-center gap-2 text-customPurple font-semibold text-base leading-7"
            >
              <MdOutlineShoppingCart size={24} className="text-customPurple" />{" "}
              ({apiDataLength})
            </Link>
            <div
              ref={dropdownRef}
              className="relative"
              onClick={(e) => {
                e.stopPropagation(); 
                setIsDropdownOpen(!isDropdownOpen);
              }}
            >
              <div className="h-9 w-9 rounded-full flex items-center justify-center cursor-pointer">
                <MdMenu size={24} className="text-customPurple" />
              </div>
              {isDropdownOpen && (
                <div className="absolute top-full right-0 w-64 md:w-96 bg-white border border-gray-200 shadow-md rounded-md z-50 overflow-y-auto max-h-[80vh]">
                  <div className="flex flex-col md:flex-row">
                    <div className="w-full md:w-1/2 bg-gray-50 border-r border-gray-200 p-4">
                      {userData ? (
                        <>
                          <div className="bg-gray-100 py-1 px-4 text-customPurple font-semibold mb-2">
                            {userFirstName}
                          </div>
                          <div className="text-sm text-gray-500 font-semibold mb-2">Compras em Grupo</div>
                            <Link to="/socias" className="flex items-center gap-2 py-2 hover:bg-gray-100">
                            <MdPeople size={20} className="text-customPurple" />
                            <span className="text-base">Minhas Compras</span>
                          </Link>
                         
                          <Link to="/grupos" className="flex items-center gap-2 py-2 hover:bg-gray-100">
                            <MdGroups2 size={20} className="text-customPurple" />
                            <span className="text-base">Meus Grupos</span>
                          </Link>
                          
                          <div className="border-t border-gray-200 my-2"></div>
                          <div className="text-sm text-gray-500 font-semibold mb-2">Minha Conta</div>
                          <Link to="/perfil" className="flex items-center gap-2 py-2 hover:bg-gray-100">
                            <FaUser size={20} className="text-customPurple" />
                            <span className="text-base">Perfil</span>
                          </Link>
                          <Link to="/despensa" className="flex items-center gap-2 py-2 hover:bg-gray-100">
                            <MdOutlineShoppingCart size={20} className="text-customPurple" />
                            <span className="text-base">Minha Despensa</span>
                          </Link>
                          <Link to="/historico" className="flex items-center gap-2 py-2 hover:bg-gray-100">
                            <FaNewspaper size={20} className="text-customPurple" />
                            <span className="text-base">Histórico</span>
                          </Link>
                           <Link to="/todos" className="flex items-center gap-2 py-2 hover:bg-gray-100">
                             <MdOutlineShoppingCart size={20} className="text-customPurple" />
                            <span className="text-base">Compras Recorrentes</span>
                          </Link>
                          <div className="py-2 border-t border-gray-200">
                            <button
                              onClick={handleLogout}
                              className="flex items-center gap-2 w-full text-left text-customPurple hover:bg-gray-100 py-2 px-4"
                            >
                              Sair <GiExitDoor size={18} />
                            </button>
                          </div>
                        </>
                      ) : (
                        <button
                          className="flex items-center gap-2 w-full text-left text-customPurple hover:bg-gray-100 py-2 px-4"
                          onClick={openModal}
                        >
                          Entrar <FaSignInAlt size={18} />
                        </button>
                      )}
                    </div>
                    <div className="w-full md:w-1/2 p-4">
                      <div className="text-sm text-gray-500 font-semibold mb-2">Categorias</div>
                      {categorias.map((categoria) => (
                        <div
                          key={categoria.id}
                          onClick={() => handleCategorySelect(categoria.id)}
                          className="flex items-center gap-2 py-2 hover:bg-gray-100 cursor-pointer"
                        >
                          <span className="text-base text-customPurple uppercase">{categoria.categoria}</span>
                        </div>
                      ))}
                      
                      
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>

          <div onClick={(e) => e.stopPropagation()}>
            <Suspense fallback={<div>Loading...</div>}>
              {isMenuOpen && (
                <div onClick={(e) => e.stopPropagation()} className="fixed inset-0 bg-white z-50 flex flex-col p-4 md:hidden overflow-y-auto">
                  <div className="flex justify-between items-center mb-4">
                    <span className="text-lg font-semibold">Menu</span>
                    <button onClick={toggleMenu} className="text-lg font-semibold">✖</button>
                  </div>
                  <div className="flex flex-col gap-4">
                    <Link
                      to="/"
                      className="flex flex-row items-center gap-2 text-customPurple font-semibold text-base leading-7"
                    >
                      <FaShoppingBag size={21} className="text-customPurple" /> Produtos
                    </Link>
                      <Link
              to="/socias"
              title="Compras em grupo"
              className="flex flex-row items-center gap-2 text-customPurple font-semibold text-base leading-7"
            >
              <MdGroups2 size={25} className="text-customPurple" /> Compras em Grupo
            </Link>
                    <Link
                      to="/carrinho"
                      title="Carrinho"
                      className="flex flex-row items-center gap-2 text-customPurple font-semibold text-base leading-7"
                    >
                      <MdOutlineShoppingCart size={24} className="text-customPurple" />{" "}
                      ({apiDataLength})
                    </Link>
                    {userData && (
                      <>
                        <div className="bg-gray-100 py-1 px-4 text-customPurple font-semibold mb-2">
                          {userFirstName}
                        </div>
                        <div className="text-sm text-gray-500 font-semibold mb-2">COMPRAS EM GRUPO</div>
                        
                 
                        <Link to="/socias" className="flex items-center gap-2 py-2 hover:bg-gray-100">
                          <MdPeople size={20} className="text-customPurple" />
                          <span className="text-base">Minhas Compras</span>
                        </Link>
                        <Link to="/grupos" className="flex items-center gap-2 py-2 hover:bg-gray-100">
                          <MdGroups2 size={20} className="text-customPurple" />
                          <span className="text-base">Meus Grupos</span>
                        </Link>
                       
                        
                        <div className="border-t border-gray-200 my-2"></div>
                        <div className="text-sm text-gray-500 font-semibold mb-2">Minha Conta</div>
                        <Link to="/perfil" className="flex items-center gap-2 py-2 hover:bg-gray-100">
                          <FaUser size={20} className="text-customPurple" />
                          <span className="text-base">Perfil</span>
                        </Link>
                        <Link to="/despensa" className="flex items-center gap-2 py-2 hover:bg-gray-100">
                          <MdOutlineShoppingCart size={20} className="text-customPurple" />
                          <span className="text-base">Minha despensa</span>
                        </Link>
                        <Link to="/historico" className="flex items-center gap-2 py-2 hover:bg-gray-100">
                          <FaNewspaper size={20} className="text-customPurple" />
                          <span className="text-base">Histórico</span>
                        </Link>
                        <div className="py-2 border-t border-gray-200">
                          <button
                            onClick={handleLogout}
                            className="flex items-center gap-2 w-full text-left text-customPurple hover:bg-gray-100 py-2 px-4"
                          >
                            Sair <GiExitDoor size={18} />
                          </button>
                        </div>
                      </>
                    )}
                    <div className="text-sm text-gray-500 font-semibold mb-2">Categorias</div>
                    {categorias.map((categoria) => (
                      <div
                        key={categoria.id}
                        onClick={() => handleCategorySelect(categoria.id)}
                        className="flex items-center gap-2 py-2 hover:bg-gray-100 cursor-pointer"
                      >
                        <span className="text-base text-customPurple uppercase">{categoria.categoria}</span>
                      </div>
                    ))}
                   
                    
                  </div>
                </div>
              )}
            </Suspense>
          </div>

          <button
            className="block md:hidden"
            onClick={toggleMenu}
          >
            <MdMenu size={24} className="text-customPurple" />
          </button>
        </div>
      </header>
      <Suspense fallback={<div>Loading...</div>}>
        <Modal isOpen={showModal} onClose={closeModal}>
          <LoginModal />
        </Modal>
      </Suspense>
    </>
  );
});

export default NavigationBar;
