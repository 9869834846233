import React, { useEffect, useState, useCallback } from "react";
import BeatLoader from "react-spinners/BeatLoader";
import CreateGroupForm from './CreateGroupForm';
import GroupDetails from './GroupDetails';
import SearchInput from './SearchInput';
import { ApiActions } from "../../actions/ApiActions";
import AppStore from "../../stores/AppStore";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShoppingCart } from '@fortawesome/free-solid-svg-icons';
import useDebounce from './useDebounce';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from "react-toastify";

function GroupManagement() {
  const [searchTerm, setSearchTerm] = useState('');
  const [showCreateForm, setShowCreateForm] = useState(false);
  const [currentGroup, setCurrentGroup] = useState(null);
  const [viewDetails, setViewDetails] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [groups, setGroups] = useState([]);

  const debouncedSearchTerm = useDebounce(searchTerm, 300);

  const handleViewDetails = (group) => {
    setCurrentGroup(group);
    setViewDetails(true);
    setShowCreateForm(false);
  };

  const handleBackToGroups = () => {
    setViewDetails(false);
    setShowCreateForm(false);
  };

  const loadGroups = async () => {
    setIsLoading(true);
    try {
      await ApiActions.loadMeusGrupos();
      const grupos = AppStore.getGrupos();
      console.log("Grupos carregados:", grupos);
      setGroups(grupos);
    } catch (error) {
      console.error('Erro ao carregar grupos', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    loadGroups();

    function handleChanges() {
      setGroups(AppStore.getGrupos());
    }
    
    function handleLoading() {
      setIsLoading(AppStore.getIsLoading());
    }

    AppStore.on("carregando", handleLoading);
    AppStore.on("change_grupos", handleChanges);

    return () => {
      AppStore.removeListener("carregando", handleLoading);
      AppStore.removeListener("change_grupos", handleChanges);
    };
  }, []);

  useEffect(() => {
    let intervalId;
    if (viewDetails) {
      intervalId = setInterval(() => {
        loadGroups();
      }, 5000); // Atualiza a cada 5 segundos
    }
    return () => clearInterval(intervalId);
  }, [viewDetails]);

  const updateCurrentGroup = useCallback(() => {
    if (currentGroup) {
      const updatedGroup = AppStore.getGrupos().find(group => group.id === currentGroup.id);
      if (updatedGroup) {
        if (updatedGroup.socias.length !== currentGroup.socias.length) {
          toast.info("O grupo foi atualizado com novos membros!");
        }
        setCurrentGroup(updatedGroup);
      } else {
        console.error("Grupo não encontrado:", currentGroup.id);
      }
    }
  }, [currentGroup]);

  useEffect(() => {
    updateCurrentGroup();
  }, [groups, updateCurrentGroup]);

  const handleGroupCreated = () => {
    loadGroups();
    setShowCreateForm(false);
  };

  const filteredGroups = groups.filter(group => 
    group.grupo.toLowerCase().includes(debouncedSearchTerm.toLowerCase()) || 
    group.descricao.toLowerCase().includes(debouncedSearchTerm.toLowerCase())
  );

  return (
    <div>
      <ToastContainer />
      {isLoading ? (
        <div className="flex flex-col items-center justify-center">
          <BeatLoader />
          <p>Carregando grupos...</p>
        </div>
      ) : (
        <div className="container mx-auto px-4">
          {!showCreateForm && !viewDetails && (
            <>
              <div className="flex justify-between items-center my-4">
                <SearchInput value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} />
                <button
                  className="bg-customPurple hover:bg-customPurple text-white font-bold py-2 px-4 rounded"
                  onClick={() => setShowCreateForm(true)}
                >
                  Novo Grupo
                </button>
              </div>
            </>
          )}
          {showCreateForm ? (
            <CreateGroupForm onBack={handleBackToGroups} onGroupCreated={handleGroupCreated} />
          ) : viewDetails && currentGroup ? (
            <GroupDetails 
              groupData={currentGroup} 
              onBack={handleBackToGroups} 
              updateGroupData={updateCurrentGroup} 
            />
          ) : (
            <>
              {filteredGroups.length === 0 ? (
                <div className="flex flex-col items-center justify-center">
                  <p className="text-gray-700 text-lg mb-4">Você ainda não faz parte de nenhum grupo.</p>
                  <p className="text-gray-700 text-lg mb-4">Crie um novo grupo para começar a partilhar compras com amigos e familiares!</p>
                </div>
              ) : (
                <div className="flex flex-wrap justify-start items-stretch overflow-auto" style={{ maxHeight: '600px' }}>
                  {filteredGroups.map(group => (
                    <div key={group.id} className="flex flex-col w-full md:w-1/3 p-4">
                      <div className={`relative bg-white rounded overflow-hidden shadow-lg flex flex-col flex-grow ${group.isPurchasing ? 'bg-gray-200' : ''}`}>
                        {group.socias && group.socias.length > 0 && (
                          <FontAwesomeIcon icon={faShoppingCart} className="absolute right-2 top-2 text-green-500" />
                        )}
                        <div className="px-6 py-4 flex flex-col flex-grow">
                          <div className="font-bold uppercase text-xl mb-2">{group.grupo}</div>
                          <p className="text-gray-700 text-base uppercase flex-grow">{group.descricao}</p>
                          <button onClick={() => handleViewDetails(group)} className="bg-customPurple hover:bg-customPurple text-white font-bold py-1 px-2 rounded mt-2">
                            Ver Detalhes
                          </button>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </>
          )}
        </div>
      )}
    </div>
  );
}

export default GroupManagement;
