import React, { useState, useEffect } from 'react';
import logo from "../Images/9894285.jpg";
const OnboardingPage = ({ image, title, description }) => {
  return (
    <div className="p-4 text-white text-center w-full max-w-lg">
      <img src={logo} alt="" className="w-full h-48 object-cover mb-4" />
      <h1 className="text-2xl font-bold mb-4">{title.toUpperCase()}</h1>
      <div className="overflow-y-auto max-h-48 text-justify">
        {description}
      </div>
    </div>
  );
};

const OnboardingView = ({ onClose }) => {
  const [currentPage, setCurrentPage] = useState(0);
    const pages = [
      {
        image: {logo},
        title: 'Bem-vindo à SOCIA: A Sua Nova Forma de Poupar!',
        description: (
          <>
            <p>A nossa plataforma é a resposta para quem quer poupar nas compras diárias.</p>
            
            <p>A ideia é simples:</p>
            <ul>
              <li>Quanto mais pessoas compram juntas, maiores são os descontos que conseguimos.</li>
              <li>Em vez de comprar sozinho, você pode se juntar a um grupo e aproveitar preços mais baixos em produtos que você adora. E o melhor de tudo, é fácil e rápido de usar!</li>
            </ul>
          </>
        )
      },
      {
        image: {logo}, // Substitua por uma imagem real
        title: 'Crie a sua conta',
        description: (
          <>
            <p>Vamos começar a sua jornada! Pode navegar pelas varias categorias sem precisar se cadastrar. Para efetuar uma compra, vai precisar fazer o registro.</p>
            <p>No canto superior direito, clique em "ENTRAR". Para se cadastrar, você precisará fornecer algumas informações básicas, como nome, e-mail e criar uma senha. Preencha o seu endereço e pronto!</p>
            <p>Agora você está oficialmente na nossa comunidade.</p>
          </>
        )
      },
      
      // ... adicione mais páginas aqui, se desejar
    ];

  useEffect(() => {
    const timeout = setTimeout(() => {
      const nextPage = (currentPage + 1) % pages.length;
      setCurrentPage(nextPage);
    }, 6000); // Ajuste o tempo de intervalo (em milissegundos)

    return () => clearTimeout(timeout);
  }, [pages]);

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50">
      <div className="bg-customPurple text-white p-8 rounded-lg shadow-lg relative">
      
        <OnboardingPage
          image={pages[currentPage].image}
          title={pages[currentPage].title}
          description={pages[currentPage].description}
        />
        <div className="text-center mt-4">
         
        </div>
        {/* Indicadores de página (opcional) */}
        <div className="mt-4">
          {pages.map((_, index) => (
            <span
              key={index}
              className={`inline-block h-2 w-2 rounded-full ${
                currentPage === index ? 'bg-white' : 'bg-gray-400'
              } mr-2`}
            ></span>
          ))}
        </div>
      </div>
      <div className="fixed inset-0 bg-black opacity-50"></div>
    </div>
  );
};

export default OnboardingView;
