import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import CryptoJS from 'crypto-js';
import CategorySection from "./CategorySection";

function formatCurrency(value, locale = "pt-AO", currency = "AOA") {
  return new Intl.NumberFormat(locale, {
    style: "currency",
    currency: currency,
  }).format(value);
}

export default function ProdutosRecomendados({ products, onShowModal }) {
  const navigate = useNavigate();

  const handleAddToCart = useCallback((produto) => {
    const token = localStorage.getItem("token");
    if (!token) {
      onShowModal(true);
    } else {
      const key = CryptoJS.enc.Hex.parse('0879febbaf365b1535e175da463ca7bcd7fdc76956282fa33c2cf5a9c28cc1b5');
      const ciphertext = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(produto.id), key, {
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7
      }).toString();
      const safeEncoded = ciphertext.replace(/\//g, '_');
      navigate(`/product/${safeEncoded}`);
    }
  }, [navigate, onShowModal]);

  return (
    <div>
      
      {products.length > 0 ? (
        <div className="space-y-4 py-8">
          {products.map(category => (
            <div key={category.id}>
              <CategorySection title={category.categoria} products={category.produtos} id={category.id } />
            </div>
          ))}
        </div>
      ) : (
        <p>No products available.</p>
      )}
    </div>
  );
}
