import React, { useEffect, useState } from "react";
import BeatLoader from "react-spinners/BeatLoader";
import { ApiActions } from "../../actions/ApiActions";
import AppStore from "../../stores/AppStore";
import { useLocation } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import ProductCard from "./ProductCard";
import CryptoJS from 'crypto-js';

function formatCurrency(value, locale = "pt-AO", currency = "AOA") {
  return new Intl.NumberFormat(locale, {
    style: "currency",
    currency: currency,
  }).format(value);
}

function PriceDisplay({ price }) {
  return <div>{formatCurrency(price)}</div>;
}

export default function Pesquisa({ onShowModal }) {
  const location = useLocation();
  const { searchTerm } = location.state;
  let navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(AppStore.getIsLoading());
  const [products, setProducts] = useState(AppStore.getProducts());
  const { catIds } = useParams();
  const [category, setCategory] = useState(null);
  const handleAddToCart = (produto) => {
    const token = localStorage.getItem("token");
    if (!token) {
      onShowModal(true);
    } else {
        const key = CryptoJS.enc.Hex.parse('0879febbaf365b1535e175da463ca7bcd7fdc76956282fa33c2cf5a9c28cc1b5'); // Ensure key is in correct format
    const ciphertext = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(produto.id), key, {
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7
    }).toString();
       const safeEncoded = ciphertext.replace(/\//g, '_');
      navigate(`/product/${safeEncoded}`);
      // Adicione a lógica para adicionar o item ao carrinho aqui
    }
  };
  useEffect(() => {
    function handleChanges() {
      setProducts(AppStore.getProducts());
    }
    function handleLoading() {
      setIsLoading(AppStore.getIsLoading());
    }

    AppStore.on("carregando", handleLoading);
    AppStore.on("change", handleChanges);

    ApiActions.searchProductsByName(searchTerm);
    return () => {
      AppStore.removeListener("change", handleChanges);
      AppStore.removeListener("carregando", handleLoading);
    };
  }, [searchTerm]);

  if (isLoading) {
    return <div>
    <div className="absolute inset-0 flex flex-col items-center justify-center bg-white bg-opacity-70 z-50">
          <BeatLoader />
          <p className="mt-4 text-customPurple">A preparar a lista de produtos para si...</p>
        </div>
    </div>;
  }

  return (
    <div>
  <div className="">
    <h1 className="scroll-m-20 pb-5 text-2xl font-semibold tracking-tight first:mt-0 text-customPurple">
      Pesquisando por: {searchTerm}
    </h1>
  </div>
  {products.length === 0 ? (
    <main className="flex flex-col items-center justify-center p-28">
    <img
      src="https://illustrations.popsy.co/fuchsia/question-mark.svg"
      alt="Carrinho vazio"
      className="h-52 select-none object-contain"
    />
    <h1 className="scroll-m-20 text-2xl text-customPurple font-extrabold tracking-tight lg:text-4xl select-none">
      Nenhum produto encontrado
    </h1>
  </main>
  ) : (
    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-4 gap-4 py-8">
      {products.map((produto, index) => (
        <ProductCard
          key={index}
          addCarrinho={handleAddToCart}
          produto={produto}
        />
      ))}
    </div>
  )}
</div>

  );
}
