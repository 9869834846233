import React, { useState, useEffect } from "react";
import { format, parseISO } from 'date-fns';
import { pt } from 'date-fns/locale';
import { FaEllipsisV } from "react-icons/fa";

// Método para formatar a data
const retornaRData = (dateString) => {
  const date = parseISO(dateString);
  return format(date, "eeee, dd MMMM yyyy", { locale: pt });
};

const MySociaCard = ({ socia, verdetalhe, sairDoGrupo }) => {
  const [secondsLeft, setSecondsLeft] = useState(50);
  const [menuOpen, setMenuOpen] = useState(false);
  const formattedDate = retornaRData(socia.data);

  useEffect(() => {
    const interval = setInterval(() => {
      setSecondsLeft((seconds) => (seconds > 0 ? seconds - 1 : 0));
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  function formatCurrency(value, locale = "pt-AO", currency = "AOA") {
    return new Intl.NumberFormat(locale, {
      style: "currency",
      currency: currency,
    }).format(value);
  }

  const progressPercentage = (socia.candidatos / socia.socios) * 100;
  const foto = socia.capa;

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const handleSairDoGrupo = (socia) => {
    sairDoGrupo(socia);
    setMenuOpen(false); // Fechar o menu após a ação
  };

  return (
    <div className="bg-white rounded-lg shadow-lg overflow-hidden max-w-sm mx-auto relative">
      <img src={foto} alt={socia.nome} className="w-full h-48 object-cover" />
      <div className="absolute top-2 right-2">
        <FaEllipsisV className="text-gray-800 cursor-pointer" onClick={toggleMenu} />
        {menuOpen && (
          <div className="absolute right-0 mt-2 w-48 bg-white border border-gray-300 rounded shadow-lg z-10">
            <button
              className="block w-full text-left px-4 py-2 text-gray-800 hover:bg-gray-100"
              onClick={() => handleSairDoGrupo(socia)}
            >
              Sair do grupo
            </button>
          </div>
        )}
      </div>
      <div className="p-4">
        <h2 className="text-lg font-bold text-gray-800">{socia.nome} </h2>
        <p className="text-sm text-gray-600 uppercase mt-2">
          {socia.produtos[0].quantidade} {socia.produtos[0].forma} de {socia.produtos[0].produto}
        </p>
        
        <div className="flex items-center mt-2">
          <span className="text-gray-800 line-through mr-2">
            {formatCurrency(socia.preco + socia.desconto)}
          </span>
          <span className="text-green-600 font-bold">
            {formatCurrency(socia.preco)}
          </span>
        </div>
        
        <p className="text-sm text-gray-600 uppercase mt-2">
          Você economizará {formatCurrency(socia.desconto)}
        </p>
        <div className="text-sm text-gray-600 uppercase mt-2">
          <span className="text-sm uppercase text-gray-600">Oferta válida até </span>
          <span className="text-red-600 uppercase">{formattedDate}</span>
        </div>
        <button
          className="w-full py-2 mt-4 bg-customPurple text-white rounded"
          onClick={() => verdetalhe(socia)}
        >
          Ver detalhes
        </button>
      </div>
      <div className="px-4 pb-4">
        <p className="text-xs text-gray-500">
          {socia.candidatos} de {socia.socios} sócios já se juntaram
        </p>
        <div className="w-full bg-gray-200 rounded-full h-2.5 mt-2">
          <div
            className="bg-blue-600 h-2.5 rounded-full"
            style={{ width: `${progressPercentage}%` }}
          ></div>
        </div>
      </div>
    </div>
  );
};

export default MySociaCard;
