// ItemDetailModal.js
import React, { useState } from 'react';
import { useSelectedItem } from './SelectedItemContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';

function formatCurrency(value, locale = 'pt-AO', currency = 'AOA') {
    return new Intl.NumberFormat(locale, {
        style: 'currency',
        currency: currency,
    }).format(value);
}
function formatDisplayForma(value) {
    if (value === 'Unidades') {
         return 'UN'
    } else if (value === 'Kilogramas') {
        return 'KG'
    } else {
        return value
    }
    
   
}
export const ItemDetailModal = () => {
    const { selectedItem, closeModal } = useSelectedItem();
    const [selectedOfferIndex, setSelectedOfferIndex] = useState(null);
  const [customQuantity, setCustomQuantity] = useState('');
    const [selectedSavings, setSelectedSavings] = useState(0);
    const [selectedQuantity, setSelectedQuantity] = useState(0);

    const bgClasses = [
  'bg-red-100', 'bg-green-100', 'bg-blue-100', 'bg-yellow-100', 'bg-pink-100',
  'bg-indigo-100', 'bg-purple-100', 'bg-orange-100'
];
  const handleOfferSelect = (index) => {
      setCustomQuantity('');
        const offer = selectedItem.socios[index]
    setSelectedSavings(offer.pupanca)
    setSelectedQuantity(offer.quantidade ) 
    setSelectedOfferIndex(index);
     // Reset custom quantity when an offer is selected
  };

    const handleCustomQuantityChange = (e) => {
        const inputQuantity = Number(e.target.value);
  if (inputQuantity <= selectedItem.stoqueExistente) {
      setCustomQuantity(inputQuantity);
        setSelectedSavings(0)
     setSelectedQuantity(inputQuantity)
      setSelectedOfferIndex(null); // Clear selected offer
    
  } else {
      alert(`The quantity cannot exceed the available stock of ${selectedItem.stoqueExistente}.`);
      setCustomQuantity(selectedItem.stoqueExistente); // Optional: adjust behavior as needed
       setSelectedSavings(0)
     setSelectedQuantity(selectedItem.stoqueExistente) 
        } // Deselect any offer when custom quantity is entered
  };

 const handleSubmit = async () => {
    if(selectedOfferIndex === null && selectedQuantity == 0) {
      alert("Please select an offer before submitting.");
      return;
    }
    
   // const selectedOffer = offers[selectedOfferIndex];
   const token = localStorage.getItem('token');
    const requestBody = {
     // title: selectedOffer.title,
      quantidade: selectedQuantity,
      //price: selectedOffer.price,
      desconto: selectedSavings,
      idProduto : selectedItem.id
    };

    try {
      const response = await fetch('https://api.socia.ao/apiV2/Socia/AddProdutoCarrinho', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'ApiKey': '9MT3VnQYj2Mb7ILNqKy2HfF9AChqPeFwtDQ8QkGBZZ0=',
          'Authorization': 'Bearer '+token
        
        },
        body: JSON.stringify(requestBody),
      });

      if (response.ok) {
        const responseData = await response.json();
     
           alert("Produto adicionado ao Carrinho.");
        // Handle successful purchase (e.g., display a success message or redirect)
      } else {
        console.error('Failed to submit offer:', response.statusText);
        // Handle request failure (e.g., display an error message)
         alert("Não foi possivel adicionar o produto ao Carrinho.");
      }
    } catch (error) {
      console.error('Error submitting offer:', error);
      // Handle network errors (e.g., display an error message)
    }
  };

    if (!selectedItem) return null; // Only show the modal if there's a selected item

    return (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
            <div className="bg-white w-full md:w-3/4 lg:w-1/2 m-auto mt-24 rounded overflow-hidden shadow-lg flex flex-col">
                <div className="flex flex-row">
                    <img className="w-1/4 object-cover pl-4 pr-4" src={selectedItem.foto || "https://placehold.co/600x600"} alt={selectedItem.produto.toUpperCase() || "Placeholder"} />
                    <div className="w-3/4 p-6">
                        <div className="font-bold text-xl mb-2">{selectedItem.produto.toUpperCase() || "Title of the Product"}</div>
                        <div className="rating-stars mb-2 flex">
                            {[...Array(5)].map((_, index) => (
                                <FontAwesomeIcon key={index} icon={faStar} className="text-yellow-400" />
                            ))}
                            <span className="ml-2">(120 reviews)</span>
                        </div>
                        <p className="text-red-600 text-xl">{formatCurrency(selectedItem.preco / selectedItem.quantidade)} <span className="text-gray-400 line-through"></span> / {formatDisplayForma(selectedItem.dividoPor)}   </p>
                        <p className='text-gray-700 text-base text-justify'>{selectedItem.description || "Encontre uma sócia para as tuas compras! Escolha entre as nossas ofertas especiais com desconto , Caso a quantidade desejada não esteja disponível no momento, não há motivo para preocupação! Você tem a possibilidade de nos informar exatamente quantas unidades precisa."}</p>
                    </div>
                </div>
                <hr className="my-4" />
                <label htmlFor="customQuantity" className="font-bold text-xl mb-2 pt-1">OFERTAS COM POUPANÇA</label>
                <div className="grid grid-flow-col auto-cols-max justify-items-center gap-4 mt-4 overflow-auto pl-4 pr-4">
                    {selectedItem.socios.map((offer, index) => (
                       <div
          
                            key={index}
          
                            className={`p-4 border rounded-md shadow-lg w-30 ${selectedOfferIndex === index ? 'border-blue-500' : 'border-gray-300'} hover:border-blue-500 cursor-pointer ${bgClasses[index % bgClasses.length]}`}
          
                            onClick={() => handleOfferSelect(index)}>
          
                            <p className="text-gray-700 text-sm"> Levas<span className="text-lg font-semibold text-gray-700"> {offer.quantidade} </span>{selectedItem.dividoPor}</p>
          
                            <p className="text-gray-700 text-sm">Pagas: <span className="my-3 text-lg font-semibold">{formatCurrency(offer.preco)} </span> </p>
          
                            <p className="text-gray-700 text-sm">Poupas:<span className="my-3 text-lg font-semibold"> {formatCurrency(offer.pupanca)} </span> </p>
        
                        </div>
                        
                    ))}

                </div>
                 <div className="flex flex-col items-center mb-4">
        <label htmlFor="customQuantity" className="font-bold text-xl mb-2 pt-4">PREFERE UMA QUANTIDADE DIFERENTE ?</label>
        <input
          type="number"
          id="customQuantity"
          className="form-input px-4 py-2 border rounded-lg"
          value={customQuantity}
          onChange={handleCustomQuantityChange}
          placeholder="Indique a Quantidade Desejada"
        />
      </div>
                <div>

                    <div className="flex items-center p-6">
  
                        <div className="flex-1"></div> 

    
                        <button className="px-4 py-2 bg-customPurple text-white font-semibold rounded-lg hover:bg-blue-700" style={{ width: "50%" }} onClick={handleSubmit}>
        
                            ADICIONAR AO CARRINHO
  
                        </button>

  
                        <div className="flex-1 flex justify-end">
    
                            <button onClick={closeModal} className="opacity-75 bg-red-500 hover:bg-red-600 hover:opacity-100 text-white font-bold py-2 px-4 rounded">
      
                                VOLTAR
    
                            </button>
  
                        </div>

                    </div>

               
                </div>
                
            </div>
        </div>
    );
};

export default ItemDetailModal;

