import React, { useEffect, useState } from "react";
import axios from "axios";
import Popup from "reactjs-popup";
import { useUser } from "../UserContext";
import ExplicacaoExpress from "./ExplicacaoExpress";
import MySociaCard from "./CardSociaExpress";
import { useNavigate } from "react-router-dom";
import CryptoJS from 'crypto-js';
const calculaDiferencaEmMinutos = (dataComoString) => {
  const dataFornecida = new Date(dataComoString);
  const agora = new Date();

  const diferencaEmMilissegundos = agora - dataFornecida;

  const diferencaEmMinutos = Math.floor(diferencaEmMilissegundos / 60000);
  console.error(diferencaEmMinutos);
  return diferencaEmMinutos;
};
export default function SociaExpress({ onShowModal }) {
    let navigate = useNavigate();
     const token = localStorage.getItem("token");
  const handleDetakheAddToCart = (socia) => {

    if (!token) {
      onShowModal(true);
    } else {
 const key = CryptoJS.enc.Hex.parse('0879febbaf365b1535e175da463ca7bcd7fdc76956282fa33c2cf5a9c28cc1b5'); // Ensure key is in correct format
    const ciphertext = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(socia.id), key, {
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7
    }).toString();
     
      navigate(`/socia/${ciphertext}`, { state: { socia } });
    }
  };
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const { user } = useUser(); // Use o hook useUser para acessar o estado do usuário

  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);

  function formatCurrency(value, locale = "pt-AO", currency = "AOA") {
    return new Intl.NumberFormat(locale, {
      style: "currency",
      currency: currency,
    }).format(value);
  }

  function PriceDisplay({ price }) {
    // Example: formatCurrency(1234.56, 'pt-AO', 'AOA') => Kz1,234.56
    return <div>{formatCurrency(price)}</div>;
  }
  const handleAddToCart = () => {
    if (!user.isLoggedIn) {
      // Se o usuário não estiver logado, abre o modal de login
      setIsLoginModalOpen(true);
    } else {
      // Adicione a lógica para adicionar o item ao carrinho aqui
    }
  };

  const [data, setData] = useState([]);
  const [gdata, setGData] = useState([]);

  

  useEffect(() => {
    if (localStorage.getItem("token")) {
      const fetchDataGroup = async () => {
        try {
          const token = localStorage.getItem("token");
          const response = await axios.get(
            "https://api.socia.ao/apiV2/Socia/ComprasEmGrupoExpress",
            {
              headers: {
                ApiKey: "9MT3VnQYj2Mb7ILNqKy2HfF9AChqPeFwtDQ8QkGBZZ0=",
                Authorization: `Bearer ${token}`,
              },
            }
          );
          setGData(response.data.objecto);
         
        } catch (error) {
          console.error(error);
        }
      };

      fetchDataGroup();
    }
  }, []);
     if (!localStorage.getItem("token")) {
      return <ExplicacaoExpress/>
    }
    if (gdata.length < 1) { 
        return (
    <div>
      <h1>Ainda Não Há "Sócias" Ativas... Mas Não Desanime!</h1>
      <p>Atualmente, não existem "Sócias" formadas, mas isso não significa que as oportunidades de poupança tenham acabado.</p>
    </div>
  );
    } else {
       return (
    <div className="">
      
                <div>
          <div className="flex flex-col items-start justify-center mt-10">
            <h1 className="scroll-m-20 pb-5 text-2xl font-semibold tracking-tight first:mt-0 text-customPurple">
              Sócia express
            </h1>
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 gap-8 py-8">
            {gdata.length > 0 ? (
              gdata.map((produto, index) => (
                <MySociaCard
                key={index}
                  socia={produto}
                  verdetalhe={handleDetakheAddToCart}
                />
              ))
            ) : (
              <p></p>
            )}
          </div>
        </div>
     
    </div>
  ); 
    }
  
}
