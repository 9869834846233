import React, { useState, useEffect } from 'react';
import { toast } from "react-toastify";
import { ApiActions } from "../../actions/ApiActions";
import CryptoJS from 'crypto-js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserPlus, faTimes } from '@fortawesome/free-solid-svg-icons';

function GroupDetails({ groupData, onBack, updateGroupData }) {
  const [isAdding, setIsAdding] = useState(false);
  const [inviteLink, setInviteLink] = useState('');
  const [selectedSocia, setSelectedSocia] = useState(null);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const secretKey = '4d3f7a4b567c1b8a3e2d1f9c8a7b6d4f3c2b1e0d9c8a7b6f4e3d2c1b0a9f8e7';
  const [socias, setSocias] = useState(groupData?.socias || []);
  const [membrosDisponiveis, setMembrosDisponiveis] = useState(groupData?.membro || []);
 const [isInvitePopupOpen, setIsInvitePopupOpen] = useState(false);
  const [friendPhoneNumber, setFriendPhoneNumber] = useState('');
    const notificarSucesso = () => {
    toast.success('Parabéns o seu convidade foi adicionado  ao grupo de compras!');
  };

  const notificarErro = () => {
    toast.error('Lamento, não foi possível concluir a  adesão ao grupo de compras');
  };
  const handleInviteFriend = () => {
    // Aqui você pode adicionar a lógica para enviar o convite para o amigo
    // usando o número de telefone armazenado em friendPhoneNumber
    console.log(`Inviting friend with phone number: ${friendPhoneNumber}`);
     ApiActions.joinGrupotelefone(groupData.id ,friendPhoneNumber )
      .then(() => {
        notificarSucesso();
       
      })
      .catch((error) => {
        console.error('Error adding to group:', error);
        notificarErro();
      })
      .finally(() => {
         setIsInvitePopupOpen(false); // Reset submitting state
      });
  
  };
  useEffect(() => {
    const intervalId = setInterval(() => {
      updateGroupData();
    }, 5000); // Atualiza a cada 5 segundos

    return () => clearInterval(intervalId);
  }, [updateGroupData]);

  useEffect(() => {
    setSocias(groupData.socias);
    setMembrosDisponiveis(groupData.membro);
  }, [groupData]);

  const handleInvitePeople = (socia) => {
    setSelectedSocia(socia);
    setIsSidebarOpen(true);
  };

  const generateInviteLink = () => {
    const currentDate = new Date(Date.now());
    const day = currentDate.getDate() + 1;
    const month = currentDate.getMonth() + 1;
    const year = currentDate.getFullYear();
    const formattedDate = `${year}-${month > 9 ? month : '0' + month}-${day}`;
    const key = CryptoJS.enc.Hex.parse('0879febbaf365b1535e175da463ca7bcd7fdc76956282fa33c2cf5a9c28cc1b5');
    const ciphertext = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(groupData.id), key, {
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7
    }).toString();
    const safeEncoded = ciphertext.replace(/\//g, '_');

    const dateParam = new Date(formattedDate).toISOString().split('T')[0];
    const ciphertextdata = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(dateParam), key, {
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7
    }).toString();
    const safeEncodedData = ciphertextdata.replace(/\//g, '_');

    const data = `${safeEncoded}${dateParam}`;
    const hash = CryptoJS.HmacSHA256(data, secretKey).toString();
    const link = `${window.location.origin}/invite?acordos=${safeEncodedData}&canjala=${safeEncoded}&hash=${hash}`;

    try {
      navigator.clipboard.writeText(link);
      toast.success('Texto copiado para a área de transferência!');
    } catch (err) {
      toast.error('Falha ao copiar: ');
    }
    setInviteLink(link);
  };

  const handleSairGrupo = (id) => {
    if (window.confirm('Tem certeza de que deseja sair deste grupo de compras?')) {
      setIsAdding(true);
      ApiActions.SairGrupo(id)
        .then(() => {
          updateGroupData();
          setIsAdding(false);
          onBack();
          toast.success("Concluímos a sua saída!".toUpperCase());
        })
        .catch((error) => {
          toast.error("Não foi possível sair do grupo de compras".toUpperCase());
          setIsAdding(false);
        });
    }
  };

  const handleAddToCart = (id) => {
    setIsAdding(true);
    ApiActions.joinSocia(id)
      .then(() => {
        setIsAdding(false);
        toast.success("Parabéns!");
        setSocias(socias.map(socia => 
          socia.id === id ? { ...socia, isIn: true, aderentes: [...socia.aderentes, 'Você'], vagas: socia.vagas - 1 } : socia
        ));
      })
      .catch((error) => {
        console.error("Error adding to cart:", error);
        toast.error("Não foi possível aderir ao grupo de compras".toUpperCase());
        setIsAdding(false);
      });
  };

  const handleAddMembro = (membro, IdSocia) => {
    setIsAdding(true);
    ApiActions.joinMembroSocia(IdSocia, membro.id)
      .then(() => {
        setIsAdding(false);
        toast.success(`Parabéns! ${membro.ultimoNome} foi adicionado à compra`.toUpperCase());
        setSocias(socias.map(socia => 
          socia.id === IdSocia ? { ...socia, aderentes: [...socia.aderentes, membro.ultimoNome], vagas: socia.vagas - 1 } : socia
        ));
        setMembrosDisponiveis(membrosDisponiveis.filter(m => m.id !== membro.id));
      })
      .catch((error) => {
        toast.error(`Não foi possível adicionar ${membro.ultimoNome} à compra`.toUpperCase());
        setIsAdding(false);
      });
  };

  const formatCurrency = (value, locale = "pt-AO", currency = "AOA") => {
    return new Intl.NumberFormat(locale, {
      style: "currency",
      currency: currency,
    }).format(value);
  };

  return (
    <div className="container mx-auto p-4 flex flex-col lg:flex-row">
      <div className={`flex-1 ${isSidebarOpen ? 'lg:w-2/3 lg:pr-10' : 'w-full'} transition-all duration-300`}>
        
        <div className="flex justify-between items-center my-4">
          <button onClick={onBack} className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded mb-4">
            Voltar
          </button>
          <div className="flex space-x-4">
            <button onClick={() => setIsInvitePopupOpen(true)} className="bg-customPurple hover:bg-customPurple text-white font-bold py-2 px-4 rounded mb-4 flex items-center">
              <FontAwesomeIcon icon={faUserPlus} className="mr-2" />
              Convidar Amigos
            </button>
            {!groupData.isAdmin && (
              <button onClick={() => handleSairGrupo(groupData.id)} className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded mb-4 flex items-center">
                <FontAwesomeIcon icon={faTimes} className="mr-2" />
                Sair do Grupo
              </button>
            )}
          </div>
        </div>
        <div className="text-center">
          <h1 className="text-2xl font-bold mb-4">{groupData.name}</h1>
          <p className="mb-4">{groupData.descricao}</p>
        </div>
       
        <div>
          <h2 className="text-xl font-semibold mt-4 mb-2">COMPRAS A DECORRER</h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
            {socias.map(product => (
              <div key={product.id} className="relative border p-3 rounded shadow-lg flex flex-col h-full">
                {groupData.isAdmin && (
                  <>
                    {product.vagas > 0 && (
                      <div className="absolute top-2 right-2">
                        <FontAwesomeIcon
                          icon={faUserPlus}
                          className="text-customPurple hover:text-customPurple cursor-pointer"
                          onClick={() => handleInvitePeople(product)}
                          size="lg"
                        />
                      </div>
                    )}
                  </>
                )}
                {product.vagas <= 0 && (
                  <div className="absolute top-2 right-2 bg-red-500 text-white px-2 py-1 rounded">
                    COMPLETO
                  </div>
                )}
                <img src={product.produtos[0].foto} alt={product.name} className="w-full h-40 object-cover mb-2" />
                <h3 className="text-lg uppercase font-bold mb-2">{product.produtos[0].produto}</h3>
                <h4 className="text-lg uppercase font-bold mb-2">{product.produtos[0].quantidade} {product.produtos[0].forma}</h4>
                {product.vagas > 0 && (
                  <h4 className="text-lg uppercase font-bold mb-2">{product.vagas} {product.vagas > 1 ? "VAGAS" : "VAGA"}</h4>
                )}
                <div className="text-center mb-4">
                  <p className="text-red-500 text-lg line-through">{formatCurrency(product.preco)}</p>
                  <p className="text-lg uppercase font-bold mb-2">{formatCurrency(product.preco - product.desconto)}</p>
                </div>
                <div className="mb-4 overflow-hidden">
                  <h4>Pessoas na compra:</h4>
                  <p>{product.aderentes.join(', ')}</p>
                </div>
                {product.vagas > 0 && (
                  <button
                    onClick={() => handleAddToCart(product.id)}
                    className={`mt-auto w-full font-bold py-2 px-4 rounded ${product.isIn ? 'bg-customPurple hover:bg-customPurple' : 'bg-green-500 hover:bg-green-700'} text-white`}
                  >
                    {product.isIn ? 'Sair da Compra' : 'Aderir à Compra'}
                  </button>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
      {isSidebarOpen && selectedSocia && (
        <div className="flex flex-col w-full lg:w-1/4 bg-neutral-50 rounded-md shadow-lg z-10">
          <button
            className="self-end p-2"
            onClick={() => setIsSidebarOpen(false)}
          >
            <FontAwesomeIcon icon={faTimes} className="text-gray-700" size="lg" />
          </button>
          <div className="p-4">
            <h5 className="text-lg uppercase font-semibold mb-4">
              Quem você quer colocar na compra de {selectedSocia.produtos[0].quantidade} {selectedSocia.produtos[0].forma} de {selectedSocia.produtos[0].produto}
            </h5>
            <ul>
              <hr className="border-t-1 border-gray-300 my-4"></hr>
              {membrosDisponiveis.map(member => (
                <li key={member.id} className="flex justify-between items-center mb-2">
                  <span>{member.ultimoNome} {member.telefone} </span>
                  <button
                    className="bg-customPurple hover:bg-customPurple text-white font-bold py-1 px-2 rounded flex items-center"
                    onClick={() => handleAddMembro(member, selectedSocia.id)}
                  >
                    <FontAwesomeIcon icon={faUserPlus} className="" />
                  </button>
                </li>
              ))}
            </ul>
          </div>
        </div>
      )}
     {isInvitePopupOpen && (
  <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-75 z-50">
    <div className="bg-white rounded shadow-md p-6">
      <h2 className="text-2xl font-semibold text-center mb-4">Adicione mais um amigo</h2>
      <p className="text-gray-700 mb-4">
     Junte mais amigos ao grupo de compras para obter descontos ainda maiores!
      </p>
      <div className="flex flex-col md:flex-row">
       <input
  type="tel"
  value={friendPhoneNumber}
  onChange={(e) => setFriendPhoneNumber(e.target.value)}
  placeholder="9xxxxxxxx"
  pattern="^\[0-9]{9}$"
  className="w-full md:w-1/2 mr-2 px-3 py-2 rounded border border-gray-300 focus:outline-none focus:border-sky-500"
/>
        <div className="flex justify-end">
          <button
            onClick={handleInviteFriend}
            className="bg-customPurple hover:bg-customPurple text-white font-bold py-2 px-4 rounded disabled:opacity-50"
          >
            Adicionar
          </button>
          <button
            onClick={() => setIsInvitePopupOpen(false)}
            className="ml-2 bg-gray-200 hover:bg-gray-300 text-gray-700 font-bold py-2 px-4 rounded"
          >
            Cancelar
          </button>
        </div>
      </div>
    </div>
  </div>
)}
    </div>
  );
}

export default GroupDetails;
