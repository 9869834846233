import React, { useEffect, useState, useRef } from "react";

const RouletteWheel = () => {
  const canvasRef = useRef(null);
  const [numeroSorteado, setNumeroSorteado] = useState(null); 
  const colors = [
    "#B8D430",
    "#3AB745",
    "#029990",
    "#3501CB",
    "#2E2C75",
    "#673A7E",
    "#CC0071",
    "#F80120",
    "#F35B20",
    "#FB9A00",
  ];
  const virtudes = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"];
  let startAngle = 0;
  const arc = Math.PI / 5; 
  let spinTimeout = null;
  let spinAngleStart = 0;
  let spinTime = 0;
  let spinTimeTotal = 0;
  let ctx = null;

  const drawRouletteWheel = () => {
    const canvas = canvasRef.current;
    if (canvas.getContext) {
      const outsideRadius = 200;
      const textRadius = 140;
      const insideRadius = 1;

      ctx = canvas.getContext("2d");
      ctx.clearRect(0, 0, 700, 700);

      ctx.strokeStyle = "black";
      ctx.lineWidth = 2;

      ctx.font = "bold 2vw sans-serif";

      for (let i = 0; i < 10; i++) {
        const angle = startAngle + i * arc;
        ctx.fillStyle = colors[i];

        ctx.beginPath();
        ctx.arc(400, 400, outsideRadius, angle, angle + arc, false);
        ctx.arc(400, 400, insideRadius, angle + arc, angle, true);
        ctx.stroke();
        ctx.fill();

        ctx.save();
        ctx.shadowOffsetX = -1;
        ctx.shadowOffsetY = -1;
        ctx.shadowBlur = 0;
        // ctx.shadowColor = "rgb(220,220,220)";
        ctx.fillStyle = "black";
        ctx.translate(
          400 + Math.cos(angle + arc / 2) * textRadius,
          400 + Math.sin(angle + arc / 2) * textRadius
        );
        ctx.rotate(angle + arc / 2 + Math.PI / 2);
        const text = virtudes[i];
        ctx.fillText(text, -ctx.measureText(text).width / 1, 0);
        ctx.restore();
      }

      // Arrow
      ctx.fillStyle = "#7A2089";
      ctx.beginPath();
      ctx.moveTo(400 - 8, 400 - (outsideRadius + 10)); // Ajuste de coordenadas para aumentar o tamanho da seta
      ctx.lineTo(400 + 8, 400 - (outsideRadius + 10)); // Ajuste de coordenadas para aumentar o tamanho da seta
      ctx.lineTo(400 + 8, 400 - (outsideRadius - 10)); // Ajuste de coordenadas para aumentar o tamanho da seta
      ctx.lineTo(400 + 18, 400 - (outsideRadius - 10)); // Ajuste de coordenadas para aumentar o tamanho da seta
      ctx.lineTo(400 + 0, 400 - (outsideRadius - 26)); // Ajuste de coordenadas para aumentar o tamanho da seta
      ctx.lineTo(400 - 18, 400 - (outsideRadius - 10)); // Ajuste de coordenadas para aumentar o tamanho da seta
      ctx.lineTo(400 - 8, 400 - (outsideRadius - 10)); // Ajuste de coordenadas para aumentar o tamanho da seta
      ctx.lineTo(400 - 8, 250 - (outsideRadius + 10)); // Ajuste de coordenadas para aumentar o tamanho da seta
      ctx.fill();
    }
  };

  useEffect(() => {
    drawRouletteWheel();
  }, []); 
  const spin = () => {
    spinAngleStart = Math.random() * 10 + 10;
    spinTime = 0;
    spinTimeTotal = Math.random() * 3 + 4 * 1000;
    rotateWheel();
  };

  const rotateWheel = () => {
    spinTime += 30;
    if (spinTime >= spinTimeTotal) {
      stopRotateWheel();
      return;
    }
    const spinAngle =
      spinAngleStart - easeOut(spinTime, 0, spinAngleStart, spinTimeTotal);
    startAngle += (spinAngle * Math.PI) / 180;
    drawRouletteWheel();
    spinTimeout = setTimeout(rotateWheel, 30);
  };

const stopRotateWheel = () => {
    clearTimeout(spinTimeout);
    const degrees = (startAngle * 180) / Math.PI + 90;
    const arcd = (arc * 180) / Math.PI;
    const index = Math.floor((360 - (degrees % 360)) / arcd);
    setNumeroSorteado(virtudes[index]); 
    ctx.save();
    ctx.font = "30px sans-serif";
    // const text = virtudes[index];
    // ctx.fillText(text, 400 - ctx.measureText(text).width / 2, 400 + 30);
    ctx.restore();
  };

  const easeOut = (t, b, c, d) => {
    const ts = (t /= d) * t;
    const tc = ts * t;
    return b + c * (tc + -3 * ts + 3 * t);
  };

  return (
    <div className="flex flex-col lg:flex-row justify-between">
      <div>
        <canvas
          ref={canvasRef}
          id="wheelcanvas"
          width="700"
          height="700"
        ></canvas>
      </div>
      <div>
        <div className=" p-5">
          <div className="flex flex-col items-center justify-center gap-5 shadow-lg rounded-md bg-gray-50 p-10">
            {numeroSorteado !== null && (
              <p className=" text-green-500 text-lg font-semibold mb-5">
                Número sorteado: {numeroSorteado}
              </p>
            )}
            <button
              value="girar"
              onClick={spin}
              type="button"
              className="w-full bg-customPurple font-semibold text-white rounded-md px-4 py-2"
            >
              Girar para ganhar
            </button>
          </div>
          <div className="h-6"></div>
          <div className="shadow-lg rounded-md bg-gray-50 w-full p-5">
            <h2 className="font-bold text-xl p-4 text-center">COMO FUNCIONA</h2>
            <div className="flex justify-start items-start mb-4">
              <ul>
                <li>
                  <p className="text-xs mb-2 text-left">
                    <span className="text-black font-bold uppercase">
                      Rodada da Sorte:
                    </span>
                    &nbsp;O cliente gira a roleta e pode ganhar um produto, um bônus ou um desconto.
                  </p>
                </li>
                <li>
                  <p className="text-xs mb-2 text-left">
                    <span className="text-black font-bold uppercase">
                      Limite por Produto:
                    </span>
                    &nbsp;Apenas um produto pode ser adquirido pelo preço promocional em cada rodada.
                  </p>
                </li>
                <li>
                  <p className="text-xs mb-2 text-left">
                    <span className="text-black font-bold uppercase">
                      Aplicação do Desconto:
                    </span>
                    &nbsp;O preço promocional só é aplicado se a compra não exceder o limite máximo permitido por produto.
                  </p>
                </li>
              </ul>
            </div>
            <p className="text-xs mb-2 text-left">
              <span className="text-black-500 font-bold uppercase">
                Exemplo:
              </span>
              &nbsp;Se a roleta oferece um desconto de 20% em um produto com limite de 2 unidades por rodada:
            </p>
            <ul>
              <li>
                <p className="text-xs mb-2 text-left">
                  <span className="text-black-500 font-bold uppercase">
                    Você Poderá:
                  </span>
                  &nbsp;Comprar 2 unidades com 20% de desconto.
                </p>
              </li>
              <li>
                <p className="text-xs mb-2 text-left">
                  <span className="text-black-500 font-bold uppercase">
                    Você Não Poderá:
                  </span>
                  &nbsp;Comprar 3 unidades com 20% de desconto, pois isso excederia o limite máximo.
                </p>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RouletteWheel;
