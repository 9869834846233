import React, { useEffect, useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import CryptoJS from "crypto-js";
import axios from "axios";
import AppStore from "../../stores/AppStore";
import FetchSocias from "../SOCIA/FetchSocias";
import Sociaexpress from "../SOCIA/ExpressHome";
import ProductsHome from "../PRODUTO/ProductsHome";
import OnboardingView from "./OnboardingPage";
import DespensaHome from "../DESPENSA/DespensaHome";
import ProdutosRecomendados from "../PRODUTO/ProdutosRecomendados";
import InviteCTA from "../CTA/Inviter";
import { ApiActions } from "../../actions/ApiActions";

export default function Inicio({ onShowModal }) {
  const [isLoading, setIsLoading] = useState(false);
  const [despensaData, setDespensaData] = useState(null);
  const [loggedIn, setLoggedIn] = useState(false);
  const [temporarioExibido, setTemporarioExibido] = useState(false);
  const [products, setProducts] = useState(AppStore.getRecomendados());
  const [showOnboarding, setShowOnboarding] = useState(false);
  const navigate = useNavigate();
  const token = localStorage.getItem("token");

  const handleDetalheAddToCart = useCallback(
    (produto) => {
      if (!token) {
        onShowModal(true);
      } else {
        const key = CryptoJS.enc.Hex.parse(
          "0879febbaf365b1535e175da463ca7bcd7fdc76956282fa33c2cf5a9c28cc1b5"
        );
        const ciphertext = CryptoJS.AES.encrypt(
          CryptoJS.enc.Utf8.parse(produto.id),
          key,
          {
            mode: CryptoJS.mode.ECB,
            padding: CryptoJS.pad.Pkcs7,
          }
        ).toString();
        const safeEncoded = encodeURIComponent(ciphertext.replace(/\//g, "_"));
        navigate(`/promo/${safeEncoded}`, { state: { produto } });
      }
    },
    [navigate, token, onShowModal]
  );

  const marcarTemporarioExibido = useCallback(() => {
    localStorage.setItem("tmpexcta", true);
    setTemporarioExibido(true);
  }, []);

  

  useEffect(() => {
   

    setLoggedIn(true);

    const fetchDespensaData = async () => {
      try {
        const response = await axios.get("https://api.socia.ao/apiV2/Socia/PredictDispensa", {
          headers: {
            apiKey: "9MT3VnQYj2Mb7ILNqKy2HfF9AChqPeFwtDQ8QkGBZZ0=",
            Authorization: `Bearer ${token}`,
          },
        });
        setDespensaData(response.data.objecto);
         console.error("despensa data:");
      } catch (error) {
        console.error("Error fetching despensa data:", error);
      }
    };
    const fetchCategoriasProdutos = async () => {
        setIsLoading(true);
      try {
        const response = await axios.get("https://api.socia.ao/apiV2/Socia/CategoriaComProdutos", {
          headers: {
            apiKey: "9MT3VnQYj2Mb7ILNqKy2HfF9AChqPeFwtDQ8QkGBZZ0=",
            Authorization: `Bearer ${token}`,
          },
        });
        setProducts(response.data.objecto);
         console.error("CategoriaProduto:", response.data.objecto);
         setIsLoading(false);
      } catch (error) {
         setIsLoading(false);
        console.error("Error fetching despensa data:", error);
      }
    };

    fetchDespensaData();
    fetchCategoriasProdutos();
    const temporarioJaExibido = localStorage.getItem("tmpexcta");
    if (temporarioJaExibido) {
      setTemporarioExibido(true);
    } else {
      setShowOnboarding(true);
    }
  }, [navigate]);

  return (
    <div>
      <div>
        {loggedIn && !temporarioExibido && <InviteCTA onExibido={marcarTemporarioExibido} />}
        <Sociaexpress onShowModal={onShowModal} />
        <DespensaHome />
        {products && products.length > 0 && <ProdutosRecomendados products={products} onShowModal={onShowModal} />}
       
        {isLoading && <OnboardingView />}
      </div>
    </div>
  );
}
