import React, { createContext, useState, useContext } from 'react';

const UserContext = createContext();

export const useUser = () => useContext(UserContext);

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState({ isLoggedIn: false, token: null });

  const login = (token) => {
    setUser({ isLoggedIn: true, token });
    // Salve o token em localStorage ou cookies, se necessário
  };

  const logout = () => {
    setUser({ isLoggedIn: false, token: null });
    // Limpe o token de localStorage ou cookies
  };

  return (
    <UserContext.Provider value={{ user, login, logout }}>
      {children}
    </UserContext.Provider>
  );
};
