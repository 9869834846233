import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import logo from "./Images/logotipo.png";
import { Link } from "react-router-dom";
import RegisterModal from "./Register";
import { IoReload } from "react-icons/io5";
import { BeatLoader } from "react-spinners";

export const LoginModal = () => {
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [loginError, setErrorMessage] = useState("");
  const [showRegisterModal, setShowRegisterModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await axios.post(
        "https://api.socia.ao/apiV2/Socia/login",
        {
          telefone: phone,
          pwd: password,
        },
        {
          headers: {
            ApiKey: "9MT3VnQYj2Mb7ILNqKy2HfF9AChqPeFwtDQ8QkGBZZ0=",
            Authorization: "2",
          },
        }
      );
      if (response.data.sucesso === true) {
        // console.log(response.data)
        localStorage.setItem("token", response.data.objecto.token.token);
        setPhone("");
        setPassword("");
        window.location.reload();
      } else {
        setErrorMessage("Login falhou, tente novamente.");
      }
    } catch (error) {
      setErrorMessage("Erro ao se conectar, tente novamente.");
    } finally {
      setLoading(false);
    }
  };

  const handleRegisterClick = () => {
    setShowRegisterModal(true);
  };

  const handleClickOutside = (event) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      setShowRegisterModal(false);
    }
  };

  const wrapperRef = useRef(null);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="p-5 bg-white rounded-lg" ref={wrapperRef}>
      {showRegisterModal ? (
        <RegisterModal />
      ) : (
        <>
          <div className="flex flex-col items-center justify-center mb-5">
            <img
              src={logo}
              alt="Sócia logo"
              width={85}
              height={85}
              className="object-cover select-none"
            />
          </div>
          {loginError && <p className="text-red-500 leading-7 mb-5 text-center">{loginError}</p>}

          <form onSubmit={handleLogin}>
            <div className="mb-4">
              <input
                id="phone"
                type="text"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none "
                placeholder="Digite seu telefone"
                required
              />
            </div>
            <div className="mb-4">
              <input
                id="password"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none"
                placeholder="Digite a sua senha"
                required
              />
            </div>
            <div className="flex flex-col items-center justify-center gap-3">
              <button
                type="submit"
                className={`w-full text-center px-10 py-2 bg-customPurple text-white font-bold rounded hover:bg-customPurple/90 ${loading ? 'hidden' : ''}`}
              >
                Entrar
              </button>
              <button
                type="button"
                className={`w-full text-center flex flex-col items-center cursor-not-allowed px-10 py-3 bg-customPurple/50 text-white font-bold rounded ${loading ? '' : 'hidden'}`}
              >
                <BeatLoader color={"#FFFFFF"} loading={loading} size={15} />
              </button>
              <Link to="/">
                <p className="text-gray-400">Esqueceu-se da palavra passe?</p>
              </Link>
              <div className="flex flex-row items-center justify-center w-full gap-2">
                <div className="bg-gray-400/55 h-[1px] w-full"></div>
                <span className="text-gray400/55">ou</span>
                <div className="bg-gray-400/55 h-[1px] w-full"></div>
              </div>
              <button
                type="button"
                onClick={handleRegisterClick}
                className="w-full px-10 py-2 bg-white text-customPurple font-bold rounded border border-customPurple/60"
              >
                Cadastrar
              </button>
            </div>
          </form>
        </>
      )}
    </div>
  );
};

export default LoginModal;
