// services/apiService.js
import axios from 'axios';
const BASE_URL = 'https://api.socia.ao/apiV2/Socia/'; // Substitua pela sua Base URL
const API_KEY = '9MT3VnQYj2Mb7ILNqKy2HfF9AChqPeFwtDQ8QkGBZZ0=';


// Instância do Axios com configuração da base URL e cabeçalho padrão
const api = axios.create({
  baseURL: BASE_URL,
  headers: {
    'Content-Type': 'application/json',
    'ApiKey': API_KEY,
  },
});

// Função de login
export const Dividir = async (IdItemCarrinho, IdEndereco, quantidade) => {
  try {
    const response = await api.post('DividirProduto', {
      IdItemCarrinho: IdItemCarrinho,
        IdEndereco: IdEndereco,
       quantidade: quantidade,
    });

    if (response.data && response.data.objecto ) {
     // localStorage.setItem('token', response.data.objecto); // Armazena o token no localStorage
      return response.data.objecto; // Retorna os dados do usuário
    } else {
      throw new Error('Credenciais inválidas');
    }
  } catch (error) {
    throw new Error(error.response?.data?.message || 'Erro ao tentar fazer login');
  }
};

export const CriarEndereco = async (IdMunicipio, Bairro, Rua) => {
  if (!IdMunicipio || !Bairro || !Rua) {
    throw new Error('Parâmetros obrigatórios estão ausentes.');
  }

  try {
    const token = localStorage.getItem('token');
    if (!token) {
      throw new Error('Token não encontrado. Faça login novamente.');
    }

    const response = await api.post(
      'CriarEndereco',
      { IdMunicipio, Bairro, Rua }, // Corpo da requisição
      {
        headers: {
          'ApiKey': '9MT3VnQYj2Mb7ILNqKy2HfF9AChqPeFwtDQ8QkGBZZ0=',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token,
        },
      }
    );

    if (response.status === 200) {
      return response.data; // Retorna os dados completos do endpoint
    } else {
      throw new Error('Não foi possível criar o endereço');
    }
  } catch (error) {
    if (error.response) {
      throw new Error(error.response.data?.message || 'Erro na criação do endereço');
    } else if (error.request) {
      throw new Error('Nenhuma resposta recebida do servidor. Verifique sua conexão.');
    } else {
      throw new Error('Erro ao configurar a requisição: ' + error.message);
    }
  }
};

export const RemoverEndereco = async (id) => {
  try {
    console.log(id)
    const token = localStorage.getItem('token');
    if (!token) {
      throw new Error('Token não encontrado. Faça login novamente.');
    }

    const response = await api.post('RemoverEndereco', 
     { id }, // Corpo da requisição
      {
        headers: {
          'ApiKey': '9MT3VnQYj2Mb7ILNqKy2HfF9AChqPeFwtDQ8QkGBZZ0=',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token,
        },
      }
    );

    if  (response.status === 200) {
     // localStorage.setItem('token', response.data.objecto); // Armazena o token no localStorage
      return response.data.objecto; // Retorna os dados do usuário
    } else {
      throw new Error('Credenciais inválidas');
    }
  } catch (error) {
    throw new Error(error.response?.data?.message || 'Não foi possivel remover o endereço');
  }
};
export const UpdateCarrinho = async (id , quantidade) => {
  try {
    console.log(id)
    const token = localStorage.getItem('token');
    if (!token) {
      throw new Error('Token não encontrado. Faça login novamente.');
    }

    const response = await api.post('UpdateCarrinho', 
     { id , quantidade }, // Corpo da requisição
      {
        headers: {
          'ApiKey': '9MT3VnQYj2Mb7ILNqKy2HfF9AChqPeFwtDQ8QkGBZZ0=',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token,
        },
      }
    );

    if  (response.status === 200) {
     // localStorage.setItem('token', response.data.objecto); // Armazena o token no localStorage
      return response.data.objecto; // Retorna os dados do usuário
    } else {
      throw new Error('Credenciais inválidas');
    }
  } catch (error) {
    throw new Error(error.response?.data?.message || 'Não foi possivel remover o endereço');
  }
};
export const RemoverDivisao = async (IdItemCarrinho) => {
  try {
    const response = await api.post('RemoverDivisao', {
      idDivisao: IdItemCarrinho,
       
    });

    if (response.data && response.data.objecto ) {
     // localStorage.setItem('token', response.data.objecto); // Armazena o token no localStorage
      return response.data.objecto; // Retorna os dados do usuário
    } else {
      throw new Error('Credenciais inválidas');
    }
  } catch (error) {
    throw new Error(error.response?.data?.message || 'Erro ao tentar fazer login');
  }
};

export const pagarConta = async (id) => {
  try {
    const response = await api.post('prext', {
      id: id,
     
    });

    if (response.data && response.data.objecto ) {
      //localStorage.setItem('token', response.data.objecto); // Armazena o token no localStorage
      return response.data.objecto; // Retorna os dados do usuário
    } else {
      throw new Error('Credenciais inválidas');
    }
  } catch (error) {
    throw new Error(error.response?.data?.message || 'Erro ao tentar fazer login');
  }
};

// Função para obter os clientes que estão fazendo compras
export const fetchClientes = async () => {
  try {
    const token = localStorage.getItem('token'); // Recupera o token do localStorage
    const response = await api.get('/VerComprasActivas', {
        headers: {
            
          'ApiKey': '9MT3VnQYj2Mb7ILNqKy2HfF9AChqPeFwtDQ8QkGBZZ0=', // Adiciona a ApiKey nos headers
          'Content-Type': 'application/json', // Especifica o tipo de conteúdo como JSON
        },
    });

    if (response.data && response.data.sucesso) {
      return response.data.objecto; // Retorna a lista de clientes
    } else {
      throw new Error('Erro ao buscar clientes');
    }
  } catch (error) {
    throw new Error(error.response?.data?.message || 'Erro ao buscar clientes');
  }
};

// Função para obter a lista de créditos concedidos
export const fetchEnderecos = async () => {
  try {
    const token = localStorage.getItem('token'); // Recupera o token do localStorage
    const response = await api.get('/MeusEnderecos', {
       headers: {
          'ApiKey': '9MT3VnQYj2Mb7ILNqKy2HfF9AChqPeFwtDQ8QkGBZZ0=', // Adiciona a ApiKey nos headers
          'Content-Type': 'application/json',
         'Authorization': 'Bearer ' + token  // Especifica o tipo de conteúdo como JSON
        },
    });

      if (response.data && response.data.sucesso) {
       
        return response.data.objecto; // Retorna a lista de créditos
        
    } else {
      throw new Error('Erro ao buscar créditos');
    }
  } catch (error) {
    throw new Error(error.response?.data?.message || 'Erro ao buscar créditos');
  }
};

// Função para obter a lista de créditos concedidos
export const fetchCobrancas = async () => {
  try {
    const token = localStorage.getItem('token'); // Recupera o token do localStorage
    const response = await api.get('/VerCreditosAtrasados', {
       headers: {
          'ApiKey': '9MT3VnQYj2Mb7ILNqKy2HfF9AChqPeFwtDQ8QkGBZZ0=', // Adiciona a ApiKey nos headers
          'Content-Type': 'application/json', // Especifica o tipo de conteúdo como JSON
        },
    });

    if (response.data && response.data.sucesso) {
      console.log(response.data.objecto)
      return response.data.objecto; // Retorna a lista de créditos
    } else {
      throw new Error('Erro ao buscar créditos');
    }
  } catch (error) {
    throw new Error(error.response?.data?.message || 'Erro ao buscar créditos');
  }
};

// Função para obter a lista de créditos concedidos
export const fetchAprovados = async () => {
  try {
    const token = localStorage.getItem('token'); // Recupera o token do localStorage
    const response = await api.get('/VerCreditosAProvados', {
       headers: {
          'ApiKey': '9MT3VnQYj2Mb7ILNqKy2HfF9AChqPeFwtDQ8QkGBZZ0=', // Adiciona a ApiKey nos headers
          'Content-Type': 'application/json', // Especifica o tipo de conteúdo como JSON
        },
    });

    if (response.data && response.data.sucesso) {
      return response.data.objecto; // Retorna a lista de créditos
    } else {
      throw new Error('Erro ao buscar créditos');
    }
  } catch (error) {
    throw new Error(error.response?.data?.message || 'Erro ao buscar créditos');
  }
};

export const fetchPagos = async () => {
  try {
    const token = localStorage.getItem('token'); // Recupera o token do localStorage
    const response = await api.get('/VerCreditosAProvados', {
       headers: {
          'ApiKey': '9MT3VnQYj2Mb7ILNqKy2HfF9AChqPeFwtDQ8QkGBZZ0=', // Adiciona a ApiKey nos headers
          'Content-Type': 'application/json', // Especifica o tipo de conteúdo como JSON
        },
    });

    if (response.data && response.data.sucesso) {
      return response.data.objecto; // Retorna a lista de créditos
    } else {
      throw new Error('Erro ao buscar créditos');
    }
  } catch (error) {
    throw new Error(error.response?.data?.message || 'Erro ao buscar créditos');
  }
};