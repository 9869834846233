function formatCurrency(value, locale = "pt-AO", currency = "AOA") {
  return new Intl.NumberFormat(locale, {
    style: "currency",
    currency: currency,
  }).format(value);
}

const ProductCard = ({ addCarrinho, produto }) => {
  function truncateText(text, maxLength) {
    if (text.length <= maxLength) {
      return text;
    }
    return text.slice(0, maxLength) + "...";
  }

  const originalPrice = produto.precoOriginal;
  const currentPrice = produto.preco / produto.quantidade;

  const calculateDiscountedPrice = (price, groupSize) => {
        const desc = groupSize / 100;
        return price * desc;
    if (!produto.descontoProgressivo) {
      return price;
    }
    const maxDiscount = 50; // Assume 50% as the maximum discount for the sake of example
    const minGroupSize = 2;
    const maxGroupSize = 4;

    if (groupSize < minGroupSize) {
      return price;
    }

    const applicableDiscount = Math.min(maxDiscount, (groupSize - minGroupSize + 1) * (maxDiscount / (maxGroupSize - minGroupSize + 1)));
    return price * (1 - applicableDiscount / 100);
  };

  const discountedPrice = currentPrice - calculateDiscountedPrice(currentPrice, produto.margem); // Assuming group size of 4 for maximum discount
  const discountPercentage = ((originalPrice - discountedPrice) / originalPrice) * 100;

  return (
    <div key={produto.id} className="bg-white shadow rounded flex flex-col h-full">
      <img src={produto.foto} alt={produto.produto.toUpperCase()} className="w-full h-36 object-contain" />
      <div className="p-4 flex flex-col items-center justify-center flex-1">
        <h2 className="text-lg font-semibold mb-2 text-center">{truncateText(produto.produto.toUpperCase(), 25)}</h2>
        <div className="flex items-center">
          <p className="text-gray-600 line-through mr-2">
            {formatCurrency(currentPrice)}
          </p>
          <p className="text-lg text-green-600">
            {formatCurrency(discountedPrice)}
          </p>
        </div>
        <p className="text-sm uppercase text-green-500 mb-2">
          até {produto.margem.toFixed(0)}% de Desconto
        </p>
        <p className="text-sm text-gray-500 mb-2">
          {/* {produto.avaliacoes} avaliações */}
        </p>
        <p className="text-sm uppercase text-blue-500 text-center mb-2">
          Desconto progressivo aplicável para grupos de 2 a 4 pessoas.
        </p>
        <div className="mt-auto w-full">
          <button className="bg-customPurple text-white px-4 py-2 rounded mt-4 w-full" onClick={() => addCarrinho(produto)}>
            Comprar
          </button>
        </div>
      </div>
    </div>
  );
};

export default ProductCard;
