import React, { useState, useEffect } from "react";
import ShareOnSocial from "react-share-on-social";
import { useLocation, useParams } from "react-router-dom";
import { ApiActions } from "../../actions/ApiActions";
import BeatLoader from "react-spinners/BeatLoader";
import { toast } from "react-toastify";
import AppStore from "../../stores/AppStore";
import CryptoJS from 'crypto-js';
import { FaShareAlt } from "react-icons/fa";
import ShareLink from '../HELPERS/ShareButton';
function formatCurrency(value, locale = "pt-AO", currency = "AOA") {
  return new Intl.NumberFormat(locale, {
    style: "currency",
    currency: currency,
  }).format(value);
}

const retornaRData = (dateString) => {
  const inputFormat = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2})$/;
  const match = dateString.match(inputFormat);

  if (match) {
    const year = match[1];
    const month = match[2] - 1;
    const day = match[3];
    const hours = match[4];
    const minutes = match[5];
    const seconds = match[6];

    const date = new Date(Date.UTC(year, month, day, hours, minutes, seconds));

    const formatter = new Intl.DateTimeFormat("pt-PT", {
      weekday: "short",
      day: "2-digit",
      month: "short",
      year: "numeric",
    });

    return formatter.format(date);
  } else {
    return dateString;
  }
};

const handleShareButtonClick = () => {
  const currentUrl = window.location.href;

  navigator.clipboard
    .writeText(currentUrl)
    .then(() => {
      console.log("URL copiada para a área de transferência:", currentUrl);
    })
    .catch((error) => {
      console.error("Erro ao copiar URL para a área de transferência:", error);
    });
};

const Sociadetalhe = () => {
  const location = useLocation();
  const params = useParams();
  const [socia, setSocia] = useState(null);
  const [discount, setDiscount] = useState(null);
  const [isAdding, setIsAdding] = useState(false);
  const [isLoading, setIsLoading] = useState(AppStore.getIsLoading());

  const notificarSucesso = () => {
    toast.success("Você se juntou com sucesso ao grupo de compras!");
  };

  const notificarErro = () => {
    toast.error("Não foi possível concluir a adesão ao grupo de compras!");
  };

  const handleAddToCart = () => {
    setIsAdding(true);
    const idProduto = socia.id;

    ApiActions.joinSocia(idProduto)
      .then(() => {
        setIsAdding(false);
        notificarSucesso();
      })
      .catch((error) => {
        console.error("Error adding to cart:", error);
        notificarErro();
      })
      .finally(() => {
        setIsAdding(false);
      });
  };

  useEffect(() => {
    if (location.state?.socia) {
      setSocia(location.state.socia);
    } else if (params.Id) {
      function handleChanges() {
        setSocia(AppStore.getDetalheSocia());
      }

      function handleLoading() {
        setIsLoading(AppStore.getIsLoading());
      }

      AppStore.on("carregando", handleLoading);
      AppStore.on("sociaDetalheSucesso", handleChanges);

      const key = CryptoJS.enc.Hex.parse('0879febbaf365b1535e175da463ca7bcd7fdc76956282fa33c2cf5a9c28cc1b5');
      const bytes = CryptoJS.AES.decrypt(params.Id, key, {
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7
      });
      const originalText = bytes.toString(CryptoJS.enc.Utf8);
      ApiActions.loadDetalhe(originalText);

      return () => {
        AppStore.removeListener("sociaDetalheSucesso", handleChanges);
        AppStore.removeListener("carregando", handleLoading);
      };
    }
  }, [location, params]);

  if (!socia) {
    return (
      <div className="flex items-center justify-center h-screen">
        <BeatLoader />
      </div>
    );
  }

  const formattedDate = retornaRData(socia.data);
  return (
    <div className="flex flex-col lg:flex-row h-screen">
      <div className="w-full lg:w-3/5 flex flex-col items-center justify-center p-4">
        <img
          src={socia.capa}
          alt={socia.nome}
          className="w-full lg:w-128 h-96 object-cover rounded-lg"
        />

        <h2 className="font-bold text-xl p-4 uppercase text-center">{socia.nome}</h2>
        <ShareLink uri={window.location.href} title={socia.nome} />

        <div className="text-xs mb-2">
          <p className="mb-1 p-10 text-center">
            Nas nossas promoções, a transparência e a facilidade reinam supremas. O desconto promocional é todo seu, desde que a quantidade adquirida não ultrapasse o limite máximo definido pela oferta. Isso significa que você tem controle total sobre suas economias, sem surpresas desagradáveis na hora de finalizar a compra.
          </p>
        </div>
      </div>

      <div className="flex-1 bg-neutral-50 flex flex-col items-start justify-start p-4">
        <div className="ps-10 pe-10 py-5">
          <div className="flex gap-2 justify-center items-center mb-4">
            <p className="text-lg font-semibold uppercase text-center">
              {socia.produtos[0].quantidade} {socia.produtos[0].forma} de {socia.produtos[0].produto} {socia.isin}
            </p>
          </div>

          <div className="flex gap-2 justify-center items-center mb-4 text-center">
            <p className="text-xs">POUPAS</p>
            <p className="text-xs"> {formatCurrency(socia.desconto)}</p>
          </div>
          <div className="flex gap-2 justify-center items-center mb-4">
            <p className="text-xs">VAIS PAGAR</p>
            <p className="text-xs"> {formatCurrency(socia.preco)}</p>
          </div>
          <p className="text-xs text-red-800 mb-2 text-center">
            {socia.vagas > 1 ? "FALTAM" : "SÓ FALTA"} {socia.vagas} {socia.vagas > 1 ? "PESSOAS" : "PESSOA"} PARA FECHAR A SÓCIA
          </p>
          <p className="text-xs lg:text-xs pb-2 uppercase font-semibold text-center">
            SÓ ATÉ {formattedDate}
          </p>

          {isAdding && (
            <div className="flex flex-col items-center justify-center">
              <BeatLoader />
            </div>
          )}

          <button
            onClick={handleAddToCart}
            disabled={isAdding}
            className="w-full bg-customPurple text-white rounded-md px-4 py-2"
          >
            ENTRAR NA SÓCIA
          </button>
        </div>

        <div className="h-6"></div>

        <div className="ps-5 pe-5 py-5">
          <h2 className="font-bold text-xl p-4">COMO FUNCIONA</h2>
          <div className="flex justify-start items-start mb-4">
            <ul>
              <li>
                <p className="text-xs mb-2 text-left">
                  <span className="text-black font-bold uppercase">Sem custos:</span> &nbsp;Se o grupo não atingir o número mínimo até a data de fechamento, ele é encerrado sem custos para os participantes.
                </p>
              </li>
              <li>
                <p className="text-xs mb-2 text-left">
                  <span className="text-black font-bold uppercase">Prazo para adesão:</span> &nbsp;Cada grupo tem uma data limite.
                </p>
              </li>
              <li>
                <p className="text-xs mb-2 text-left">
                  <span className="text-black font-bold uppercase">Acompanhe o progresso:</span> &nbsp;Acesse "SÓCIA" na app ou no site.
                </p>
              </li>
              <li>
                <p className="text-xs mb-2 text-left">
                  <span className="text-black font-bold uppercase">Entregas:</span> &nbsp;As entregas são feitas quando o número mínimo de participantes é atingido.
                </p>
              </li>
              <li>
                <p className="text-xs mb-2 text-left">
                  <span className="text-black font-bold uppercase">Pagamento:</span> &nbsp;Você paga quando receber a sua parte da compra.
                </p>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sociadetalhe;
