import React, { useEffect, useState } from "react";
import axios from "axios";
import Popup from "reactjs-popup";
import { useUser } from "../UserContext";
import SociaCard from "./SociaCard";
import MySociaCard from "./MySociaCard";
import { useNavigate } from "react-router-dom";
import CryptoJS from 'crypto-js';
import { toast } from "react-toastify";
import { ApiActions } from "../../actions/ApiActions";

const calculaDiferencaEmMinutos = (dataComoString) => {
  const dataFornecida = new Date(dataComoString);
  const agora = new Date();

  const diferencaEmMilissegundos = agora - dataFornecida;

  const diferencaEmMinutos = Math.floor(diferencaEmMilissegundos / 60000);
  console.error(diferencaEmMinutos);
  return diferencaEmMinutos;
};

export default function FetchSocias({ onShowModal }) {
  let navigate = useNavigate();
  const handleDetakheAddToCart = (socia) => {
    const token = localStorage.getItem("token");
    if (!token) {
      onShowModal(true);
    } else {
      const key = CryptoJS.enc.Hex.parse('0879febbaf365b1535e175da463ca7bcd7fdc76956282fa33c2cf5a9c28cc1b5'); // Ensure key is in correct format
      const ciphertext = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(socia.id), key, {
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7
      }).toString();
      const safeEncoded = ciphertext.replace(/\//g, '_');
      navigate(`/socia/${safeEncoded}`, { state: { socia } });
    }
  };

  const [Minhassocias, setSocias] = useState([]); // Initialize socias state
  const [data, setData] = useState([]);
  const [gdata, setGData] = useState([]); // Initialize gdata state

  const sairDoGrupo = (socia) => {
    
    if (window.confirm(`Tem certeza de que deseja cancelar este grupo de compra ${socia.id}?`)) {
      ApiActions.cancelarAdesaoSocia(socia.id)
        .then(() => {
          toast.success("Grupo de Compra Cancelado!");
          setSocias(prevSocias => prevSocias.filter(lsocia => socia.id !== lsocia.id));
        })
        .catch((error) => {
          toast.error("Não foi possível sair do grupo de compras");
        });
    }
  };

  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const { user } = useUser(); // Use the hook useUser to access the user state
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);

  function formatCurrency(value, locale = "pt-AO", currency = "AOA") {
    return new Intl.NumberFormat(locale, {
      style: "currency",
      currency: currency,
    }).format(value);
  }

  function PriceDisplay({ price }) {
    return <div>{formatCurrency(price)}</div>;
  }

  const handleAddToCart = () => {
    if (!user.isLoggedIn) {
      setIsLoginModalOpen(true);
    } else {
      // Add logic to add the item to the cart here
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://api.socia.ao/apiV2/Socia/ComprasEmGrupo",
          {
            headers: {
              ApiKey: "9MT3VnQYj2Mb7ILNqKy2HfF9AChqPeFwtDQ8QkGBZZ0=",
              Authorization: "2",
            },
          }
        );
        setData(response.data.objecto);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (localStorage.getItem("token")) {
      const fetchDataGroup = async () => {
        try {
          const token = localStorage.getItem("token");
          const response = await axios.get(
            "https://api.socia.ao/apiV2/Socia/MeusGruposCompra",
            {
              headers: {
                ApiKey: "9MT3VnQYj2Mb7ILNqKy2HfF9AChqPeFwtDQ8QkGBZZ0=",
                Authorization: `Bearer ${token}`,
              },
            }
          );
          setGData(response.data.objecto);
          setSocias(response.data.objecto); // Update socias state with fetched data
        } catch (error) {
          console.error(error);
        }
      };

      fetchDataGroup();
    }
  }, []);

  return (
    <div>
      {localStorage.getItem("token") && (
        <div>
         

         
          {Minhassocias.length > 0 ? (
            <div>
               <div className="flex flex-col items-start justify-center mt-10">
            <h1 className="scroll-m-20 pb-5 text-2xl font-semibold font-sans tracking-tight first:mt-0 text-customPurple">
              Minhas compras em grupo
            </h1>
          </div>
           <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 gap-8 py-8">
              {
                Minhassocias.map((produto, index) => (
                <MySociaCard
                  key={index}
                  socia={produto}
                  verdetalhe={handleDetakheAddToCart}
                  sairDoGrupo={sairDoGrupo}
                />
              ))
            }  
        </div>
       </div>

            ) : (
              <p className="font-sans"></p>
            )}
       
        </div>
      )}
      <div>
        <div className="flex flex-col items-start justify-center mt-10">
          <h1 className="font-sans scroll-m-20 pb-5 text-2xl font-semibold tracking-tight first:mt-0 text-customPurple">
           Grupos de compras disponíveis
          </h1>
        </div>

        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 gap-8 py-8">
          {data.map((produto, index) => (
            <SociaCard key={index} socia={produto} verdetalhe={handleDetakheAddToCart} />
          ))}
        </div>
      </div>
    </div>
  );
}
