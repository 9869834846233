import React, { useEffect, useState } from 'react';
import CryptoJS from 'crypto-js';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from "react-toastify";
import { ApiActions } from "../../actions/ApiActions";

const JoinGroup = ({ onShowModal }) => {
  const location = useLocation();
  const [message, setMessage] = useState('Validating...');
  const [isSubmitting, setIsSubmitting] = useState(false); // Add this state
  const navigate = useNavigate();

  // Use a secure method to store and retrieve this key
  const secretKey = '4d3f7a4b567c1b8a3e2d1f9c8a7b6d4f3c2b1e0d9c8a7b6f4e3d2c1b0a9f8e7';

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const date = query.get('acordos');
    const groupId = query.get('canjala');
    const hash = query.get('hash');

    const validateLink = () => {
      const currentDate = new Date();
      const data = `${groupId}${date}`;
      const generatedHash = CryptoJS.HmacSHA256(data, secretKey).toString();
      const token = localStorage.getItem("token");
 const base64 = date.replace(/_/g, '/');
    const key = CryptoJS.enc.Hex.parse('0879febbaf365b1535e175da463ca7bcd7fdc76956282fa33c2cf5a9c28cc1b5');
    const bytes = CryptoJS.AES.decrypt(base64, key, {
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7
    });

    const originalText = bytes.toString(CryptoJS.enc.Utf8);
      if (!token) {
        onShowModal(true);
      } else if (currentDate > originalText) {
        setMessage('O LINK DE CONVITE EXPIROU NESTA DATA');
      }  else {
        handleSubmit(); // Ensure this is called once
      }
    };

    validateLink();
  }, [location]);

  const notificarSucesso = () => {
    toast.success('Parabéns você foi adicionado com sucesso ao grupo de compras!');
  };

  const notificarErro = () => {
    toast.error('Lamento, não foi possível concluir a sua adesão ao grupo de compras');
  };

  const handleSubmit = () => {
    if (isSubmitting) return; // Prevent double submission
    setIsSubmitting(true); // Set submitting state

    const query = new URLSearchParams(location.search);
    const grupoId = query.get('canjala');
    const base64 = grupoId.replace(/_/g, '/');
    const key = CryptoJS.enc.Hex.parse('0879febbaf365b1535e175da463ca7bcd7fdc76956282fa33c2cf5a9c28cc1b5');
    const bytes = CryptoJS.AES.decrypt(base64, key, {
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7
    });

    const originalText = bytes.toString(CryptoJS.enc.Utf8);

    ApiActions.joinGrupo(originalText)
      .then(() => {
        notificarSucesso();
        navigate('/'); // Redirect to the main page
      })
      .catch((error) => {
        console.error('Error adding to group:', error);
        notificarErro();
      })
      .finally(() => {
        setIsSubmitting(false); // Reset submitting state
      });
  };

  return <div>{message}</div>;
};

export default JoinGroup;
