import ShareOnSocial from 'react-share-on-social';
import { FaShare , FaShareAlt } from "react-icons/fa";
export default function ShareLink  ({ uri, title, description }) {
    const shareConfig = {
      textToShare: title,
      link: uri,
      linkTitle: title,
      linkMetaDesc: description,
      shareTo: ['facebook', 'twitter', 'whatsapp'],
    };

    return (
      <ShareOnSocial {...shareConfig}>
        <button  className="flex items-center justify-center gap-2 bg-customPurple  text-white text-sm font-semibold py-2 px-4 rounded"
      aria-label="Share"> <FaShareAlt size={13} /> Partilhar</button>
      </ShareOnSocial>
    );
  };