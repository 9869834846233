import ProductCard from './CategoryProdCard';
import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom";

const CategorySection = ({ title, products, id }) => {
    const navigate = useNavigate();

    const handleCategorySelect = useCallback((catId) => {
        console.log('Category selected:', catId);
        navigate(`/produtoscategoria/`, { state: { catId } });
    }, [navigate]);

    return (
        <section className="my-8">
            <h2 className="text-xl font-semibold mb-4  text-customPurple">{title}</h2>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
                {products.map(product => (
                    <div
                        key={product.id}
                        className="cursor-pointer"
                        onClick={() => handleCategorySelect(id)}
                    >
                        <ProductCard product={product} />
                    </div>
                ))}
            </div>
        </section>
    );
};

export default CategorySection;
