// SearchInput.jsx
import React from 'react';

function SearchInput({ value, onChange }) {
  return (
    <input
      type="text"
      placeholder="Procurar Grupo"
      className="p-2 border border-gray-300 rounded"
      style={{ maxWidth: '33%' }}
      value={value}
      onChange={onChange}
    />
  );
}

export default SearchInput;
