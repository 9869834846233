import React from "react";
import { IoClose } from "react-icons/io5";

const Modal = ({ isOpen, onClose, children }) => {
  if (!isOpen) return null;

  return (
    <div className="h-screen fixed inset-0 bg-black bg-opacity-20 backdrop-blur-xl z-50 flex justify-center items-center">
      <div className="bg-white p-5 rounded-lg shadow-lg relative max-w-sm lg:max-w-[420px] w-full">
        <button
          onClick={onClose}
          className="absolute top-2 right-2 text-gray-700 font-semibold text-lg"
        >
          <IoClose size={20} />
        </button>
        {children}
      </div>
    </div>
  );
};

export default Modal;
