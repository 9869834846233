import React, { useState, useEffect } from 'react';
import { ApiActions } from "../../actions/ApiActions";
import { toast } from "react-toastify";
import BeatLoader from "react-spinners/BeatLoader"; // Importar o spinner

const CreateGroupService = ({ product, onSubmitGroupBuy, userGroups }) => {
  const [quantityPerParticipant, setQuantityPerParticipant] = useState(4);
  const [participants, setParticipants] = useState(4);
  const [discountPercent, setDiscountPercent] = useState(2);
  const [totalDiscount, setTotalDiscount] = useState(0);
  const [totalPriceAfterDiscount, setTotalPriceAfterDiscount] = useState(0);
  const [groupType, setGroupType] = useState('existing'); // 'anonymous' or 'existing'
  const [selectedGroupId, setSelectedGroupId] = useState(null);
  const [loading, setLoading] = useState(false);

  function formatCurrency(value, locale = "pt-AO", currency = "AOA") {
    return new Intl.NumberFormat(locale, {
      style: "currency",
      currency: currency,
    }).format(value);
  }

  const notificarErro = () => {
    toast.error("Não foi possível adicionar o produto.");
  };

  const notificarSucessoSocia = () => {
    toast.success("Parabéns! Sua sócia foi criada com sucesso!");
  };

  const safeQuantityPerParticipant = Math.min(quantityPerParticipant, 10);

  useEffect(() => {
    calculateDiscount();
  }, [quantityPerParticipant, participants, product]);

  useEffect(() => {
    if (groupType === 'existing' && !selectedGroupId && userGroups.length > 0) {
      setSelectedGroupId(userGroups[0].id);
    }
  }, [groupType, selectedGroupId, userGroups]);

  const calculateDiscount = () => {
    if (!product || participants <4) {
      alert("Um grupo deve ter pelo menos quatro participantes.");
      setParticipants(4);
      return;
    }
    if (participants > 10) {
      setParticipants(10);
      return;
    }
    if (quantityPerParticipant > product.maximo) {
      alert(`A quantidade por participante foi ajustada para ${product.maximo} para não exceder a capacidade maxima.`);
      setQuantityPerParticipant(product.maximo);
    }
    if (quantityPerParticipant < product.minimo) {
      alert(`A quantidade por participante foi ajustada para ${product.minimo} para não ficar abaixo do valor mínimo permitido.`);
      setQuantityPerParticipant(product.minimo);
    }

    const baseDiscount = 5; // 5% para o número mínimo de participantes
    const maxDiscountPercent = product.margem < 45 ? (product.margem < 1 ? 10 : product.margem) : 45; // Máximo de desconto permitido
    const minParticipants = 1;
    const maxParticipants = 10;

    // Desconto adicional por participante além do mínimo
    const additionalDiscountPerParticipant = (maxDiscountPercent - baseDiscount) / (maxParticipants - minParticipants);

    // Calcula o desconto total com base no número de participantes
    let calculatedDiscountPercent = baseDiscount + (participants - minParticipants) * additionalDiscountPerParticipant;
    calculatedDiscountPercent = Math.min(calculatedDiscountPercent, maxDiscountPercent);

    const totalQuantity = quantityPerParticipant * participants;
    const precoUnitario = (product.preco / product.quantidade);
    const totalPrice = quantityPerParticipant * precoUnitario;
    const profitMargin = 2000;

    let calculatedTotalDiscount = (totalPrice * calculatedDiscountPercent) / 100;
    calculatedTotalDiscount = Math.min(calculatedTotalDiscount, calculatedTotalDiscount); // O desconto não pode exceder a margem de lucro

    setDiscountPercent(calculatedDiscountPercent);
    setTotalDiscount(calculatedTotalDiscount);
    setTotalPriceAfterDiscount(totalPrice - calculatedTotalDiscount);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    const groupBuyDetails = {
      quantityPerParticipant,
      participants,
      discountPercent,
      totalDiscount,
      totalPriceAfterDiscount,
      groupType,
      selectedGroupId
    };

    const nomeSocia = 'SOCIA DE ' + product.produto.toUpperCase();
   
    const isPrvt = groupType === 'existing' ? true : false;

    const idGrupo = groupType === 'existing' ? selectedGroupId : 0;

    ApiActions.CreateSocia(product.id, quantityPerParticipant, nomeSocia, participants, discountPercent, isPrvt, idGrupo , "SERVICO")
      .then(() => {
        notificarSucessoSocia();
      })
      .catch((error) => {
        notificarErro();
      })
      .finally(() => {
        setLoading(false);
      });

    onSubmitGroupBuy(groupBuyDetails);
  };

  return (
    <div className="relative mx-auto">
      <h3 className="text-lg font-bold mb-4 text-start uppercase">Detalhes da Compra</h3>
      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          {/* <div className="flex justify-start">
            <button type="button" onClick={() => setGroupType('anonymous')} className={`hover:bg-blue-700 text-black font-bold py-2 px-4 ${groupType === 'anonymous' ? 'bg-gray-300 font-bold' : 'bg-gray-100'}`}>GRUPO PÚBLICO</button>
            <button type="button" onClick={() => setGroupType('existing')} className={`hover:bg-green-700 text-black font-bold py-2 px-4 ${groupType === 'existing' ? 'bg-gray-300' : 'bg-gray-100'}`}>GRUPO EXISTENTE</button>
          </div> */}
        </div>

        {groupType === 'existing' && userGroups.length > 0 ? (
          <div className="mb-4 py-4">
            <label htmlFor="groupSelect" className="block text-sm font-medium text-gray-700 uppercase">Selecionar Grupo:</label>
            <select
              id="groupSelect"
              value={selectedGroupId}
              onChange={(e) => setSelectedGroupId(e.target.value)}
              className="mt-1 p-2 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
            >
              {userGroups.map(group => (
                <option className="text-lg uppercase" key={group.id} value={group.id}>{group.grupo.toUpperCase()} - {group.descricao.toUpperCase()}</option>
              ))}
            </select>
          </div>
        ) : groupType === 'existing' && userGroups.length === 0 ? (
          <div className="mb-4 py-4">
            <p className="text-sm text-gray-500">Você ainda não tem nenhum grupo privado formado. Para criar um grupo, acesse o menu do usuário e escolha a opção "Criar Novo Grupo".</p>
          </div>
        ) : null}

        <div className="mb-4">
          <label htmlFor="quantityPerParticipant" className="block text-sm font-medium text-gray-700 uppercase">Quantidade por sócio (Participante):</label>
          <input
            type="number"
            id="quantityPerParticipant"
            value={quantityPerParticipant}
            onChange={(e) => setQuantityPerParticipant(Math.max(1, parseInt(e.target.value, 10)))}
            className="mt-1 p-2 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
            min="1"
          />
        </div>
        <div className="mb-4">
          <label htmlFor="participants" className="block text-sm font-medium text-gray-700 uppercase">Número de sócios (Participantes):</label>
          <input
            type="number"
            id="participants"
            value={participants}
            onChange={(e) => setParticipants(Math.max(1, parseInt(e.target.value, 10)))}
            className="mt-1 p-2 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
            min="2"
          />
        </div>
        <div className="mb-4">
          <p className="text-sm text-gray-500 uppercase">Desconto: { discountPercent.toFixed(2)}%</p>
          <p className="text-sm text-gray-500 uppercase">Valor do Desconto: {formatCurrency(totalDiscount)}</p>
          <p className="text-sm text-gray-500 uppercase">Preço  por membro: {formatCurrency(totalPriceAfterDiscount)}</p>
          <p className="text-sm text-gray-500 uppercase">Total do Grupo: {formatCurrency(totalPriceAfterDiscount * participants)}</p>
        </div>
        <div className="flex justify-center">
          <button type="submit" className="bg-customPurple hover:bg-customPurple text-white font-bold py-2 px-4 rounded" disabled={loading}>
            {loading ? <BeatLoader size={8} color={"#fff"} /> : 'COMEÇAR COMPRA EM GRUPO'}
          </button>
        </div>
      </form>
    </div>
  );
};

export default CreateGroupService;
