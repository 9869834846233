import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FiEdit3, FiTrash } from "react-icons/fi";
import { IoShareOutline } from "react-icons/io5";
import { jwtDecode } from "jwt-decode";
import CryptoJS from "crypto-js";
import { toast } from "react-toastify";
import { RemoverEndereco, CriarEndereco, fetchEnderecos } from "../../actions/apiServices";
import axios from "axios";
const decrypt = (encryptedText) => {
  try {
    const key = CryptoJS.enc.Hex.parse(
      "0879febbaf365b1535e175da463ca7bcd7fdc76956282fa33c2cf5a9c28cc1b5"
    );
    const bytes = CryptoJS.AES.decrypt(encryptedText, key, {
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7,
    });
    return bytes.toString(CryptoJS.enc.Utf8);
  } catch (error) {
    return ""; // Return an empty string if decryption fails
  }
};

export default function Profile() {
  const navigate = useNavigate();
  const [userData, setUserData] = useState(null);
  const [addresses, setAddresses] = useState([]);
  // Dropdown-related state
  const [countries, setCountries] = useState([]);
  const [provinces, setProvinces] = useState([]);
  const [municipalities, setMunicipalities] = useState([]);
  const [newAddress, setNewAddress] = useState({
    idPais: "",
    idProvincia: "",
    idMunicipio: "",
    bairro: "",
    rua: "",
  });
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/");
      return;
    }
    try {
      const decoded = jwtDecode(token);
      if (
        !decoded.PrimeiroNome ||
        !decoded.UltimoNome ||
        !decoded.mail ||
        !decoded.numeroCompras
      ) {
        localStorage.clear();
        navigate("/");
      } else {
        setUserData(decoded);
        // Simular carregamento de endereços existentes
       
      }
    } catch {
      localStorage.clear();
      navigate("/");
    }
  }, [navigate]);
   useEffect(() => {
    // Load countries from the API
    axios
      .get("https://api.socia.ao/apiV2/Socia/verLocalEntregas", {
        headers: {
          ApiKey: "9MT3VnQYj2Mb7ILNqKy2HfF9AChqPeFwtDQ8QkGBZZ0=",
          Authorization: "2",
        },
      })
      .then((response) => setCountries(response.data.objecto || []))
      .catch(() => toast.error("Erro ao carregar países."));
  }, []);


 
  useEffect(() => {
    if (newAddress.idPais) {
      const country = countries.find(
        (c) => c.id === parseInt(newAddress.idPais)
      );
      setProvinces(country?.provincias || []);
      setNewAddress((prev) => ({
        ...prev,
        idProvincia: "",
        idMunicipio: "",
      }));
      setMunicipalities([]);
    }
  }, [newAddress.idPais, countries]);

  useEffect(() => {
    if (newAddress.idProvincia) {
      const province = provinces.find(
        (p) => p.id === parseInt(newAddress.idProvincia)
      );
      setMunicipalities(province?.municipios || []);
      setNewAddress((prev) => ({
        ...prev,
        idMunicipio: "",
      }));
    }
  }, [newAddress.idProvincia, provinces]);

  const handleAddAddress = async () => {
    if (
      !newAddress.idPais ||
      !newAddress.idProvincia ||
      !newAddress.idMunicipio ||
      !newAddress.bairro ||
      !newAddress.rua
    
    ) {
      toast.error("Por favor, preencha todos os campos.");
      return;
    }

    const newEntry = {
      pais: countries.find((c) => c.id === parseInt(newAddress.idPais))?.pais,
      provincia: provinces.find(
        (p) => p.id === parseInt(newAddress.idProvincia)
      )?.provincia,
      municipio: municipalities.find(
        (m) => m.id === parseInt(newAddress.idMunicipio)
      )?.municipio,
      bairro: newAddress.bairro,
      rua: newAddress.rua,
         
    };
 await CriarEndereco(newAddress.idMunicipio, newAddress.bairro , newAddress.rua);
    setAddresses([...addresses, newEntry]);
    setNewAddress({
      idPais: "",
      idProvincia: "",
      idMunicipio: "",
      bairro: "",
      rua: "",
    });
    setShowModal(false);
    toast.success("Endereço adicionado com sucesso!");
  };

  const handleRemoveAddress = (indexToRemove , id) => {
    const updatedAddresses = addresses.filter((_, index) => index !== indexToRemove);
    RemoverEndereco(id);
    setAddresses(updatedAddresses);
    toast.success("Endereço removido com sucesso!");
  };
    useEffect(() => {
  {
      const loadAddresses = async () => {
       // setIsLoadingAddresses(true);
        try {
          const data = await fetchEnderecos(); // Busca os endereços disponíveis
          setAddresses(data);
        } catch (error) {
          console.error("Erro ao carregar endereços:", error);
         // setAvailableAddresses([]);
        } finally {
         // setIsLoadingAddresses(false);
        }
      };
      loadAddresses();
    }
  });

  return (
    <div className="mt-28 mb-10 p-10 rounded-lg bg-white">
      {/* User Info Section */}
      <div className="flex flex-col sm:flex-row items-start justify-between gap-4">
        <div className="relative group flex flex-row items-center gap-4">
          <img
            src="https://i.pinimg.com/564x/d9/a9/f8/d9a9f8d44e3edb28bce3448c0d3b0e20.jpg"
            alt="avatar"
            className="h-20 object-cover p-1 border border-customPurple rounded-full"
          />
          <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity cursor-pointer">
            <FiEdit3 size={24} className="text-white" />
          </div>
          <div className="flex flex-col">
            <h2 className="font-bold text-lg text-customPurple">
              {decrypt(userData?.PrimeiroNome || "")}{" "}
              {decrypt(userData?.UltimoNome || "")}
            </h2>
            <span className="lowercase opacity-50">
              {decrypt(userData?.mail || "")}
            </span>
          </div>
        </div>
      </div>

      {/* User Statistics */}
      <div className="grid gap-4 md:grid-cols-2 lg:grid-cols-5 w-full mt-10">
        {[
          { label: "Compras", value: decrypt(userData?.numeroCompras || "0") },
          { label: "Sócias criadas", value: "33" },
          { label: "Sócias participadas", value: "6" },
          { label: "Valores", value: "0 kzs" },
          { label: "Convidados", value: "9" },
        ].map((item, index) => (
          <div
            key={index}
            className="rounded-xl border bg-card text-card-foreground shadow p-4"
          >
            <div className="flex items-center justify-between">
              <h3 className="text-sm font-medium">{item.label}</h3>
              <IoShareOutline className="w-4 h-4 text-gray-500" />
            </div>
            <div className="text-base font-bold text-customPurple pt-2">
              {item.value}
            </div>
          </div>
        ))}
      </div>

      {/* Personal Data and Addresses Section */}
      <div className="flex flex-row gap-6 mt-8">
        {/* Personal Data */}
        <div className="bg-gray-100/80 rounded-md shadow-sm  w-full p-5">
          <div className="flex justify-between mb-6">
            <h1 className="font-bold text-lg text-customPurple">
              Dados pessoais:
            </h1>
            <button>
              <FiEdit3 size={20} className="text-customPurple" />
            </button>
          </div>
          <div className="flex flex-col gap-4">
            {[
              { label: "Primeiro Nome", value: decrypt(userData?.PrimeiroNome || "") },
              { label: "Último Nome", value: decrypt(userData?.UltimoNome || "") },
              { label: "Email", value: decrypt(userData?.mail || "") },
              { label: "Telefone", value: decrypt(userData?.telefone || "") },
            ].map((item, index) => (
              <div key={index} className="flex justify-between items-center">
                <span className="text-sm font-semibold text-customPurple">
                  {item.label}:
                </span>
                <input
                  type="text"
                  value={item.value}
                  readOnly
                  className="bg-transparent border-b border-gray-300 w-2/3 px-2 text-sm focus:outline-none"
                />
              </div>
            ))}
          </div>
        </div>

        {/* Addresses */}
        <div className="w-1/2">
          <div className="flex justify-between mb-4">
            <h2 className="font-bold text-lg text-customPurple">Outros Endereços de Entrega:</h2>
            <button
              className="text-sm bg-customPurple text-white px-4 py-2 rounded"
              onClick={() => setShowModal(true)}
            >
              Adicionar
            </button>
          </div>
          <div className="space-y-4">
            {addresses.map((address, index) => (
              <div
                key={index}
                className="p-4 border rounded-md bg-gray-50 shadow-sm flex justify-between items-center"
              >
                <div>
                  <p>
                    <strong>Município:</strong> {address.municipio}
                  </p>
                  <p>
                    <strong>Bairro:</strong> {address.bairro}
                  </p>
                  <p>
                    <strong>Rua:</strong> {address.rua}
                  </p>
                </div>
                <button
                  onClick={() => handleRemoveAddress(index , address.id)}
                  className="text-red-600 hover:text-red-800"
                >
                  <FiTrash size={20} />
                </button>
              </div>
            ))}
          </div>
        </div>
      </div>
     
      {/* Add Address Modal */}
      {showModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-white p-6 rounded shadow-lg w-96">
            <h3 className="text-lg font-bold mb-4 text-customPurple">
              Adicionar Novo Endereço
            </h3>
            <form className="space-y-4">
              <select
                value={newAddress.idPais}
                onChange={(e) =>
                  setNewAddress((prev) => ({ ...prev, idPais: e.target.value }))
                }
                className="w-full p-2 border rounded"
              >
                <option value="">Selecione o país</option>
                {countries.map((country) => (
                  <option key={country.id} value={country.id}>
                    {country.pais}
                  </option>
                ))}
              </select>
              <select
                value={newAddress.idProvincia}
                onChange={(e) =>
                  setNewAddress((prev) => ({
                    ...prev,
                    idProvincia: e.target.value,
                  }))
                }
                className="w-full p-2 border rounded"
              >
                <option value="">Selecione a província</option>
                {provinces.map((province) => (
                  <option key={province.id} value={province.id}>
                    {province.provincia}
                  </option>
                ))}
              </select>
              <select
                value={newAddress.idMunicipio}
                onChange={(e) =>
                  setNewAddress((prev) => ({
                    ...prev,
                    idMunicipio: e.target.value,
                  }))
                }
                className="w-full p-2 border rounded"
              >
                <option value="">Selecione o município</option>
                {municipalities.map((municipality) => (
                  <option key={municipality.id} value={municipality.id}>
                    {municipality.municipio}
                  </option>
                ))}
              </select>
              <input
                type="text"
                placeholder="Bairro"
                value={newAddress.bairro}
                onChange={(e) =>
                  setNewAddress((prev) => ({ ...prev, bairro: e.target.value }))
                }
                className="w-full p-2 border rounded"
              />
              <input
                type="text"
                placeholder="Rua"
                value={newAddress.rua}
                onChange={(e) =>
                  setNewAddress((prev) => ({ ...prev, rua: e.target.value }))
                }
                className="w-full p-2 border rounded"
              />
              <div className="flex justify-end gap-2">
                <button
                  type="button"
                  className="bg-gray-500 text-white px-4 py-2 rounded"
                  onClick={() => setShowModal(false)}
                >
                  Cancelar
                </button>
                <button
                  type="button"
                  className="bg-customPurple text-white px-4 py-2 rounded"
                  onClick={handleAddAddress}
                >
                  Adicionar
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
}
