// CreateGroupForm.jsx
import React, { useState } from 'react';
import { toast } from "react-toastify";
import { ApiActions } from "../../actions/ApiActions";

function CreateGroupForm({ onBack, onGroupCreated }) {
  const [isAdding, setIsAdding] = useState(false);
  const [groupDetails, setGroupDetails] = useState({
    name: '',
    description: '',
    type: 'private'
  });

  const notificarSucesso = () => {
    toast.success("Parabéns grupo criado com sucesso!");
    onGroupCreated();
  };

  const notificarErro = () => {
    toast.error("Não foi possivel criar o grupo");
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setGroupDetails(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!groupDetails.name || !groupDetails.description) {
      alert('Please fill in all fields.');
      return;
    }

    setIsAdding(true);

    try {
      await ApiActions.CriarGrupo(groupDetails.name, groupDetails.description, groupDetails.type);
      notificarSucesso();
    } catch (error) {
      console.error("Error creating group:", error);
      notificarErro();
    } finally {
      setIsAdding(false);
      onBack(); // Call onBack after successful submission or error
    }
  };

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-xl font-bold mb-4">Criar Novo Grupo</h1>
      <form onSubmit={handleSubmit} className="space-y-4">
        <div>
          <label htmlFor="name" className="block text-sm font-medium text-gray-700">Nome do Grupo</label>
          <input
            type="text"
            name="name"
            id="name"
            value={groupDetails.name}
            onChange={handleChange}
            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
          />
        </div>
        <div>
          <label htmlFor="description" className="block text-sm font-medium text-gray-700">Descrição do Grupo</label>
          <textarea
            name="description"
            id="description"
            value={groupDetails.description}
            onChange={handleChange}
            rows="4"
            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
          ></textarea>
        </div>
        <div>
          <span className="block text-sm font-medium text-gray-700">Tipo de Grupo</span>
          <div className="mt-2">
            <label className="inline-flex items-center">
              <input
                type="radio"
                name="type"
                value="private"
                checked={groupDetails.type === 'private'}
                onChange={handleChange}
                className="form-radio"
              />
              <span className="ml-2">Privado</span>
            </label>
            <label className="inline-flex items-center ml-6">
              <input
                type="radio"
                name="type"
                value="public"
                checked={groupDetails.type === 'public'}
                onChange={handleChange}
                className="form-radio"
              />
              <span className="ml-2">Público</span>
            </label>
          </div>
        </div>
        <div className="flex justify-between">
          <button type="button" onClick={onBack} className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded">
            Voltar
          </button>
          <button type="submit" className="bg-customPurple hover:bg-customPurple text-white font-bold py-2 px-4 rounded" disabled={isAdding}>
            {isAdding ? 'Criando...' : 'Criar'}
          </button>
        </div>
      </form>
    </div>
  );
}

export default CreateGroupForm;
