import AppDispatcher from "../dispatcher/AppDispatcher";
import axios from "axios";
// src/actions/ApiActions.js
const ApiActionTypes = {
    LOAD_PRODUCTS: 'LOAD_PRODUCTS',
    ADD_TO_CART: 'ADD_TO_CART',

    REMOVE_FROM_CART: 'REMOVE_FROM_CART',
    LOAD_CART: 'LOAD_CART',
    ADD_TO_CART_ERROR: 'ADD_TO_CART_ERROR',
   
    SEARCH_PRODUCTS_BY_NAME: 'SEARCH_PRODUCTS_BY_NAME',
    SEARCH_PRODUCTS_BY_CATEGORY: 'SEARCH_PRODUCTS_BY_CATEGORY',
    START_SEARCHING: 'START_SEARCHING',
    END_SEARCHING: 'END_SEARCHING',

    LOGIN_START: 'LOGIN_START',
    LOGIN_FINISH: 'LOGIN_FINISH',
    LOGIN_ERROR: 'LOGIN_ERROR',

    LOAD_GRUPO_START: 'LOAD_GRUPO_START',
    LOAD_GRUPO_SUCCESS: 'LOAD_GRUPO_SUCCESS',
    LOAD_GRUPO_ERROR: 'LOAD_GRUPO_ERROR',

    EXIT_GRUPO_START: 'EXIT_GRUPO_START',
    EXIT_GRUPO_SUCCESS: 'EXIT_GRUPO_SUCCESS',
    EXIT_GRUPO_ERROR: 'EXIT_GRUPO_ERROR',

    CREATE_GRUPO_START: 'CREATE_GRUPO_START',
    CREATE_GRUPO_SUCESS: 'CREATE_GRUPO_SUCESS',
    CREATE_GRUPO_ERROR: 'CREATE_GRUPO_ERROR',

    ADERIR_COMPRA_GRUPO_START: 'ADERIR_COMPRA_GRUPO_START',
    ADERIR_COMPRA_GRUPO_SUCESS:'ADERIR_COMPRA_GRUPO_SUCESS',
    ADERIR_COMPRA_GRUPO_ERROR: 'ADERIR_COMPRA_GRUPO_ERROR',



    ACCOUNT_CREATION_START: 'ACCOUNT_CREATION_START',
    ACCOUNT_CREATION_FINISH: 'ACCOUNT_CREATION_FINISH',
    ACCOUNT_CREATION_ERROR: 'ACCOUNT_CREATION_ERROR',

    LOAD_DATA_START: 'LOAD_DATA_START',
    LOAD_DATA_SUCCESS: 'LOAD_DATA_SUCCESS',
    LOAD_DATA_ERROR: 'LOAD_DATA_ERROR',

    LOAD_DATA_ENDERECO: 'LOAD_DATA_ENDERECO',
    LOAD_DATA_ENDRECO_SUCCESS: 'LOAD_DATA_ENDRECO_SUCCESS',
    LOAD_DATA_ENDERECO_ERROR: 'LOAD_DATA_ENDERECO_ERROR',

    LOAD_DETALHE_START: 'LOAD_DETALHE_START',
    LOAD_DETALHE_SUCCESS: 'LOAD_DETALHE_SUCCESS',
    LOAD_DETALHE_ERROR: 'LOAD_DETALHE_ERROR',

    LOAD_DETALHE_PRODUTO_START: 'LOAD_DETALHE_PRODUTO_START',
    LOAD_DETALHE_PRODUTO_SUCCESS: 'LOAD_DETALHE_PRODUTO_SUCCESS',
    LOAD_DETALHE_PRODUTO_ERROR: 'LOAD_DETALHE_PRODUTO_ERROR',

     LOAD_DETALHE_PROMO_START: 'LOAD_DETALHE_PROMO_START',
    LOAD_DETALHE_PROMO_SUCCESS: 'LOAD_DETALHE_PROMO_SUCCESS',
    LOAD_DETALHE_PROMO_ERROR: 'LOAD_DETALHE_PROMO_ERROR',

    GENERIC_LOAD_DATA_START: 'GENERIC_LOAD_DATA_START',
    GENERIC_LOAD_DATA_SUCCESS: 'GENERIC_LOAD_DATA_SUCCESS',
    GENERIC_LOAD_DATA_ERROR: 'GENERIC_LOAD_DATA_ERROR',

    CATEGORIA_LOAD_DATA_START: 'CATEGORIA_LOAD_DATA_START',
    CATEGORIA_LOAD_DATA_SUCCESS: 'CATEGORIA_LOAD_DATA_SUCCESS',
    CATEGORIA_PRODUTO_LOAD_DATA_SUCCESS: 'CATEGORIA_PRODUTO_LOAD_DATA_SUCCESS',
    CATEGORIA_LOAD_DATA_ERROR: 'CATEGORIA_LOAD_DATA_ERROR',

    SOCIA_LOAD_DATA_START: 'SOCIA_LOAD_DATA_START',
    SOCIA_LOAD_DATA_SUCCESS: 'SOCIA_LOAD_DATA_SUCCESS',
    SOCIA_LOAD_DATA_ERROR: 'SOCIA_LOAD_DATA_ERROR',

    PROMO_LOAD_DATA_START: 'PROMO_LOAD_DATA_START',
    PROMO_LOAD_DATA_SUCCESS: 'PROMO_LOAD_DATA_SUCCESS',
    PROMO_LOAD_DATA_ERROR: 'PROMO_LOAD_DATA_ERROR',

    CARRINHO_LOAD_DATA_START: 'CARRINHO_LOAD_DATA_START',
    CARRINHO_LOAD_DATA_SUCCESS: 'CARRINHO_LOAD_DATA_SUCCESS',
    CARRINHO_LOAD_DATA_ERROR: 'CARRINHO_LOAD_DATA_ERROR',

    BAIPAGA_LOAD_DATA_START: 'BAIPAGA_LOAD_DATA_START',
    BAIPAGA_LOAD_DATA_SUCCESS: 'BAIPAGA_LOAD_DATA_SUCCESS',
    BAIPAGA_LOAD_DATA_ERROR: 'BAIPAGA_LOAD_DATA_ERROR',

    UNITEL_LOAD_DATA_START: 'UNITEL_LOAD_DATA_START',
    UNITEL_LOAD_DATA_SUCCESS: 'UNITEL_LOAD_DATA_SUCCESS',
    UNITEL_LOAD_DATA_ERROR: 'UNITEL_LOAD_DATA_ERROR',

    LOCALIDADE_LOAD_DATA_START: 'LOCALIDADE_LOAD_DATA_START',
    LOCALIDADE_LOAD_DATA_SUCCESS: 'LOCALIDADE_LOAD_DATA_SUCCESS',
    LOCALIDADE_LOAD_DATA_ERROR: 'LOCALIDADE_LOAD_DATA_ERROR',

    VOUCHER_ADD_START: 'VOUCHER_ADD_START',
    VOUCHER_ADD_SUCCESS: 'VOUCHER_ADD_SUCCESS',
    VOUCHER_ADD_ERROR: 'VOUCHER_ADD_ERROR',

    RECOMENDADOS_START: 'RECOMENDADOS_START',
    RECOMENDADOS_SUCCESS: 'RECOMENDADOS_SUCCESS',
    RECOMENDADOS_ERROR: 'RECOMENDADOS_ERROR',

    SERVICO_START: 'SERVICO_START',
    SERVICO_SUCCESS: 'SERVICO_SUCCESS',
    SERVICO_ERROR: 'SERVICO_ERROR',

    START_SAVING: 'START_SAVING',
    END_SAVING: 'END_SAVING',

    START_LOADING: 'START_LOADING',
    END_LOADING: 'END_LOADING',

    START_LOADING_BD: 'START_LOADING_BD',
    SUCESS_LOADING_BD: 'SUCESS_LOADING_BD',
    ERROR_LOADING_BD: 'ERROR_LOADING_BD',
    END_LOADING_BD: 'END_LOADING_BD',

     START_LOADING_SUBSCRICAO: 'START_LOADING_SUBSCRICAO',
    SUCESS_LOADING_SUBSCRICAO: 'SUCESS_LOADING_SUBSCRICAO',
    ERROR_LOADING_SUBSCRICAO: 'ERROR_LOADING_SUBSCRICAO',
    END_LOADING_SUBSCRICAO: 'END_LOADING_SUBSCRICAO',

    START_LOADING_TRANSPORTADORAS: 'START_LOADING_TRANSPORTADORAS',
    SUCESS_LOADING_TRANSPORTADORAS: 'SUCESS_LOADING_TRANSPORTADORAS',
    END_LOADING_TRANSPORTADORAS: 'END_LOADING_TRANSPORTADORAS',


    FACTURA_LOADED_SUCCESS: 'FACTURA_LOADED_SUCCESS',
    RE_BUY_SUCCESS: 'RE_BUY_SUCCESS',
};
const api = axios.create({
    baseURL: 'https://api.socia.ao/apiV2/Socia/',
});
const token = localStorage.getItem('token');
const apiKey = "9MT3VnQYj2Mb7ILNqKy2HfF9AChqPeFwtDQ8QkGBZZ0=";

const headers = {
    'ApiKey': apiKey,
    'Authorization': 'Bearer ' + token
};
const ApiActions = {
        loadTransportadoras: () => {
        AppDispatcher.dispatch({
            type: ApiActionTypes.START_LOADING_TRANSPORTADORAS,

        });
        api.get('ListarTransportadoraUser', {
                headers
            })
            .then(response => {
                AppDispatcher.dispatch({
                    type: ApiActionTypes.END_LOADING_TRANSPORTADORAS,

                });
                AppDispatcher.dispatch({
                    type: ApiActionTypes.SUCESS_LOADING_TRANSPORTADORAS,
                    products: response.data.objecto,
                });
            })
            .catch(error => {
                console.error('teste 33')
                console.error("Erro ao carregar Produtos " + error)
                AppDispatcher.dispatch({
                    type: ApiActionTypes.END_LOADING,

                });
            });
        // Implementar chamada à API para carregar produtos
    },

      loadEnderecos: () => {
        AppDispatcher.dispatch({
            type: ApiActionTypes.START_LOADING_TRANSPORTADORAS,

        });
        api.get('MeusEnderecos', {
                headers
            })
            .then(response => {
                AppDispatcher.dispatch({
                    type: ApiActionTypes.END_LOADING_TRANSPORTADORAS,

                });
                AppDispatcher.dispatch({
                    type: ApiActionTypes.SUCESS_LOADING_TRANSPORTADORAS,
                    products: response.data.objecto,
                });
            })
            .catch(error => {
                console.error('teste 33')
                console.error("Erro ao carregar Produtos " + error)
                AppDispatcher.dispatch({
                    type: ApiActionTypes.END_LOADING,

                });
            });
        // Implementar chamada à API para carregar produtos
    },
        loadBD: () => {
        AppDispatcher.dispatch({
            type: ApiActionTypes.START_LOADING_BD,

        });
        api.get('CarregarBD', {
                headers
            })
            .then(response => {
                AppDispatcher.dispatch({
                    type: ApiActionTypes.END_LOADING_BD,

                });
                 console.error('teste 44')
                AppDispatcher.dispatch({
                    type: ApiActionTypes.SUCESS_LOADING_BD,
                    products: response.data.objecto,
                });
            })
            .catch(error => {
                console.error('teste 33')
                console.error("Erro ao carregar Produtos " + error)
                AppDispatcher.dispatch({
                    type: ApiActionTypes.END_LOADING,

                });
            });
        // Implementar chamada à API para carregar produtos
    },
    loadRecomendados: () => {
         const currentDate = new Date();
     
        AppDispatcher.dispatch({
            type: ApiActionTypes.RECOMENDADOS_START,

        });
        api.get('CategoriaComProdutos', { headers: { 'ApiKey': apiKey, 'Authorization': 'Bearer ' + token } })
            .then(response => {
                
                AppDispatcher.dispatch({
                    type: ApiActionTypes.RECOMENDADOS_SUCCESS,
                    products: response.data.objecto,
                });
                   const currentDate2 = new Date();
                
            })
            .catch(error => {
                console.error('erro Categoria 33')
                console.error("Erro ao carregar Categorias " + error)
                AppDispatcher.dispatch({
                    type: ApiActionTypes.RECOMENDADOS_ERROR,

                });
            });
        // Implementar chamada à API para carregar produtos
    },
    loadProducts: () => {
        AppDispatcher.dispatch({
            type: ApiActionTypes.START_LOADING,

        });
        api.get('TodosServicos', {
                headers
            })
            .then(response => {
                AppDispatcher.dispatch({
                    type: ApiActionTypes.SERVICO_START,

                });
                AppDispatcher.dispatch({
                    type: ApiActionTypes.SERVICO_SUCCESS,
                    products: response.data.objecto,
                });
            })
            .catch(error => {
                console.error('teste 33')
                console.error("Erro ao carregar Produtos " + error)
                AppDispatcher.dispatch({
                    type: ApiActionTypes.SERVICO_ERROR,

                });
            });
        // Implementar chamada à API para carregar produtos
    },
    loadRelatedProducts: (id) => {

        AppDispatcher.dispatch({
            type: ApiActionTypes.START_LOADING,

        });
        const params = {
            idProduto: id,

        };
        api.get('ProdutosRelacionados', {
                headers,
                params
            })
            .then(response => {
                AppDispatcher.dispatch({
                    type: ApiActionTypes.END_LOADING,

                });
              
                AppDispatcher.dispatch({
                    type: ApiActionTypes.LOAD_PRODUCTS,
                    products: response.data.objecto,
                });
            })
            .catch(error => {
               
                AppDispatcher.dispatch({
                    type: ApiActionTypes.END_LOADING,

                });
            });
        // Implementar chamada à API para carregar produtos
    },
    loadPromos: () => {
        AppDispatcher.dispatch({
            type: ApiActionTypes.START_LOADING,

        });
        api.get('VerPromocao', {
                headers: {
                    'ApiKey': apiKey,
                    'Authorization': 'Bearer ' + token
                }
            })
            .then(response => {
                AppDispatcher.dispatch({
                    type: ApiActionTypes.END_LOADING,

                });
                AppDispatcher.dispatch({
                    type: ApiActionTypes.PROMO_LOAD_DATA_SUCCESS,
                    products: response.data.objecto,
                });
                // console.error('Promo Load Sucesso Actions')

            })
            .catch(error => {
                console.error('Promo Load ERRO Actions')
                console.error("Erro ao carregar Produtos " + error)
                AppDispatcher.dispatch({
                    type: ApiActionTypes.END_LOADING,

                });
            });
        // Implementar chamada à API para carregar produtos
    },
    loadCategorias: () => {
        AppDispatcher.dispatch({
            type: ApiActionTypes.START_LOADING,

        });
        api.get('Categorias', {
                headers: {
                    'ApiKey': apiKey,
                    'Authorization': 'Bearer ' + token
                }
            })
            .then(response => {
                AppDispatcher.dispatch({
                    type: ApiActionTypes.END_LOADING,

                });
                AppDispatcher.dispatch({
                    type: ApiActionTypes.CATEGORIA_LOAD_DATA_SUCCESS,
                    products: response.data.objecto,
                });
            })
            .catch(error => {
                console.error('teste 33')
                console.error("Erro ao carregar Produtos " + error)
                AppDispatcher.dispatch({
                    type: ApiActionTypes.END_LOADING,

                });
            });
        // Implementar chamada à API para carregar produtos
    },
    loadProdutosCategoria: (idcategoria) => {
        const params = {
            id: idcategoria,

        };
        AppDispatcher.dispatch({
            type: ApiActionTypes.START_LOADING,

        });
        api.get('ProdutosCategoria', {
                headers,
                params
            })
            .then(response => {
               
                AppDispatcher.dispatch({
                    type: ApiActionTypes.END_LOADING,

                });

                AppDispatcher.dispatch({
                    type: ApiActionTypes.CATEGORIA_PRODUTO_LOAD_DATA_SUCCESS,
                    products: response.data.objecto,
                });
            })
            .catch(error => {
                console.error('teste erro produtoCategoria')

                AppDispatcher.dispatch({
                    type: ApiActionTypes.END_LOADING,

                });
            });
        // Implementar chamada à API para carregar produtos
    },
    loadSociasDisponiveis: () => {
        AppDispatcher.dispatch({
            type: ApiActionTypes.START_LOADING,

        });
        api.get('ComprasEmGrupo', {
                headers: {
                    'ApiKey': apiKey,
                    'Authorization': 'Bearer ' + token
                }
            })
            .then(response => {
                AppDispatcher.dispatch({
                    type: ApiActionTypes.END_LOADING,

                });
                AppDispatcher.dispatch({
                    type: ApiActionTypes.SOCIA_LOAD_DATA_SUCCESS,
                    products: response.data.objecto,
                });
            })
            .catch(error => {
                console.error('teste 33')
                console.error("Erro ao carregar Produtos " + error)
                AppDispatcher.dispatch({
                    type: ApiActionTypes.END_LOADING,

                });
            });
        // Implementar chamada à API para carregar produtos
    },
    loadSociasSociaExpres: () => {
        AppDispatcher.dispatch({
            type: ApiActionTypes.START_LOADING,

        });
        api.get('ComprasEmGrupoExpress', {
                headers: {
                    'ApiKey': apiKey,
                    'Authorization': 'Bearer ' + token
                }
            })
            .then(response => {
                AppDispatcher.dispatch({
                    type: ApiActionTypes.END_LOADING,

                });
                AppDispatcher.dispatch({
                    type: ApiActionTypes.SOCIA_LOAD_DATA_SUCCESS,
                    products: response.data.objecto,
                });
            })
            .catch(error => {
                console.error('teste 33')
                console.error("Erro ao carregar Produtos " + error)
                AppDispatcher.dispatch({
                    type: ApiActionTypes.END_LOADING,

                });
            });
        // Implementar chamada à API para carregar produtos
    },
    loadMeusGrupos: () => {
        AppDispatcher.dispatch({
        type: ApiActionTypes.LOAD_GRUPO_START,
    });

    api.get('MeuGrupo', { headers: { 'ApiKey': apiKey, 'Authorization': 'Bearer ' + token } })
        .then(response => {
         // Adicione este log para verificar os dados
            AppDispatcher.dispatch({
                type: ApiActionTypes.LOAD_GRUPO_SUCCESS,
                products: response.data.objecto, // Certifique-se de passar os dados corretos aqui
            });
              
        })
        .catch(error => {
            console.error("Erro ao carregar Produtos " + error);
            AppDispatcher.dispatch({
                type: ApiActionTypes.LOAD_GRUPO_ERROR,
            });
        });
        // Implementar chamada à API para carregar produtos
    },
    loadMinhasSocias: () => {
        AppDispatcher.dispatch({
            type: ApiActionTypes.START_LOADING,

        });
        api.get('MeusGruposCompra', {
                headers: {
                    'ApiKey': apiKey,
                    'Authorization': 'Bearer ' + token
                }
            })
            .then(response => {
                AppDispatcher.dispatch({
                    type: ApiActionTypes.END_LOADING,

                });
                AppDispatcher.dispatch({
                    type: ApiActionTypes.SOCIA_LOAD_DATA_SUCCESS,
                    products: response.data.objecto,
                });
            })
            .catch(error => {
                console.error('teste 33')
                console.error("Erro ao carregar Produtos " + error)
                AppDispatcher.dispatch({
                    type: ApiActionTypes.END_LOADING,

                });
            });
        // Implementar chamada à API para carregar produtos
    },
    loadDetalhe: (id) => {
        const params = {
            idcampanha: id,

        };
        AppDispatcher.dispatch({
            type: ApiActionTypes.START_LOADING,

        });
        api.get('DetalheCampanha', {
                headers,
                params
            })
            .then(response => {
                
                AppDispatcher.dispatch({
                    type: ApiActionTypes.END_LOADING,

                });

                AppDispatcher.dispatch({
                    type: ApiActionTypes.LOAD_DETALHE_SUCCESS,
                    products: response.data.objecto,
                });
            })
            .catch(error => {
                console.error('teste erro produtoCategoria')

                AppDispatcher.dispatch({
                    type: ApiActionTypes.END_LOADING,

                });
            });
        // Implementar chamada à API para carregar produtos
    },
    loadDetalheProduto: (id) => {
   
        const params = {
            idproduto: id,

        };
        AppDispatcher.dispatch({
            type: ApiActionTypes.START_LOADING,

        });
        api.get('VerProduto', {
                headers,
                params
            })
            .then(response => {
                
                AppDispatcher.dispatch({
                    type: ApiActionTypes.END_LOADING,

                });
               
                AppDispatcher.dispatch({
                    type: ApiActionTypes.LOAD_DETALHE_PRODUTO_SUCCESS,
                    products: response.data.objecto,
                });
            })
            .catch(error => {
               

                AppDispatcher.dispatch({
                    type: ApiActionTypes.END_LOADING,

                });
            });
        // Implementar chamada à API para carregar produtos
    },
    loadDetalhePromo: (id) => {
        // console.error(id)
        const params = {
            idPromo: id,

        };
        AppDispatcher.dispatch({
            type: ApiActionTypes.LOAD_DETALHE_PROMO_START,

        });
        api.get('VerDetalhePromocao', {
                headers,
                params
            })
            .then(response => {
                
                AppDispatcher.dispatch({
                    type: ApiActionTypes.END_LOADING,

                });

                AppDispatcher.dispatch({
                    type: ApiActionTypes.LOAD_DETALHE_PROMO_SUCCESS,
                    products: response.data.objecto,
                });
            })
            .catch(error => {
                console.error(' erro ao trazer promocao')

                AppDispatcher.dispatch({
                    type: ApiActionTypes.LOAD_DETALHE_PROMO_ERROR,

                });
            });
        // Implementar chamada à API para carregar produtos
    },
    loadMinhasFacturas: () => {
        AppDispatcher.dispatch({
            type: ApiActionTypes.START_LOADING,

        });
        api.get('MinhasFacturas', {
                headers: {
                    'ApiKey': apiKey,
                    'Authorization': 'Bearer ' + token
                }
            })
            .then(response => {
                AppDispatcher.dispatch({
                    type: ApiActionTypes.END_LOADING,

                });
                AppDispatcher.dispatch({
                    type: ApiActionTypes.FACTURA_LOADED_SUCCESS,
                    products: response.data.objecto,
                });
            })
            .catch(error => {
                console.error('teste 33')
                console.error("Erro ao carregar Produtos " + error)
                AppDispatcher.dispatch({
                    type: ApiActionTypes.END_LOADING,

                });
            });
        // Implementar chamada à API para carregar produtos
    },
loadMinhasSubescricao: () => {
        AppDispatcher.dispatch({
            type: ApiActionTypes.START_LOADING_SUBSCRICAO,

        });
        api.get('VerProdutosSubescritor', {
                headers: {
                    'ApiKey': apiKey,
                    'Authorization': 'Bearer ' + token
                }
            })
            .then(response => {
               console.error(response.data.objecto)
                AppDispatcher.dispatch({
                    type: ApiActionTypes.END_LOADING_SUBSCRICAO,

                });
                AppDispatcher.dispatch({
                    type: ApiActionTypes.SUCESS_LOADING_SUBSCRICAO,
                    products: response.data.objecto,
                });
            })
            .catch(error => {
                console.error('teste 33')
                console.error("Erro ao carregar Produtos " + error)
                AppDispatcher.dispatch({
                    type: ApiActionTypes.END_LOADING,

                });
            });
        // Implementar chamada à API para carregar produtos
    },
    addToCart: (idProduto, desconto, quantidade) => {
        return new Promise((resolve, reject) => {
            const requestBody = {
                quantidade: quantidade,
                desconto: desconto == null ? 0 : desconto,
                idProduto: idProduto
            };

            api.post('AddProdutoCarrinho', requestBody, {
                headers: {
                    'Content-Type': 'application/json',
                    'ApiKey': apiKey,
                    'Authorization': 'Bearer ' + token
                }
            }).then(response => {
                AppDispatcher.dispatch({
                    type: ApiActionTypes.ADD_TO_CART,
                    products: response.data.objecto,
                });
                resolve(response);
            }).catch(error => {
                AppDispatcher.dispatch({
                    type: ApiActionTypes.ADD_TO_CART_ERROR,
                    error: error.response ? error.response.data : "Erro desconhecido",
                });
                reject(error);
            });
        });
    },
    subEscreverProduto: (idProduto, dia, quantidade , frequencia) => {
        return new Promise((resolve, reject) => {
            const requestBody = {
                quantidade: quantidade,
                dia: dia,
                frequencia: frequencia,
                idProduto: idProduto
            };

            api.post('SubsEcricao', requestBody, {
                headers: {
                    'Content-Type': 'application/json',
                    'ApiKey': apiKey,
                    'Authorization': 'Bearer ' + token
                }
            }).then(response => {
                AppDispatcher.dispatch({
                    type: ApiActionTypes.ADD_TO_CART,
                    products: response.data.objecto,
                });
                resolve(response);
            }).catch(error => {
                AppDispatcher.dispatch({
                    type: ApiActionTypes.ADD_TO_CART_ERROR,
                    error: error.response ? error.response.data : "Erro desconhecido",
                });
                reject(error);
            });
        });
    },
    AlterarsubEscreverProduto: (idProduto, dia, quantidade , frequencia) => {
        return new Promise((resolve, reject) => {
            const requestBody = {
                quantidade: quantidade,
                dia: dia,
                frequencia: frequencia,
                id: idProduto
            };

            api.post('AlterarSubescricao', requestBody, {
                headers: {
                    'Content-Type': 'application/json',
                    'ApiKey': apiKey,
                    'Authorization': 'Bearer ' + token
                }
            }).then(response => {
                AppDispatcher.dispatch({
                    type: ApiActionTypes.ADD_TO_CART,
                    products: response.data.objecto,
                });
                resolve(response);
            }).catch(error => {
                AppDispatcher.dispatch({
                    type: ApiActionTypes.ADD_TO_CART_ERROR,
                    error: error.response ? error.response.data : "Erro desconhecido",
                });
                reject(error);
            });
        });
    },
    removerSubescricao: (id) => {
        return new Promise((resolve, reject) => {
            const requestBody = {
                id: id,
                
            };

            api.post('RemoverSubescricaoo', requestBody, {
                headers: {
                    'Content-Type': 'application/json',
                    'ApiKey': apiKey,
                    'Authorization': 'Bearer ' + token
                }
            }).then(response => {
                AppDispatcher.dispatch({
                    type: ApiActionTypes.ADD_TO_CART,
                    products: response.data.objecto,
                });
                resolve(response);
            }).catch(error => {
                AppDispatcher.dispatch({
                    type: ApiActionTypes.ADD_TO_CART_ERROR,
                    error: error.response ? error.response.data : "Erro desconhecido",
                });
                reject(error);
            });
        });
    },
    CriarGrupo: (nome, descricao, isPrivado) => {
         AppDispatcher.dispatch({
            type: ApiActionTypes.CREATE_GRUPO_START,

        });
        return new Promise((resolve, reject) => {
            const requestBody = {
                nome: nome,
                descricao: descricao == null ? "" : descricao,
                isPrivado: isPrivado == "private" ? true : false
            };
          
            api.post('CriarGrupo', requestBody, {
                headers: {
                    'Content-Type': 'application/json',
                    'ApiKey': apiKey,
                    'Authorization': 'Bearer ' + token
                }
            }).then(response => {
                AppDispatcher.dispatch({
                    type: ApiActionTypes.CREATE_GRUPO_SUCESS,
                    products: response.data.objecto,
                });
                resolve(response);
            }).catch(error => {
                AppDispatcher.dispatch({
                    type: ApiActionTypes.CREATE_GRUPO_ERROR,
                    error: error.response ? error.response.data : "Erro desconhecido",
                });
                reject(error);
            });
        });
    },
     joinGrupo: (idGrupo) => {
        return new Promise((resolve, reject) => {
            const requestBody = {

                idGrupo: idGrupo
            };

            api.post('AderirGrupo', requestBody, {
                headers: {
                    'Content-Type': 'application/json',
                    'ApiKey': apiKey,
                    'Authorization': 'Bearer ' + token
                }
            }).then(response => {
                AppDispatcher.dispatch({
                    type: ApiActionTypes.ADD_TO_CART,
                    products: response.data.objecto,
                });
                resolve(response);
            }).catch(error => {
                AppDispatcher.dispatch({
                    type: ApiActionTypes.ADD_TO_CART_ERROR,
                    error: error.response ? error.response.data : "Erro desconhecido",
                });
                reject(error);
            });
        });
    },
     joinGrupotelefone: (idGrupo , telefone) => {
        return new Promise((resolve, reject) => {
            const requestBody = {
                telefone : telefone ,
                idGrupo: idGrupo
            };

            api.post('AderirGrupo', requestBody, {
                headers: {
                    'Content-Type': 'application/json',
                    'ApiKey': apiKey,
                    'Authorization': 'Bearer ' + token
                }
            }).then(response => {
                AppDispatcher.dispatch({
                    type: ApiActionTypes.ADD_TO_CART,
                    products: response.data.objecto,
                });
                resolve(response);
            }).catch(error => {
                AppDispatcher.dispatch({
                    type: ApiActionTypes.ADD_TO_CART_ERROR,
                    error: error.response ? error.response.data : "Erro desconhecido",
                });
                reject(error);
            });
        });
    },
    joinSocia: (idSocia) => {
        return new Promise((resolve, reject) => {
            const requestBody = {

                id: idSocia
            };

            api.post('AderirCompraEmGrupo', requestBody, {
                headers: {
                    'Content-Type': 'application/json',
                    'ApiKey': apiKey,
                    'Authorization': 'Bearer ' + token
                }
            }).then(response => {
                AppDispatcher.dispatch({
                    type: ApiActionTypes.ADD_TO_CART,
                    products: response.data.objecto,
                });
                resolve(response);
            }).catch(error => {
                AppDispatcher.dispatch({
                    type: ApiActionTypes.ADD_TO_CART_ERROR,
                    error: error.response ? error.response.data : "Erro desconhecido",
                });
                reject(error);
            });
        });
    },
    joinMembroSocia: (idSocia , idMembro) => {
        return new Promise((resolve, reject) => {
            const requestBody = {

                id: idSocia,
                idcLiente:idMembro
            };

            api.post('AderirCompraEmGrupo', requestBody, {
                headers: {
                    'Content-Type': 'application/json',
                    'ApiKey': apiKey,
                    'Authorization': 'Bearer ' + token
                }
            }).then(response => {
                AppDispatcher.dispatch({
                    type: ApiActionTypes.ADD_TO_CART,
                    products: response.data.objecto,
                });
                resolve(response);
            }).catch(error => {
                AppDispatcher.dispatch({
                    type: ApiActionTypes.ADD_TO_CART_ERROR,
                    error: error.response ? error.response.data : "Erro desconhecido",
                });
                reject(error);
            });
        });
    },
    refazerCompra: (idfactura) => {
        return new Promise((resolve, reject) => {
            const requestBody = {

                idEncomenda: idfactura
            };

            api.post('BuyTwice', requestBody, {
                headers: {
                    'Content-Type': 'application/json',
                    'ApiKey': apiKey,
                    'Authorization': 'Bearer ' + token
                }
            }).then(response => {
                AppDispatcher.dispatch({
                    type: ApiActionTypes.ADD_TO_CART,
                    products: response.data.objecto,
                });
                resolve(response);
            }).catch(error => {
                AppDispatcher.dispatch({
                    type: ApiActionTypes.ADD_TO_CART_ERROR,
                    error: error.response ? error.response.data : "Erro desconhecido",
                });
                reject(error);
            });
        });
    },
    CreateSocia: (idProduto, quantidade, nome, socios, desconto, isPrivado, idGrupo , tipo) => {
    
       
        return new Promise((resolve, reject) => {
            const requestBody = {
                nome: nome,
                desconto: desconto,
                descricao: nome,
                numero: socios,
                user: true,
                tipo: tipo,
                isPrivado: isPrivado,
                idGrupo: idGrupo,
                produtos: [
        
                    { id: idProduto, quantidade: quantidade },
       
               
                ],
      
    };
            

            api.post('NewLista', requestBody, {
                headers: {
                    'Content-Type': 'application/json',
                    'ApiKey': apiKey,
                    'Authorization': 'Bearer ' + token
                }
            }).then(response => {
                AppDispatcher.dispatch({
                    type: ApiActionTypes.ADD_TO_CART,
                    products: response.data.objecto,
                });
                resolve(response);
            }).catch(error => {
                AppDispatcher.dispatch({
                    type: ApiActionTypes.ADD_TO_CART_ERROR,
                    error: error.response ? error.response.data : "Erro desconhecido",
                });
                reject(error);
            });
        });
    },
    cancelarGrupoSocia: (idSocia) => {
        return new Promise((resolve, reject) => {
            const requestBody = {

                id: idSocia
            };

            api.post('CancelarCompraemgrupo', requestBody, {
                headers: {
                    'Content-Type': 'application/json',
                    'ApiKey': apiKey,
                    'Authorization': 'Bearer ' + token
                }
            }).then(response => {
                AppDispatcher.dispatch({
                    type: ApiActionTypes.ADD_TO_CART,
                    products: response.data.objecto,
                });
                resolve(response);
            }).catch(error => {
                AppDispatcher.dispatch({
                    type: ApiActionTypes.ADD_TO_CART_ERROR,
                    error: error.response ? error.response.data : "Erro desconhecido",
                });
                reject(error);
            });
        });
    },
    cancelarAdesaoSocia: (idSocia) => {
        return new Promise((resolve, reject) => {
            const requestBody = {

                id: idSocia
            };

            api.post('CancelarAdesao', requestBody, {
                headers: {
                    'Content-Type': 'application/json',
                    'ApiKey': apiKey,
                    'Authorization': 'Bearer ' + token
                }
            }).then(response => {
                AppDispatcher.dispatch({
                    type: ApiActionTypes.ADD_TO_CART,
                    products: response.data.objecto,
                });
                resolve(response);
            }).catch(error => {
                AppDispatcher.dispatch({
                    type: ApiActionTypes.ADD_TO_CART_ERROR,
                    error: error.response ? error.response.data : "Erro desconhecido",
                });
                reject(error);
            });
        });
    },
     SairGrupo: (idSocia) => {
        return new Promise((resolve, reject) => {
            const requestBody = {

                idGrupo: idSocia
            };

            api.post('SairGrupo', requestBody, {
                headers: {
                    'Content-Type': 'application/json',
                    'ApiKey': apiKey,
                    'Authorization': 'Bearer ' + token
                }
            }).then(response => {
                AppDispatcher.dispatch({
                    type: ApiActionTypes.ADD_TO_CART,
                    products: response.data.objecto,
                });
                resolve(response);
            }).catch(error => {
                AppDispatcher.dispatch({
                    type: ApiActionTypes.ADD_TO_CART_ERROR,
                    error: error.response ? error.response.data : "Erro desconhecido",
                });
                reject(error);
            });
        });
    },
    removeFromCart: (productId) => {
        return new Promise((resolve, reject) => {
            const requestBody = {

                idSocia: productId,
            };

            api.post('Cancelar', requestBody, {
                headers: {
                    'Content-Type': 'application/json',
                    'ApiKey': apiKey,
                    'Authorization': 'Bearer ' + token
                }
            }).then(response => {
                AppDispatcher.dispatch({
                    type: ApiActionTypes.ADD_TO_CART,
                    products: response.data.objecto,
                });
                resolve(response);
            }).catch(error => {
                AppDispatcher.dispatch({
                    type: ApiActionTypes.ADD_TO_CART_ERROR,
                    error: error.response ? error.response.data : "Erro desconhecido",
                });
                reject(error);
            });
        });
        // Implementar chamada à API para remover produto do carrinho
    },
    loadCart: () => {
       
        AppDispatcher.dispatch({
            type: ApiActionTypes.START_LOADING,

        });
        api.get('Meucarrinho', {
                headers: {
                    'ApiKey': apiKey,
                    'Authorization': 'Bearer ' + token
                }
            })
            .then(response => {
                AppDispatcher.dispatch({
                    type: ApiActionTypes.END_LOADING,

                });

                AppDispatcher.dispatch({
                    type: ApiActionTypes. CARRINHO_LOAD_DATA_SUCCESS,
                    products: response.data.objecto,
                });

            })
            .catch(error => {
                console.error('teste 33')
                console.error("Erro ao carregar Produtos " + error)
                AppDispatcher.dispatch({
                    type: ApiActionTypes.END_LOADING,

                });
            });
        // Implementar chamada à API para carregar o carrinho
    },
    loadlocalidade: () => {
        //console.error('Helner')
        AppDispatcher.dispatch({
            type: ApiActionTypes.LOCALIDADE_LOAD_DATA_START,

        });
        api.get('verLocalEntregas', {
                headers: {
                    'ApiKey': apiKey,
                    'Authorization': 'Bearer ' + token
                }
            })
            .then(response => {
               

                AppDispatcher.dispatch({
                    type: ApiActionTypes. LOCALIDADE_LOAD_DATA_SUCCESS,
                    products: response.data.objecto,
                });
console.error(response.data.objecto)
            })
            .catch(error => {
               
               
                AppDispatcher.dispatch({
                    type: ApiActionTypes.LOCALIDADE_LOAD_DATA_ERROR,

                });
            });
        // Implementar chamada à API para carregar o carrinho
    },
    loadDespensa: () => {
        //console.error('Helner')
        AppDispatcher.dispatch({
            type: ApiActionTypes.GENERIC_LOAD_DATA_START,

        });
        api.get('PredictDispensa', {
                headers: {
                    'ApiKey': apiKey,
                    'Authorization': 'Bearer ' + token
                }
            })
            .then(response => {
               

                AppDispatcher.dispatch({
                    type: ApiActionTypes. GENERIC_LOAD_DATA_SUCCESS,
                    products: response.data.objecto,
                });
console.error(response.data.objecto)
            })
            .catch(error => {
               
               
                AppDispatcher.dispatch({
                    type: ApiActionTypes.END_LOADING,

                });
            });
        // Implementar chamada à API para carregar o carrinho
    },
    searchProductsByName: (name) => {
        const params = {
            nome: name,

        };
        AppDispatcher.dispatch({
            type: ApiActionTypes.START_LOADING,

        });
        api.get('ProcurarProduto', {
                params,
                headers
            })
            .then(response => {
                AppDispatcher.dispatch({
                    type: ApiActionTypes.END_LOADING,

                });
                AppDispatcher.dispatch({
                    type: ApiActionTypes.LOAD_PRODUCTS,
                    products: response.data.objecto,
                });
            })
            .catch(error => {
                console.error('teste 33')
                console.error("Erro ao carregar Produtos " + error)
                AppDispatcher.dispatch({
                    type: ApiActionTypes.END_LOADING,

                });
            });
        // Implementar chamada à API para buscar produtos por nome
    },
 
    actualizarCarrinho: (id , quantidade) => {
        return new Promise((resolve, reject) => {
            const requestBody = {

                id: id,
                quantidade : quantidade
            };

            api.post('UpdateCarrinho', requestBody, {
                headers: {
                    'Content-Type': 'application/json',
                    'ApiKey': apiKey,
                    'Authorization': 'Bearer ' + token
                }
            }).then(response => {
                AppDispatcher.dispatch({
                    type: ApiActionTypes.ADD_TO_CART,
                    products: response.data.objecto,
                });
                resolve(response);
            }).catch(error => {
                AppDispatcher.dispatch({
                    type: ApiActionTypes.ADD_TO_CART_ERROR,
                    error: error.response ? error.response.data : "Erro desconhecido",
                });
                reject(error);
            });
        });
    },
     addVoucher: (voucher) => {
         return new Promise((resolve, reject) => {
             AppDispatcher.dispatch({
                    type: ApiActionTypes.VOUCHER_ADD_START,
                    //error: error.response ? error.response.data : "Erro desconhecido",
                });
            const requestBody = {

              
                nome : voucher
            };

            api.post('UseVoucher', requestBody, {
                headers: {
                    'Content-Type': 'application/json',
                    'ApiKey': apiKey,
                    'Authorization': 'Bearer ' + token
                }
            }).then(response => {
                AppDispatcher.dispatch({
                    type: ApiActionTypes.VOUCHER_ADD_SUCCESS,
                    products: response.data.objecto,
                });
                resolve(response);
            }).catch(error => {
                AppDispatcher.dispatch({
                    type: ApiActionTypes. VOUCHER_ADD_ERROR,
                    error: error.response ? error.response.data : "Erro desconhecido",
                });
                reject(error);
            });
        });
    },
    RemoverProCarrinho: (id , quantidade) => {
        return new Promise((resolve, reject) => {
            const requestBody = {

                idSocia: id
               
            };

            api.post('remover', requestBody, {
                headers: {
                    'Content-Type': 'application/json',
                    'ApiKey': apiKey,
                    'Authorization': 'Bearer ' + token
                }
            }).then(response => {
                console.log(`Produto removido ${id}`)
                AppDispatcher.dispatch({
                    type: ApiActionTypes.ADD_TO_CART,
                    products: response.data.objecto,
                });
                resolve(response);
            }).catch(error => {
                  console.log(`Produto nao removido ${id}`)
                AppDispatcher.dispatch({
                    type: ApiActionTypes.ADD_TO_CART_ERROR,
                    error: error.response ? error.response.data : "Erro desconhecido",
                });
                reject(error);
            });
        });
    },
    PagarBAI: (id , idtransportadora) => {
          AppDispatcher.dispatch({
            type: ApiActionTypes.BAIPAGA_LOAD_DATA_START,

        });
        return new Promise((resolve, reject) => {
                        const encomenda = {
                        
                          tipoOperacao: "SO",

                        idParceiro: idtransportadora
                    };
            
            
           
            api.post('Pagarbanco', encomenda, {
              
                headers: {
                    'Content-Type': 'application/json',
                    'ApiKey': apiKey,
                    'Authorization': 'Bearer ' + token
                }
            }).then(response => {
                AppDispatcher.dispatch({
                    type: ApiActionTypes.BAIPAGA_LOAD_DATA_SUCCESS,
                    products: response.data.objecto,
                });
                resolve(response);
            }).catch(error => {
                  console.error("Error adding to cart:", error);
                AppDispatcher.dispatch({
                    type: ApiActionTypes.BAIPAGA_LOAD_DATA_ERROR,
                    error: error.response ? error.response.data : "Erro desconhecido",
                });
                reject(error);
            });
        });
    },
    PagarUnitel: (id , idtransportadora) => {
         AppDispatcher.dispatch({
            type: ApiActionTypes.UNITEL_LOAD_DATA_START,

        });
        return new Promise((resolve, reject) => {
            
                    const encomenda = {
                       
                        
                        tipoOperacao: "SO",
  
                        idParceiro: idtransportadora
                    };
            
            
            const dados = {
                           
                "encomenda": encomenda,
    
            };
            console.error(dados)

            api.post('PagarUnitelMoney', encomenda, {
                headers: {
                    'Content-Type': 'application/json',
                    'ApiKey': apiKey,
                    'Authorization': 'Bearer ' + token
                }
            }).then(response => {
                AppDispatcher.dispatch({
                    type: ApiActionTypes.UNITEL_LOAD_DATA_SUCCESS,
                    products: response.data.objecto,
                });
                resolve(response);
            }).catch(error => {
                 console.error("Error adding to cart:", error);
                AppDispatcher.dispatch({
                    type: ApiActionTypes.UNITEL_LOAD_DATA_ERROR,
                    error: error.response ? error.response.data : "Erro desconhecido",
                });
                reject(error);
            });
        });
    },
     PagarIban: (id, idtransportadora) => {
    return new Promise((resolve, reject) => {
        const encomenda = {
            idCliente: "",
            idEncomenda: id,
            pickup: false,
            tb: true,
            express: false,
            periodo: "", // Corrigido de "perido" para "periodo"
            dataEntrega: new Date(),
            telefone: "",
            prestacao: false,
            idParceiro: idtransportadora
        };

        const dados = {
            encomenda: encomenda
        };

        console.error(dados);

        api.post('EnviarCoordenadas', dados, {
            headers: {
                'Content-Type': 'application/json',
                'ApiKey': apiKey,
                'Authorization': 'Bearer ' + token
            }
        }).then(response => {
            AppDispatcher.dispatch({
                type: ApiActionTypes.ADD_TO_CART,
                products: response.data.objecto,
            });
            resolve(response);
        }).catch(error => {
            console.error("Error Saving:", dados);
            AppDispatcher.dispatch({
                type: ApiActionTypes.ADD_TO_CART_ERROR,
                error: error.response ? error.response.data : "Erro desconhecido",
            });
            reject(error);
        });
    });
},


    login: (phone, password) => {
        AppDispatcher.dispatch({
            type: ApiActionTypes.LOGIN_START,

        });
      return new Promise((resolve, reject) => {
            const requestBody = {

               telefone: phone,
          pwd: password,
            };

            api.post('login', requestBody, {
                headers: {
                    'Content-Type': 'application/json',
                    'ApiKey': apiKey,
                    'Authorization': 'Bearer ' + token
                }
            }).then(response => {
                  localStorage.setItem("token", response.data.objecto.token.token);
                AppDispatcher.dispatch({
                    type: ApiActionTypes.LOGIN_FINISH,
                    products: response.data.objecto,
                });
                resolve(response);
            }).catch(error => {
                AppDispatcher.dispatch({
                    type: ApiActionTypes.LOGIN_ERROR,
                    error: error.response ? error.response.data : "Erro desconhecido",
                });
                reject(error);
            });
        });
    },

     createAccount: (user) => {
        AppDispatcher.dispatch({
            type: ApiActionTypes.ACCOUNT_CREATION_START,

        });
      return new Promise((resolve, reject) => {
            const requestBody = {

               PrimerioNome: user.PrimerioNome,
                UltimoNome: user.UltimoNome,
                Email: user.Email,
                Telefone: user.Telefone,
                Bairro: user.Bairro,
                Rua: user.Rua,
                Municipio: user.Municipio,
                idMunicipio: user.idMunicipio,
                idPais: user.idPais,
                idProvincia: user.idProvincia,
                Pwd: user.Pwd
                
                
          
            };

            api.post('CriarConta', requestBody, {
                headers: {
                    'Content-Type': 'application/json',
                    'ApiKey': apiKey,
                    'Authorization': 'Bearer ' + token
                }
            }).then(response => {
                  localStorage.setItem("token", response.data.objecto.token.token);
                AppDispatcher.dispatch({
                    type: ApiActionTypes.ACCOUNT_CREATION_FINISH,
                    products: response.data.objecto,
                });
                resolve(response);
            }).catch(error => {
                AppDispatcher.dispatch({
                    type: ApiActionTypes.ACCOUNT_CREATION_FINISH,
                    error: error.response ? error.response.data : "Erro desconhecido",
                });
                reject(error);
            });
        });
    }

};

export { ApiActions, ApiActionTypes };