// src/components/Novidades.jsx

import React, { useEffect, useState, useCallback } from "react";
import BeatLoader from "react-spinners/BeatLoader";
import { ApiActions } from "../../actions/ApiActions";
import AppStore from "../../stores/AppStore";
import ProductCard from "./ProductCard";
import { useNavigate } from "react-router-dom";
import CryptoJS from "crypto-js";

const formatCurrency = (value, locale = "pt-AO", currency = "AOA") =>
  new Intl.NumberFormat(locale, {
    style: "currency",
    currency,
  }).format(value);

const PriceDisplay = ({ price }) => <div>{formatCurrency(price)}</div>;

const Novidades = ({ onShowModal }) => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(AppStore.getIsLoading());
  const [products, setProducts] = useState(AppStore.getProducts());

  const handleAddToCart = useCallback(
    (produto) => {
      const token = localStorage.getItem("token");
      if (!token) {
        onShowModal(true);
      } else {
        const key = CryptoJS.enc.Hex.parse(
          "0879febbaf365b1535e175da463ca7bcd7fdc76956282fa33c2cf5a9c28cc1b5"
        );
        const ciphertext = CryptoJS.AES.encrypt(
          CryptoJS.enc.Utf8.parse(produto.id),
          key,
          {
            mode: CryptoJS.mode.ECB,
            padding: CryptoJS.pad.Pkcs7,
          }
        ).toString();
        const safeEncoded = ciphertext.replace(/\//g, "_");
        navigate(`/product/${safeEncoded}`);
      }
    },
    [navigate, onShowModal]
  );

  useEffect(() => {
    const handleChanges = () => setProducts(AppStore.getProducts());
    const handleLoading = () => setIsLoading(AppStore.getIsLoading());

    AppStore.on("carregando", handleLoading);
    AppStore.on("change", handleChanges);

    ApiActions.loadProdutosCategoria(8);

    return () => {
      AppStore.removeListener("change", handleChanges);
      AppStore.removeListener("carregando", handleLoading);
    };
  }, []);

  if (isLoading) {
    return (
      <div className="flex flex-col items-center justify-center">
        <div className="absolute inset-0 flex flex-col items-center justify-center bg-white bg-opacity-70 z-50">
          <BeatLoader />
          <p className="mt-4 text-customPurple">
            A preparar a lista de novidades para si...
          </p>
        </div>
      </div>
    );
  }

  return (
    <div className="mt-20 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-10 py-8">
      {products.map((produto, index) => (
        <ProductCard
          key={index}
          addCarrinho={handleAddToCart}
          produto={produto}
        />
      ))}
    </div>
  );
};

export default Novidades;
