// SelectedItemContext.js

import React, { createContext, useContext, useState } from 'react';

const SelectedItemContext = createContext();

export const useSelectedItem = () => useContext(SelectedItemContext);

export const SelectedItemProvider = ({ children }) => {
    const [selectedItem, setSelectedItem] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const selectItem = (item) => {
        setSelectedItem(item);
        setIsModalOpen(true); // Open modal when item is selected
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setSelectedItem(null); // Optionally clear the selected item on close
    };

    return ( <SelectedItemContext.Provider value = {
        { selectedItem, isModalOpen, selectItem, closeModal }} > {children}
        </SelectedItemContext.Provider>
    );
};