import React, { useState, useEffect } from 'react';
import { IoIosClose } from "react-icons/io";
import { Link } from "react-router-dom";

const Toast = ({ onExibido }) => {
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setVisible(false);
      onExibido();
    }, 120000); // 2 minutos em milissegundos

    
    return () => clearTimeout(timer);
  }, [onExibido]);

  const onClose = () => {
    setVisible(false)
    localStorage.setItem("tmpexcta", true);
  }

  return (
    visible && (
      <div className="w-96 fixed bottom-0 right-0 p-3 m-8 shadow-2xl bg-white border backdrop-blur-lg z-50 rounded-md">
        <div className='flex flex-col items-center gap-2'>
          <div className='w-full flex flex-row items-center justify-between'>
            <p className='text-md font-semibold leading-7 [&:not(:first-child)]:mt-6 text-customPurple animate-bounce'>Convide amigos e ganhe! 🎉</p>
          <IoIosClose className='cursor-pointer' size={20} onClick={onClose}  />
          </div>
        <span className='text-sm font-medium leading-2 text-gray-500'>Ganhe 500 KZS na sua conta de usuário para cada novo amigo que você convidar. Clique no botão 'Convidar' no <Link to="/perfil" className='text-customPurple border-b p-1'>seu perfil</Link> para começar a ganhar hoje mesmo!</span>
        </div>
        </div>
    )
  );
};

export default Toast;
