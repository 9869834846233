import React, { useState, useEffect } from "react";

import { FaShare } from "react-icons/fa";

const retornaRData = (dateString) => {
  const inputFormat = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2})$/;
  const match = dateString.match(inputFormat);

  if (match) {
    const year = match[1];
    const month = match[2] - 1;
    const day = match[3];
    const hours = match[4];
    const minutes = match[5];
    const seconds = match[6];

    const date = new Date(Date.UTC(year, month, day, hours, minutes, seconds));

    const formatter = new Intl.DateTimeFormat("pt-PT", {
      weekday: "short",
      day: "2-digit",
      month: "short",
      year: "numeric",
    });

    return formatter.format(date);
  } else {
    return dateString;
  }
};

const CardSociaExpress = ({ socia, verdetalhe }) => {
  
     // Convertendo o tempo de minutos para segundos para o estado inicial de secondsLeft
  const [secondsLeft, setSecondsLeft] = useState(socia.tempo * 60);
  const formattedDate = retornaRData(socia.data);

  


  useEffect(() => {
    const interval = setInterval(() => {
      setSecondsLeft((seconds) => (seconds > 0 ? seconds - 1 : 0));
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  // Calcular horas e minutos a partir de secondsLeft
  const hours = Math.floor(secondsLeft / 3600);
  const minutes = Math.floor((secondsLeft % 3600) / 60).toString().padStart(2, '0');

    

  function formatCurrency(value, locale = "pt-AO", currency = "AOA") {
    return new Intl.NumberFormat(locale, {
      style: "currency",
      currency: currency,
    }).format(value);
  }

  const progressPercentage = (socia.candidatos / socia.socios) * 100;
  const foto = socia.capa;

  return (
    <div className="">
      <div
        className="font-sans relative flex flex-col lg:flex-row gap-4 lg:gap-9 items-end bg-black rounded-lg p-4 lg:p-2 lg:h-[500px] bg-cover bg-center"
        style={{ backgroundImage: `url(${foto})` }}
          >
        <div className="absolute right-2 top-2 z-20 bg-opacity-75 bg-red-500 text-white py-2 px-3 rounded-lg text-lg uppercase shadow-lg animate-pulse font-digitaldream ">
        {hours > 0 ? hours + 'h:'  : ''}{minutes}m
      </div>
        <div className="flex flex-col items-start justify-start w-full">
          <div className="absolute inset-0 bg-gradient-to-t from-black opacity-80 to-transparent rounded-lg"></div>
          <div className="z-10 relative text-start w-full">
            <h2 className="text-base text-gray-100 uppercase font-bold">
              {socia.nome}
            </h2>
            <p className="text-xs lg:text-sm text-gray-100/60 uppercase">
              Grupo de {socia.socios} pessoas
            </p>
            <h4 className="text-sm lg:text-sm text-gray-100 uppercase font-semibold">
              {socia.vagas > 1 ? "SÓ FALTAM" : "SÓ FALTA"} {socia.vagas}{" "}
              {socia.vagas > 1 ? "PESSOAS" : "PESSOA"} PARA FECHAR A SÓCIA
            </h4>
            <p className="text-sm lg:text-sm text-gray-100/85 uppercase font-normal">
              DATA DE ENTREGA: {formattedDate}
            </p>
            

            <h4 className="text-base text-gray-100 uppercase font-semibold">
              Valor a pagar: {formatCurrency(socia.preco)}
            </h4>

            <p className="text-sm lg:text-sm text-gray-100/85 uppercase font-normal">
              Vai poupar: {formatCurrency(socia.desconto)}
            </p>
            <div className="w-full flex flex-col items-center justify-center mt-4">
              <button
                className="w-full py-2 bg-customPurple border border-gray-50/25 text-white mb-2 rounded"
                onClick={() => verdetalhe(socia)}
              >
                Juntar-se à sócia
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardSociaExpress;
