import React from 'react';

const benefits = [
  {
    icon: '🔹', // Substitute with appropriate icon or SVG
    title: 'Iniciante',
    description: '0 a 199 pontos (Cashback  em categroias selecionadas)',
  },
  {
    icon: '🥉', // Substitute with appropriate icon or SVG
    title: 'Bronze',
    description: '200 a 500 pontos (Cashback em categroias selecionadas)',
  },
  {
    icon: '🥈', // Substitute with appropriate icon or SVG
    title: 'Prata',
    description: '501 a 1000 pontos (Cashback de 10% em todas as compras, 10% de desconto nas entregas)',
  },
  {
    icon: '🥇', // Substitute with appropriate icon or SVG
    title: 'Ouro',
    description: '1001 a 1500 pontos (Cashback de 10% em todas as compras, 20% de desconto nas entregas)',
  },
  {
    icon: '💎', // Substitute with appropriate icon or SVG
    title: 'Diamante',
    description: 'A partir de 1501 pontos (Cashback de 20% em todas as compras, 25% de desconto nas entregas)',
  },
];

const CashbackInfo = ({ clientName }) => {
  return (
    <div className="bg-white p-6 rounded-lg shadow-md max-w-6xl mx-auto my-8">
      <h1 className="text-2xl font-bold mb-4">
        Olá {clientName ? clientName : 'Socio'},
      </h1>
      <p className="mb-4">
        Temos uma novidade especial para você!
      </p>
    <p className="mb-4">
       Na Sócia, queremos que você aproveite ao máximo as suas compras. Por isso, criamos o nosso programa de clientes "Mais Sócia", uma maneira incrível de economizar em todas as suas compras.
    </p>
    <h2 className="text-xl font-semibold mb-2">Como Funciona o "Mais Sócia"?</h2>
       <p className="mb-4">
      A cada 2000 kwanzas gastos, você ganha 1 ponto. Esses pontos são acumulados e te ajudam a melhorar o seu nível de compras, permitindo que você aproveite benefícios exclusivos, ao se juntar ao programa "Mais Sócia", você não apenas economiza dinheiro, mas também aproveita uma experiência de compra melhorada. Os pontos acumulados te ajudam a alcançar níveis mais altos, onde você ganha ainda mais cashback e descontos nas entregas..
    </p>
     
    <h2 className="text-xl font-semibold mb-2">O que é Cashback?</h2>
      <p className="mb-4">
        Cashback é um crédito que você recebe de volta na sua conta na Sócia sempre que faz uma compra. Esse valor é acumulado e usado automaticamente na sua próxima compra.
      </p>
      <h2 className="text-xl font-semibold mb-2">Como Funciona?</h2>
      <ul className="list-disc pl-5 mb-4">
        <li>A cada compra que você faz na Sócia, uma parte do valor gasto é devolvida como crédito.</li>
        <li>O percentual de cashback varia de acordo com o seu nível de compras.</li>
        <li>Os créditos acumulados são aplicados automaticamente como desconto na sua próxima compra.</li>
      </ul>
      <h2 className="text-xl font-semibold mb-2">Vantagens do Programa "Mais Sócia:</h2>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
        {benefits.map((benefit, index) => (
          <div key={index} className="text-center p-4 border rounded-lg shadow-sm">
            <div className="text-4xl mb-2">{benefit.icon}</div>
            <h3 className="text-lg font-bold mb-1">{benefit.title}</h3>
            <p>{benefit.description}</p>
          </div>
        ))}
      </div>
      <h2 className="text-xl font-semibold mb-2 mt-6">Exemplo Prático:</h2>
      <p className="mb-4">
        Se fizer uma compra de 50.000 kwanzas e estiver no nível Prata, você receberá 10% da margem de lucro da Sócia como cashback. Esse valor será acumulado em sua conta e aplicado automaticamente na próxima compra.
      </p>
      <p className="mb-4">
        Não perca essa oportunidade de economizar enquanto compra os produtos que adora. Comece a aproveitar o nosso programa hoje mesmo!
      </p>
      <p className="font-semibold">Atenciosamente,</p>
      <p>Equipe Sócia</p>
    </div>
  );
};

export default CashbackInfo;
