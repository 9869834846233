import React from 'react';

export default function ExplicacaoExpress({ onShowModal }) {
    return (
      <div className="font-sans max-w-4xl mx-auto p-5 text-justify ">
        <h1 className="text-2xl font-bold text-center mb-4 uppercase">Bem-vindo à Sócia Express!</h1>
        <p className="mb-4 ">A Socia Express transforma a experiência de compras online, oferecendo uma maneira emocionante e colaborativa de adquirir produtos com descontos extraordinários , <strong>para visualizar sócias express você precisa ter uma conta na nossa plataforma e estar logado.</strong> <br/> <br/> Aqui está como você pode aproveitar ao máximo essa oportunidade única: </p>
         
            <h2 className="text-xl font-semibold mb-3 uppercase">Como Funciona a Socia Express?</h2>
        
             <ul className="list-disc pl-5 mb-4">
          
                <li><strong className='uppercase'>Tempo é Essencial:</strong> Cada Socia Express dura apenas duas horas. Dentro desse curto período, você tem a chance de participar de uma compra coletiva que desbloqueia preços incrivelmente baixos para produtos que você ama.</li>
          
                <li><strong className='uppercase'>Compra em Grupo:</strong> A mágica da Socia Express acontece quando você se junta com outras socias . Para que a oferta seja ativada, um número específico de "socias" (participantes) precisa se juntar ao grupo de compra dentro do prazo de duas horas.</li>
          
            </ul>
            <h2 className="text-xl font-semibold mb-3 uppercase">Como Participar?</h2>
        
            <ul className="list-disc pl-5 mb-4">
          
                <li><strong className='uppercase'>Descubra o Seu Grupo:</strong> Encontre seu grupo na seção "Sócia Express" na página inicial.</li>
          
                <li><strong className='uppercase'>Descontos Espetaculares:</strong> Aproveite produtos da cesta básica com descontos incríveis.</li>
          
                <li><strong className='uppercase'>Pagamento à Entrega:</strong> Total tranquilidade, só paga quando receber. Sua confiança é nossa prioridade.</li>
          
                <li><strong className='uppercase'>Partilhe e Poupe:</strong> Divulgue o link do seu grupo e assegure a formação do grupo para desbloquear descontos fantásticos.</li>
        
            </ul>
        
     
        <p className="mb-4"><strong className='uppercase'>Ideal para Colegas</strong> Perfeito para quem trabalha em escritório ou frequenta a escola. Junte-se aos colegas para economizar!</p>

        <p className="mb-4"><strong className='uppercase'>Por que Sócia Express?</strong> Porque acreditamos que poupar deve ser simples, rápido e social. Unimos tecnologia para transformar a compra de produtos essenciais.</p>

        
      </div>
    );
  }



