import axios from "axios";
import React, { useState , useEffect } from "react";
import logo from "./Images/logotipo.png";
import LoginModal from "./LoginModal";
import { BeatLoader } from "react-spinners";
import { toast } from "react-toastify";

export const RegisterModal = ({ onShowLoginModal }) => {
  const [step, setStep] = useState(1);
  const [first, setFirst] = useState("");
  const [last, setLast] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [registerError, setRegisterError] = useState("");
  const [showLoginModal, setShowLoginModal] = useState(false); // Estado para controlar a exibição do modal de registro
  //const [country, setCountry] = useState(""); // Novo estado para o país
 // const [province, setProvince] = useState(""); // Novo estado para a província
  const [municipality, setMunicipality] = useState(""); // Novo estado para o município
  const [neighborhood, setNeighborhood] = useState(""); // Novo estado para o bairro
  const [street, setStreet] = useState("");
  const [password, setPassword] = useState("");
  const [confirm, setConfirm] = useState("");
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [countries, setCountrys] = useState([]);
  const [municipalities, setMunicipalities] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(0);
  const [selectedProvince, setSelectedProvince] = useState(0);
  const [selectedMunicipio, setselectedMunicipio] = useState(0);
  const [provinces, setProvinces] = useState([])
// Function to toggle the modal visibility
const toggleLoginModal = () => {
  setShowLoginModal(!showLoginModal);
};
  // Função para alternar a visualização da senha
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  // Função para alternar a visualização da confirmação da senha
  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const validateEmail = (email) => {
    return /\S+@\S+\.\S+/.test(email);
  };

  const validatePhone = (phone) => {
    return phone.length >= 8 && /^\d+$/.test(phone);
  };

  const validatePasswords = () => {
    return password === confirm && password.length >= 6;
  };


  const handleNextStep = () => {
     let errorMessage = "";
    if (step === 1 && (!first || !last || !email || !phone)) {
      setRegisterError("Por favor, preencha todos os campos.");
      return;
    }
   switch (step) {
      case 1:
        if (!first || !last || !validateEmail(email) || !validatePhone(phone)) {
          errorMessage = "Por favor, preencha todos os campos corretamente.";
        }
        break;
      case 2:
        if ( !municipality) {
          errorMessage = "Por favor, preencha todos os campos de localização.";
        }
        break;
      case 3:
        if (!neighborhood || !street) {
          errorMessage = "Por favor, preencha todos os campos de endereço.";
        }
        break;
      case 4:
        if (!validatePasswords()) {
          errorMessage = "As senhas não coincidem ou são muito curtas.";
        }
        break;
      default:
        break;
    }
    // Adicione outras validações de passo específicas conforme necessário

    if (errorMessage) {
      setRegisterError(errorMessage);
      return;
    }

    setStep(step + 1);
    setRegisterError("");
    setStep(step + 1);
    setRegisterError(""); 
  };

  const handlePreviousStep = () => {
    setStep(step - 1);
    setRegisterError(""); 
  };

  const handleSubmit = async (e) => {
      setLoading(true);
    e.preventDefault();
    if (!validatePasswords()) {
      setRegisterError("A senha não 6 caracteres ou não igual a senha de confirmação.");
      setLoading(false);
      return;
    }
  setLoading(true);
    try {
     
      const response = await axios.post(
        "https://api.socia.ao/apiV2/Socia/CriarConta",
        {
          PrimerioNome: first,
          UltimoNome: last,
          Email: email,
          Telefone: phone,
          Bairro: neighborhood,
          Rua: street,
          Municipio: municipality,
          idMunicipio: selectedMunicipio,
          idPais: selectedCountry,
          idProvincia: selectedProvince,
          Pwd: password
        },
        {
          headers: {
            ApiKey: "9MT3VnQYj2Mb7ILNqKy2HfF9AChqPeFwtDQ8QkGBZZ0=",
            Authorization: "2",
          },
        }
      );
         //console.log(response.data);
      if (response.data.sucesso) {
    
        //localStorage.setItem("token", response.data.objecto.token.token);
        // localStorage.setItem("fname", response.data.objecto.primerioNome);
        toast.success("Parabéns; a sua Conta foi criada com sucesso!");
        setPhone("");
        setShowLoginModal(true)
       // toggleLoginModal();
        
        //setLoading(false);
        // setPassword("");
        // window.location.reload();
      } else {
        //response.data.resposta
         toast.error(response.data.resposta);
         setLoading(false);
        //setErrorMessage("Login falhou, tente novamente.");
      }
    } catch (error) {
      setLoading(false);
      
       toast.error("Lamento , mas não foi possivel criar a sua conta!" );
  
    }

   
  };
 useEffect(() => {
    
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://api.socia.ao/apiV2/Socia/verLocalEntregas",
          {
            headers: {
              ApiKey: "9MT3VnQYj2Mb7ILNqKy2HfF9AChqPeFwtDQ8QkGBZZ0=",
              Authorization: "2",
            },
          }
        );
    
        setCountrys(response.data.objecto);
      } catch (error) {
        // console.error(error);
      }
    };

    fetchData();
 }, []);
   useEffect(() => {
     if (selectedCountry > 0) {
      
       const a =  selectedCountry  > 0 ? selectedCountry : 1
      // Filter provinces based on selected country
      const filteredProvinces = countries.find(
        (pais) => pais.id == a
      )?.provincias || [];
      setProvinces(filteredProvinces);
    }
  }, [selectedCountry]);

  useEffect(() => {
    if (selectedProvince > 0) {
      // Filter municipalities based on selected province
      const filteredMunicipalities = provinces.find(
        (province) => province.id == selectedProvince
      ).municipios;
      setMunicipalities(filteredMunicipalities);
    }
  }, [selectedProvince]);

   useEffect(() => {
    if (selectedMunicipio > 0 ) {
       const filteredMunicipalities = municipalities.find(
        (province) => province.id == selectedMunicipio
      ).municipio;
      setMunicipality(filteredMunicipalities);
      
    }
  }, [selectedMunicipio ,municipality ]);

  const handleCountryChange = (event) => {
   
    setSelectedCountry(event.target.value);
  };

  const handleProvinceChange = (event) => {
    setSelectedProvince(event.target.value);
  };
  const handleMunicipalityChange = (event) => {
   
    setselectedMunicipio(event.target.value);
   
    
  };
  return (
    <>
  {showLoginModal ? (
    <LoginModal />
  ) : (
    <div className="p-5 bg-white rounded-lg font-sans">
      <div className="flex flex-col items-center justify-center mb-3">
        <img
          src={logo}
          alt="Sócia logo"
          width={85}
          height={85}
          className="object-cover select-none"
        />
      </div>
      {registerError && (
        <p className="text-red-500 leading-7 mb-5">{registerError}</p>
      )}
      <form onSubmit={handleSubmit}>
        {step === 1 && (
          <div>
            <div className="mb-4">
              <input
                id="first"
                type="text"
                value={first}
                onChange={(e) => setFirst(e.target.value)}
                className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none "
                placeholder="Primeiro nome"
                required
              />
            </div>
            <div className="mb-4">
              <input
                id="last"
                type="text"
                value={last}
                onChange={(e) => setLast(e.target.value)}
                className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none"
                placeholder="Último nome"
                required
              />
            </div>
            <div className="mb-4">
              <input
                id="email"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none"
                placeholder="Email"
                required
              />
            </div>
            <div className="mb-4">
              <input
                id="phone"
                type="number"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none"
                placeholder="Telefone"
                required
              />
            </div>
          </div>
        )}
        {step === 2 && (
          <div>
            <div className="mb-4">
                    <select className="form-select rounded-md shadow-sm border border-gray-300 p-2.5 text-gray-700 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 w-full"
                      id="country"
                      name="country"
                      value={selectedCountry}
                      
                      onChange={handleCountryChange}>
                        <option value="">Selecione o pais</option>
                      
                      {countries.map((country) => (
          
          
                        <option key={country.id} value={country.id}>
            
                          {country.pais}
         
                        </option>
                      ))}
                      
                    </select>
                    
            </div>
            
                  <div className="mb-4">
          
                    <select className="form-select rounded-md shadow-sm border border-gray-300 p-2.5 text-gray-700 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 w-full"
                      id="province"
                      name="province"
                      value={selectedProvince}
                      onChange={handleProvinceChange}>
                        
                      <option value="">Selecione a provincia</option>
       
                      {provinces.map((province) => (
          
                        <option key={province.id} value={province.id}>
           
                          {province.provincia}
         
                        </option>
                      ))}
                      
      
                    </select>
            </div>
            <div className="mb-4">
             
                    <select className="form-select rounded-md shadow-sm border border-gray-300 p-2.5 text-gray-700 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 w-full"
                      id="municipality"
                      
                      name="municipality"
                    
                      value={selectedMunicipio}

                      onChange={handleMunicipalityChange}>
                      
                      <option value="">Selecione o municipio</option>
        
                      
                      {municipalities.map((municipality) => (
         
                        
                        <option key={municipality.id} value={municipality.id}>
           
                          {municipality.municipio}
          
                        </option>
        ))}
      
                    </select>
            </div>
          </div>
        )}
        {step === 3 && (
          <div>
            <div className="mb-4">
              <input
                id="neighborhood"
                type="text"
                value={neighborhood}
                onChange={(e) => setNeighborhood(e.target.value)}
                className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none "
                placeholder="Bairro"
                required
              />
            </div>
            <div className="mb-4">
              <input
                id="street"
                type="text"
                value={street}
                onChange={(e) => setStreet(e.target.value)}
                className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none "
                placeholder="Rua"
                required
              />
            </div>
          </div>
        )}
        {step === 4 && (
            <div>
              <div className="mb-4 relative">
                <input
                  id="password"
                  type={showPassword ? "text" : "password"}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none "
                  placeholder="Senha"
                  required
                />
                <button
                  type="button"
                  onClick={togglePasswordVisibility}
                  className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5"
                >
                  {showPassword ? "Esconder" : "Mostrar"}
                </button>
              </div>
              <div className="mb-4 relative">
                <input
                  id="confirm"
                  type={showConfirmPassword ? "text" : "password"}
                  value={confirm}
                  onChange={(e) => setConfirm(e.target.value)}
                  className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none"
                  placeholder="Confirmar palavra-passe"
                  required
                />
                <button
                  type="button"
                  onClick={toggleConfirmPasswordVisibility}
                  className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5"
                >
                  {showConfirmPassword ? "Esconder" : "Mostrar"}
                </button>
              </div>
            </div>
          )}

        <div className="flex flex-col items-center justify-center gap-3">
          
                <div className="w-full flex flex-col gap-2">
            <button
              type="button"
              onClick={step === 4 ? handleSubmit : handleNextStep}
              className="w-full px-10 py-2 bg-customPurple text-white font-bold rounded hover:bg-customPurple/90"
            >
              {step === 4 ? "Cadastrar" : "Próximo"}
            </button>
            {step > 1  && (
              <p
                onClick={handlePreviousStep}
                className="text-gray-400 cursor-pointer"
              >
                Voltar
              </p>
            )}
            <div className="flex flex-row items-center justify-center w-full gap-2">
              <div className="bg-gray-400/55 h-[1px] w-full"></div>
              <span className="text-gray400/55">ou</span>
              <div className="bg-gray-400/55 h-[1px] w-full"></div>
            </div>
            <button
              type="button"
              onClick={() => setShowLoginModal(true)}
              className="w-full px-10 py-2 bg-white text-customPurple font-bold rounded border border-customPurple/60"
            >
              Entrar
            </button>
                </div>
                <BeatLoader  loading={loading} size={15} />
        </div>
      </form>
    </div>
  )}
</>

  );
};

export default RegisterModal;
