import React from 'react';

const CategoryProdCard = ({ product }) => {
    return (
        <div className="h-full rounded-lg shadow-lg  p-4 text-center">
            <img src={product.foto} alt={product.produto} className="w-24 h-24 object-cover mx-auto" />
            <h3 className="mt-2 font-semibold uppercase">{product.produto}</h3>
            <p className="text-sm text-gray-600 uppercase mt-2">
                { product.familia}
        </p>
        </div>
    );
};

export default CategoryProdCard;
