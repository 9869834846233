import React, { useState, useEffect } from 'react';
import { ApiActions } from "../../actions/ApiActions";
import { toast } from "react-toastify";
import BeatLoader from "react-spinners/BeatLoader";

const CreateGroupForm = ({ product, onSubmitGroupBuy, userGroups }) => {
  const [quantityPerParticipant, setQuantityPerParticipant] = useState(parseInt(product.quantidade / 4));
  const [participants, setParticipants] = useState(2);
  const [discountPercent, setDiscountPercent] = useState(2);
  const [totalDiscount, setTotalDiscount] = useState(0);
  const [totalPriceAfterDiscount, setTotalPriceAfterDiscount] = useState(0);
  const [groupType, setGroupType] = useState('anonymous');
  const [selectedGroupId, setSelectedGroupId] = useState(null);
  const [loading, setLoading] = useState(false);

  function formatCurrency(value, locale = "pt-AO", currency = "AOA") {
    return new Intl.NumberFormat(locale, {
      style: "currency",
      currency: currency,
    }).format(value);
  }

  const notificarErro = () => {
    toast.error("Não foi possível adicionar o produto.");
  };

  const notificarSucessoSocia = () => {
    toast.success("Parabéns! Sua sócia foi criada com sucesso!");
  };

  useEffect(() => {
    calculateDiscount();
  }, [quantityPerParticipant, participants, product]);

  useEffect(() => {
    if (groupType === 'existing' && !selectedGroupId && userGroups.length > 0) {
      setSelectedGroupId(userGroups[0].id);
    }
  }, [groupType, selectedGroupId, userGroups]);

  const validateInputs = () => {
    if (!product || participants < 2) {
      alert("Um grupo deve ter pelo menos dois participantes.");
      setParticipants(2);
      return false;
    }
    if (participants > 4) {
      setParticipants(4);
      return false;
    }
    if (quantityPerParticipant > product.quantidade) {
      alert(`A quantidade por participante foi ajustada para ${product.quantidade} para não exceder a capacidade da caixa.`);
      setQuantityPerParticipant(product.quantidade);
      return false;
    }
    if (quantityPerParticipant < parseInt(product.quantidade / 4)) {
      alert(`A quantidade por participante foi ajustada para ${parseInt(product.quantidade / 4)} para não ficar abaixo do valor mínimo permitido.`);
      setQuantityPerParticipant(parseInt(product.quantidade / 4));
      return false;
    }
    return true;
  };

  const calculateDiscount = () => {
    if (!validateInputs()) return;
const SociosContri = (product.margem * 0.3) / 4;
    const QtdContri = (product.margem * 0.7) / product.quantidade;
    const baseDiscount = SociosContri + QtdContri;
    const additionalDiscount = baseDiscount * 2;
    const maxDiscountPercent = product.margem 

    

    let calculatedDiscountPercent = baseDiscount + (participants - 2) * additionalDiscount;
    calculatedDiscountPercent = Math.min(calculatedDiscountPercent, maxDiscountPercent);

    const totalQuantity = quantityPerParticipant * participants;
    const precoUnitario = (product.preco / product.quantidade);
    const totalPrice = totalQuantity * precoUnitario;

    let calculatedTotalDiscount = (totalPrice * calculatedDiscountPercent) / 100;

    setDiscountPercent(calculatedDiscountPercent);
    setTotalDiscount(calculatedTotalDiscount);
    setTotalPriceAfterDiscount(totalPrice - calculatedTotalDiscount);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    const groupBuyDetails = {
      quantityPerParticipant,
      participants,
      discountPercent,
      totalDiscount,
      totalPriceAfterDiscount,
      groupType,
      selectedGroupId
    };

    const nomeSocia = 'SOCIA DE ' + product.produto.toUpperCase();
    const isPrvt = groupType === 'existing';
    const idGrupo = groupType === 'existing' ? selectedGroupId : 0;

    ApiActions.CreateSocia(product.id, quantityPerParticipant, nomeSocia, participants, discountPercent, isPrvt, idGrupo)
      .then(() => {
        notificarSucessoSocia();
      })
      .catch((error) => {
        notificarErro();
      })
      .finally(() => {
        setLoading(false);
      });

    onSubmitGroupBuy(groupBuyDetails);
  };

  return (
    <div className="relative mx-auto">
      <h3 className="text-lg font-bold mb-4 text-start uppercase">Detalhes da Compra</h3>
      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <div className="flex justify-start">
            <button type="button" onClick={() => setGroupType('anonymous')} className={`hover:bg-blue-700 text-black font-bold py-2 px-4 ${groupType === 'anonymous' ? 'bg-gray-300 font-bold' : 'bg-gray-100'}`}>ENCONTRE UM SÓCIO PARA MIM </button>
            <button type="button" onClick={() => setGroupType('existing')} className={`hover:bg-green-700 text-black font-bold py-2 px-4 ${groupType === 'existing' ? 'bg-gray-300' : 'bg-gray-100'}`}>COMPRAR COM AMIGOS</button>
          </div>
        </div>

        {groupType === 'existing' && userGroups.length > 0 ? (
          <div className="mb-4 py-4">
            <label htmlFor="groupSelect" className="block text-sm font-medium text-gray-700 uppercase">Selecionar Grupo:</label>
            <select
              id="groupSelect"
              value={selectedGroupId}
              onChange={(e) => setSelectedGroupId(e.target.value)}
              className="mt-1 p-2 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
            >
              {userGroups.map(group => (
                <option className="text-lg uppercase" key={group.id} value={group.id}>{group.grupo.toUpperCase()} - {group.descricao.toUpperCase()}</option>
              ))}
            </select>
          </div>
        ) : groupType === 'existing' && userGroups.length === 0 ? (
          <div className="mb-4 py-4">
            <p className="text-sm text-gray-500">Você ainda não tem nenhum grupo privado formado. Para criar um grupo, acesse o menu do usuário e escolha a opção "Criar Novo Grupo".</p>
          </div>
        ) : null}

        <div className="mb-4">
          <label htmlFor="quantityPerParticipant" className="block text-sm font-medium text-gray-700 uppercase">Quantidade por sócio (Participante):</label>
          <input
            type="number"
            id="quantityPerParticipant"
            value={quantityPerParticipant}
            onChange={(e) => setQuantityPerParticipant(Math.max(1, parseInt(e.target.value, 10)))}
            className="mt-1 p-2 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
            min="1"
          />
        </div>
        <div className="mb-4">
          <label htmlFor="participants" className="block text-sm font-medium text-gray-700 uppercase">Número de sócios (Participantes):</label>
          <input
            type="number"
            id="participants"
            value={participants}
            onChange={(e) => setParticipants(Math.max(2, parseInt(e.target.value, 10)))}
            className="mt-1 p-2 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
            min="2"
          />
        </div>
        <div className="mb-4">
          <p className="text-sm text-gray-500 uppercase">Desconto: {discountPercent.toFixed(2)}%</p>
          <p className="text-sm text-gray-500 uppercase">Valor do Desconto: {formatCurrency(totalDiscount)}</p>
          <p className="text-sm text-gray-500 uppercase">Preço Após Desconto: {formatCurrency(totalPriceAfterDiscount)}</p>
        </div>
        <div className="flex justify-center">
          <button type="submit" className="bg-customPurple hover:bg-customPurple text-white font-bold py-2 px-4 rounded" disabled={loading}>
            {loading ? <BeatLoader size={8} color={"#fff"} /> : 'COMEÇAR COMPRA EM GRUPO'}
          </button>
        </div>
      </form>
    </div>
  );
};

export default CreateGroupForm;
