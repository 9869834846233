import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AppStore from "../../stores/AppStore";
import BeatLoader from "react-spinners/BeatLoader";
import axios from "axios";

export default function Historico() {
  const [isLoading, setIsLoading] = useState(AppStore.getIsLoading());
  const [historico, setHistorico] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(12);

  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("token");

    function handleLoading() {
      setIsLoading(AppStore.getIsLoading());
    }

    AppStore.on("carregando", handleLoading);

    if (!token) {
      navigate("/");
    }
  }, [navigate]);

  useEffect(() => {
    const token = localStorage.getItem("token");

    function handleLoading() {
      setIsLoading(AppStore.getIsLoading());
    }

    AppStore.on("carregando", handleLoading);

    if (!token) {
      navigate("/");
      return;
    }

    try {
      axios
        .get("https://api.socia.ao/apiV2/Socia/MinhasFacturas", {
          headers: {
            apiKey: "9MT3VnQYj2Mb7ILNqKy2HfF9AChqPeFwtDQ8QkGBZZ0=",
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          setHistorico(response.data.objecto);
          setIsLoading(false);
        })
        .catch((error) => {
          setIsLoading(false); 
          console.error("Erro na requisição:", error);
        });
    } catch (error) {
      setIsLoading(false);
      console.error("Erro só:", error);
    }
  }, [navigate]);

  function formatCurrency(value, locale = "pt-AO", currency = "AOA") {
    return new Intl.NumberFormat(locale, {
      style: "currency",
      currency: currency,
    }).format(value);
  }

  function handleVerDetalhes(fatura) {
    navigate("/carrinho/recomprar/", { state: { fatura: fatura } });
  }

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = historico && historico.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div>
      <div className="flex flex-col items-start">
        <h1 className="scroll-m-20 pb-5 text-2xl font-semibold tracking-tight first:mt-0 text-customPurple">
          Histórico de compras
        </h1>
        <p className="text-left">
          Verifique os produtos que possa precisar em sua despensa.
        </p>
      </div>
      {isLoading ? (
        <div className="flex justify-center items-center h-screen">
          <BeatLoader />
        </div>
      ) : historico && historico.length > 0 ? (
        <div>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-11 py-8">
          {currentItems.map((hist, index) => (
            <div
              key={index}
              className="w-72 flex flex-col p-5 gap-1 bg-gray-100/45 py-5 rounded shadow-md"
            >
              <div>
                <div className="border-b pb-3 flex flex-col gap-3">
                  <h2 className="font-bold text-lg flex flex-row items-center justify-between">
                    <span className="">Factura Nº:</span>{" "}
                    <span>{hist.numero}</span>
                  </h2>
                  <p className="flex flex-row items-center justify-between">
                    <span className="font-semibold opacity-50 text-sm">
                      Data:
                    </span>{" "}
                    <span className="text-xs opacity-50 font-medium italic">
                      {hist.data}
                    </span>
                  </p>
                </div>
                <div className="border-b py-3 flex flex-col gap-2">
                  <p className="flex flex-row items-center justify-between">
                    <span className="font-bold opacity-70 text-md">
                      Subtotal:
                    </span>{" "}
                    <span className="text-sm opacity-65 font-medium ">
                      {formatCurrency(hist.subTotal)}
                    </span>
                  </p>
                  <p className="flex flex-row items-center justify-between">
                    <span className="font-bold opacity-70 text-md">
                      Custo de serviço:
                    </span>{" "}
                    <span className="text-sm opacity-65 font-medium ">
                      {formatCurrency(hist.custoServico)}
                    </span>
                  </p>
                </div>
                <div>
                  <p className="flex flex-row items-center justify-between font-bold py-3 text-base">
                    <span>Total:</span>
                    <span>{formatCurrency(hist.total)}</span>
                  </p>
                </div>
              </div>
              <div className="flex flex-col items-center justify-between">
                <div className="w-full">
                  <button
                    onClick={() => handleVerDetalhes(hist)}
                    className="w-full bg-customPurple text-white px-16 py-2 rounded"
                  >
                    Ver 
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="flex justify-center mt-5">
          <button
            onClick={() => paginate(currentPage - 1)}
            disabled={currentPage === 1}
            className="px-4 py-2 mr-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
          >
            Anterior
          </button>
          <button
            onClick={() => paginate(currentPage + 1)}
            disabled={currentItems.length < itemsPerPage}
            className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50 focus:outline-none focus:ring
            -2 focus:ring-indigo-500 focus:ring-offset-2"
            >
              Próximo
            </button>
          </div>
          </div>
        ) : (
          historico !== null && (
            <main className="flex flex-col items-center justify-center h-screen">
              <img
                src="https://illustrations.popsy.co/fuchsia/shopping-cart.svg"
                alt="Carrinho vazio"
                className="h-52 select-none object-contain -mt-24"
              />
              <h1 className="scroll-m-20 text-2xl text-customPurple font-extrabold tracking-tight lg:text-4xl select-none">
                Histórico vazio
              </h1>
            </main>
          )
        )}
      </div>
    );
  }
  