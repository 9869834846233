import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import AppStore from "../../stores/AppStore";
import { ApiActions } from "../../actions/ApiActions";
import { FaLocationArrow } from "react-icons/fa";
import CryptoJS from "crypto-js";
import { Link } from "react-router-dom";
import BeatLoader from "react-spinners/BeatLoader";
import { toast } from "react-toastify";

function truncateText(text, maxLength) {
  if (text.length <= maxLength) {
    return text;
  }
  return text.slice(0, maxLength) + "...";
}

const GroupBuyingCard = ({ product, onCreateGroup }) => {
  return (
  <button
  className="w-full bg-customPurple text-white px-10 py-2 rounded mt-4"
  onClick={onCreateGroup}
  >
   Fazer Sócia
  </button>
  );
};


const CreateGroupModal = ({ isOpen, onClose, product, onSubmitGroupBuy }) => {
  // const product.quantidade = product ? product.quantidade : 0;

  const [produto, setProduto] = useState(null);

   const [quantityPerParticipant, setQuantityPerParticipant] = useState(parseInt(product.quantidade / 4));
   const [participants, setParticipants] = useState(2);
   const [discountPercent, setDiscountPercent] = useState(2);
   const [totalDiscount, setTotalDiscount] = useState(0);
   const [totalPriceAfterDiscount, setTotalPriceAfterDiscount] = useState(0);
   const safeQuantityPerParticipant = Math.min(quantityPerParticipant, product.quantidade);

   useEffect(() => {
    calculateDiscount();
  }, [quantityPerParticipant, participants, product]);

  const calculateDiscount = () => {
    // console.log(product)
    if (!product || participants < 2  ) {
      alert("Um grupo deve ter dois participantes");
      setParticipants(2)
      return;
    }
     if (participants > 4  ) {
     // alert("Um grupo deve ter participantes");
      setParticipants(4)
      return;
    }
     if (quantityPerParticipant > product.quantidade) {
      toast.info(`A quantidade por participante foi ajustada para ${product.quantidade} para não exceder a capacidade da caixa.`);
      setQuantityPerParticipant(safeQuantityPerParticipant);
     }
    if (quantityPerParticipant < parseInt(product.quantidade / 4)) {
      toast.info(`A quantidade por participante foi ajustada para ${parseInt(product.quantidade / 4)} para não ficar abaixo do valor minimo permitido.`);
      setQuantityPerParticipant (parseInt(product.quantidade / 4));
    }
    // Definindo a lógica de crescimento progressivo do desconto
    const baseDiscount = 5; // 5% para dois participantes
    let additionalDiscount = (product.margem < 1 ? 10 : product.margem   - 6) / 2; // 2% adicional por cada participante extra
    let maxDiscountPercent = product.margem < 45 ?  product.margem < 1 ? 10 :  product.margem  : 45  ; // Máximo de desconto permitido

    let calculatedDiscountPercent = baseDiscount + (participants - 2) * additionalDiscount;
    calculatedDiscountPercent = Math.min(calculatedDiscountPercent, maxDiscountPercent);

    const totalQuantity = quantityPerParticipant ;
    const totalPrice = totalQuantity * (product.preco / product.quantidade);
    const totalCost = totalQuantity * product.costPrice;
    const profitMargin = 2000;
    
    let calculatedTotalDiscount = (totalPrice * calculatedDiscountPercent) / 100;
    calculatedTotalDiscount = Math.min(calculatedTotalDiscount, profitMargin); // O desconto não pode exceder a margem de lucro

    setDiscountPercent(calculatedDiscountPercent);
    setTotalDiscount(calculatedTotalDiscount);
    setTotalPriceAfterDiscount(totalPrice - calculatedTotalDiscount);
  };

   const handleSubmit = (e) => {
    e.preventDefault();
    onSubmitGroupBuy({
      quantityPerParticipant,
      participants,
      discountPercent,
      totalDiscount,
      totalPriceAfterDiscount
    });
    // onClose();
  };


  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex items-center justify-center z-50">
      <div className="relative mx-auto p-5 border w-96 shadow-lg rounded-md bg-white">
        <h3 className="text-lg font-bold mb-4 text-center uppercase">Detalhes da Sócia</h3>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label htmlFor="quantityPerParticipant" className="block text-sm font-medium text-gray-700 uppercase">Quantidade por sócio (Participante):</label>
            <input
              type="number"
              id="quantityPerParticipant"
              value={quantityPerParticipant}
              onChange={(e) => setQuantityPerParticipant(Math.max(1, parseInt(e.target.value, 10)))}
              className="mt-1 p-2 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
              min="1"
            />
          </div>
          <div className="mb-4">
            <label htmlFor="participants" className="block text-sm font-medium text-gray-700 uppercase">Número de sócios (Participantes):</label>
            <input
              type="number"
              id="participants"
              value={participants}
              onChange={(e) => setParticipants(Math.max(2, parseInt(e.target.value, 10)))}
              className="mt-1 p-2 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
              min="2"
            />
          </div>
          <div className="mb-4">
            <p className="text-sm text-gray-500 uppercase">Desconto: {discountPercent.toFixed(2)} %</p>
            <p className="text-sm text-gray-500 uppercase">Valor do Desconto: {formatCurrency(totalDiscount)}</p>
            <p className="text-sm text-gray-500 uppercase">Preço Após Desconto: {formatCurrency(totalPriceAfterDiscount) }</p>
          </div>
          <div className="flex justify-between">
            <button type="button" onClick={onClose} className="bg-red-100 hover:bg-red-700 text-white font-bold py-2 px-4 rounded">Cancelar</button>
            <button type="submit" className="bg-customPurple hover:bg-customPurple text-white font-bold py-2 px-4 rounded">Criar Sócia</button>
          </div>
        </form>
      </div>
    </div>
  );
};


function formatCurrency(value, locale = "pt-AO", currency = "AOA") {
  return new Intl.NumberFormat(locale, {
    style: "currency",
    currency: currency,
  }).format(value);
}


const Despensa = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(AppStore.getIsLoading());
  const [despensaData, setDespensaData] = useState(null);
  const [showCreateGroupModal, setShowCreateGroupModal] = useState(false);
  const [isAdding, setIsAdding] = useState(false);
  const [produto, setProduto] = useState(null);
  const [selectedProduct, setSelectedProduct] = useState(null);

  function handleChanges() {
    setProduto(AppStore.getProduto())
    setSelectedProduct(AppStore.getProduto());
  }

  const handleCreateGroup = (selectedProduct) => {
    // console.log("Produto selecionado:", selectedProduct);
    setSelectedProduct(selectedProduct);
    setShowCreateGroupModal(true);
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
    function handleLoading() {
      setIsLoading(AppStore.getIsLoading());
    }
    AppStore.on("carregando", handleLoading);
    if (!token) {
      navigate("/");
      return;
    }
    try {
      axios
        .get("https://api.socia.ao/apiV2/Socia/PredictDispensa", {
          headers: {
            apiKey: "9MT3VnQYj2Mb7ILNqKy2HfF9AChqPeFwtDQ8QkGBZZ0=",
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          setDespensaData(response.data.objecto);
        })
        .catch((error) => {});
    } catch (error) {
      // console.error("Erro só:", error);
    }
  }, [navigate]);



  const token = localStorage.getItem("token");
function formatCurrency(value, locale = "pt-AO", currency = "AOA") {
  return new Intl.NumberFormat(locale, {
    style: "currency",
    currency: currency,
  }).format(value);
}
  const redirectToPromoDetailPage = (produto) => {
    const key = CryptoJS.enc.Hex.parse(
      "0879febbaf365b1535e175da463ca7bcd7fdc76956282fa33c2cf5a9c28cc1b5"
    ); // Ensure key is in correct format
    const ciphertext = CryptoJS.AES.encrypt(
      CryptoJS.enc.Utf8.parse(produto.id),
      key,
      {
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7,
      }
    ).toString();
    const safeEncoded = ciphertext.replace(/\//g, "_");
    navigate(`/product/${safeEncoded}`);
  };

  
  const handleSubmitGroupBuy = (groupDetails) => {
    // Handle group buy logic here, such as calculating discounts,
    // ensuring it doesn't exceed the profit margin, and submitting the group buy request
    const data = selectedProduct;
    const nomeSocia = 'SOCIA DE ' + data.produto;
    // console.log(data.id , groupDetails.quantityPerParticipant, nomeSocia , groupDetails.participants , groupDetails.discountPercent)
     ApiActions.CreateSocia(data.id, groupDetails.quantityPerParticipant, nomeSocia, groupDetails.participants, 6)
      .then(() => {
        setIsAdding(false);
        toast.success("Parabéns a sua socia foi criada com sucesso!");
      })
      .catch((error) => {
      
        toast.error("Não foi possivel criar a sócia ");
      })
      .finally(() => {
        setIsAdding(false); // Stop loading regardless of success or error
      });
  };



  const handleCloseModal = () => {
    setShowCreateGroupModal(false);
  };

  function formatCurrency(value, locale = "pt-AO", currency = "AOA") {
    return new Intl.NumberFormat(locale, {
      style: "currency",
      currency: currency,
    }).format(value);
  }

  if (isLoading) {
    return (
      <div>
        {isLoading && (
          <div className="flex flex-col items-center justify-center">
            <BeatLoader />
          </div>
        )}
      </div>
    );
  }

  return (
    <div>
      <div className="flex flex-col items-start justify-start">
        <h1 className="font-sans scroll-m-20 pb-5 text-2xl font-semibold tracking-tight first:mt-0 text-customPurple">
          A sua despensa
        </h1>
        <p className="text-left">
        Estes produtos podem estar em falta na sua despensa
        </p>
      </div>
      {despensaData && despensaData.length > 0 ? (
        <div>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-10 py-8">
            {despensaData.map((produto, index) => (
              <div className="w-full flex flex-col items-center" key={index}>
                <div className="w-full flex flex-col shadow rounded">
                  <img
                    src={produto.foto}
                    alt={produto.produto}
                    className="w-full h-36 object-contain"
                  />
                  <div className="p-4 flex-col items-center justify-center">
                  <h2 className="text-lg font-semibold mb-2 text-center">
                    {truncateText(produto.produto, 17)}
                    </h2>
                    <p className="text-green-600 mb-2"></p>
                    <p className="text-red-700 text-center text-sm">{produto.esgota}</p>
                    <GroupBuyingCard product={produto} onCreateGroup={() => handleCreateGroup(produto)} />
                  </div>
                  
                </div>
              </div>
            ))}
          </div>
          {showCreateGroupModal && (
        <CreateGroupModal
        product={selectedProduct}
          isOpen={showCreateGroupModal}
          onClose={handleCloseModal}
          onSubmitGroupBuy={handleSubmitGroupBuy}
        />
           )}
        </div>
      ) : (
        <main className="flex flex-col items-center justify-center h-screen">
          <img src="https://illustrations.popsy.co/fuchsia/shopping-cart.svg" alt="Carrinho vazio" className="h-52 select-none object-contain -mt-24" /> 
          <h1 className="scroll-m-20 text-2xl text-customPurple font-extrabold tracking-tight lg:text-4xl select-none">
            Sem produtos por terminar
          </h1>
        </main>
      )}
    </div>
  );
}


export default Despensa;